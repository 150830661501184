import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ReactLenis, useLenis } from "lenis/react";

import Header from "src/layouts/marketing/header";
import AdminLogin from "src/modules/admin/admin-login";
import MarketingLayout from "src/layouts/marketing/marketing-layout";
import { animateTitle } from "./animation/common";
import { getStoredUser } from "src/common/components/auth/auth";
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(useGSAP);

const Login = () => {
    const navigate = useNavigate();

    const main = useRef();
    const lenis = useLenis((data) => {});

    useEffect(() => {
        if (getStoredUser() && getStoredUser().id) {
            navigate("/admin/");
        }
    }, []);

    useGSAP(
        () => {
            animateTitle({ trigger: ".section-login .g-title", scrub: false });

            gsap.from(".section-login", {
                y: "100px",
                opacity: 0,
            });
        },
        { scope: main }
    );

    return (
        <MarketingLayout type={`no-footer`}>
            <div className="fixed scale-75 translate-y-32 md:translate-y-0 md:scale-100 left-0 top-0 w-full min-h-screen flex items-center justify-center z-0 pt-32">
                <div
                    className="bg-blur absolute rounded-full z-0 opacity-60 left-1/2 -translate-x-1/2 top-1/3 "
                    style={{ background: "#6676FF", width: 1000, height: 300, filter: `blur(300px)` }}
                ></div>
            </div>

            {/* HERO */}
            <div className="flex-1 flex min-h-screen" ref={main}>
                <div className="section-login section flex w-full relative items-center pt-40 md:pt-52  justify-center z-30  min-h-full px-6 ">
                    <div className="container-sm flex-1 flex justify-center items-start  min-h-full ">
                        <div className="flex flex-col text-center gap-10 md:gap-20 bg-white/40 px-10 md:px-40 pt-20 md:pt-40 pb-10 md:pb-20 rounded-4xl">
                            <div className="g-title text-4xl md:text-7xl leading-tighter tracking-tighter font-semibold">
                                <div>Login to your</div>
                                <div>account</div>
                            </div>
                            <div className="subtitle text-base md:text-lg opacity-50 px-10 md:px-0">Enjoy exclusive features and many more.</div>

                            <AdminLogin />
                        </div>
                    </div>
                </div>
            </div>
        </MarketingLayout>
    );
};

export default Login;
