import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animateTitle, horizontalLoop } from "./common";

gsap.registerPlugin(ScrollTrigger);

export const animateBg = () => {
    // gsap.to('.bg-blur-2',{
    //     yPercent:-100,
    //     // xPercent:-50,
    //     scrollTrigger: {
    //         trigger: '.section-feats',
    //         start: "30% bottom",
    //         end: "+=300",
    //         scrub: 3
    //   you  },
    // })
}

export const animateHero = () => {
    // animateTitle({ trigger: ".section-hero .g-title", scrub: false });

    gsap.to(".section-hero > .container", {
        scale: 0.9,
        opacity: 0,
        scrollTrigger: {
            trigger: ".section-feats",
            start: "top 90%",
            end: "+=50",
            scrub: 1,
        },
    });
};

export const animateFeats = () => {
    const fitems = gsap.utils.toArray(".section-feats .feat-item");
    fitems.forEach((item, i) => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: item,
                start: "top 50%",
                end: "+=300",
                scrub:1,
            },
        });
        tl.from(item, {
            x: "-200px",
            opacity: 0,
        });

        const featmore = item.querySelector(".feat-more");
        if (featmore) {
            tl.from(featmore, {
                delay:5,
                opacity: 0,
                y: 110,
                
            });

            // const featmores = gsap.utils.toArray(".feat .why-item");
            // featmores.forEach((item, i) => {
            //     const tl = gsap.timeline({
            //         scrollTrigger: {
            //             trigger: item,
            //             start: "top 80%",
            //             end: "+=100",
            //             scrub: 1,
            //         },
            //     });
            //     tl.from(item, {
            //         // x: "-200px",
            //         opacity: 0,
            //         scale: 1.3,
            //         delay: i,
            //     });
            // });



        }

       
    });
};




