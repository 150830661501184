import { useEffect, useState } from "react";

const Timeline = (props) => {
    const { data, selected, canNext } = props;
    const [theClass, setTheClass] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(()=>{
        const selectedIndex = data.findIndex((data) => data.id === selected?.id);
        if(selectedIndex !== undefined) {
            setCurrentIndex(selectedIndex)
        }
    },[selected])

    const renderClass = (ditem, dindex) => {
        if (selected) {
            if(dindex < currentIndex){
                return "border-blue text-white bg-blue";
            }else if (ditem.id === selected.id) {
                return "border-blue text-blue";
            } else {
                return "border-slate-500 text-slate-500 opacity-30";
            }

            
        }
    };

    return (
        <div className="flex-1 flex gap-8 items-center justify-center">
            {data.map((ditem, d) => (
                <div
                    key={d}
                    className="flex gap-4 items-center cursor-pointer"
                    disabled={!canNext}
                    onClick={(e) => {
                        if(canNext){
                        if (props.onClick) {
                            props.onClick(e);
                        }
                        if (props.OnSelect) {
                            props.OnSelect(ditem);
                        }
                    }
                    }}
                >
                    {d >= 1 && <div className="h-0.5 w-20 bg-slate-300"></div>}
                    <div className={`w-10 h-10 rounded-3xl flex items-center justify-center font-semibold border-2 ${renderClass(ditem,d)}`}>{d + 1}</div>
                    <div className={`font-semibold ${d < currentIndex || selected.id === ditem.id ? "" : "opacity-30"}`}>{ditem.name}</div>
                </div>
            ))}
            {/* <div className="flex gap-4 items-center cursor-pointer">
                <div className="w-10 h-10 rounded-3xl flex items-center justify-center font-semibold border-2 border-blue text-white bg-blue">1</div>
                <div className="font-semibold ">Campaign Settings</div>
            </div>

            <div className="flex gap-4 items-center  cursor-pointer">
                <div className="h-0.5 w-20 bg-slate-300"></div>
                <div className="w-10 h-10 rounded-3xl flex items-center justify-center font-semibold border-2 border-blue text-blue">2</div>
                <div className="font-semibold ">Select Character</div>
            </div>

            <div className="flex gap-4 items-center cursor-pointer">
                <div className="h-0.5 w-20 bg-slate-300"></div>
                <div className="w-10 h-10 rounded-3xl flex items-center justify-center font-semibold border-2 border-slate-500 text-slate-500  opacity-30 ">
                    3
                </div>
                <div className="font-semibold  opacity-30  ">Add Script</div>
            </div> */}
        </div>
    );
};

export default Timeline;



