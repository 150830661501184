import { useState } from "react";
import Dbox from "src/common/components/dbox/dbox";

const AudioDefault = (props) => {
    const {genData} = props;

    const voiceTabDefaults = [
        { id: "male", label: "Male" },
        { id: "female", label: "Female" },
    ];

    return (
        <Dbox className={`flex-col flex-1 gap-8 p-20 items-center justify-center `}>
            <div className="flex flex-col gap-5 min-w-912">
                <div>Select a voice :</div>
                <div className="flex-1 border border-lightblue100 rounded-2xl bg-lightblue50">
                    <select
                        value={genData.voiceGender}
                        className="flex-1 p-4 "
                        onChange={(e) => {
                            // setVoiceTabDefault(e.target.value);
                            genData.voiceGender = e.target.value
                            props.OnSet({...genData})
                        }}
                    >
                        <option value="">Select a voice</option>
                        {voiceTabDefaults.map((tab, t) => (
                            <option key={t} value={tab.id}>
                                {tab.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </Dbox>
    );
};

export default AudioDefault;
