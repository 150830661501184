import iconFacebook from "src/common/assets/images/marketing/icon-facebook.svg";
import iconTwitter from "src/common/assets/images/marketing/icon-twitter.svg";
import iconLinkedin from "src/common/assets/images/marketing/icon-linkedin.svg";
import iconNewsletter from "src/common/assets/images/marketing/icon-newsletter.svg";
import { useNavigate } from "react-router-dom";

import Logo from "src/common/components/logo/logo";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
gsap.registerPlugin(useGSAP);

const Footer = (props) => {
    const main = useRef()
    const navigate = useNavigate();
    const menu = [
        {
            label: "Features",
            path: "/features",
        },
        {
            label: "Examples",
            path: "/examples",
        },
        {
            label: "Pricing",
            path: "/pricing",
        },
        {
            label: "Login",
            path: "/login",
        },
    ];

    useGSAP(
        () => {
            gsap.from(".container", {
                scale: 1.3,
                y: "200",
                scrollTrigger: {
                    trigger: ".container",
                    start: "0% 90%",
                    end: "+=100",
                    scrub: 1,
                },
            });
            
        },
        { scope: main }
    );

    return (
        
        <div ref={main} className="section-footer section flex relative w-full px-4">
            <div
                className="bg-blur-right absolute rounded-full z-0 opacity-30 left-2/3 -top-3/4"
                style={{ background: "#6676FF", width: 1000, height: 1000, filter: `blur(300px)` }}
            ></div>

            <div
                className="bg-blur-left absolute rounded-full z-0  opacity-20 right-2/3 -bottom-1/2"
                style={{ background: "#6676FF", width: 600, height: 600, filter: `blur(200px)` }}
            ></div>
            <div className="container mx-auto relative flex-1 flex flex-col z-20 bg-white/40 rounded-3xl p-10 md:p-24 backdrop-blur gap-10 md:gap-20">
                <div className="section-newsletter flex flex-col md:flex-row border-b border-b-lightblue300/50 pb-12 md:pb-28 gap-10">
                    <div className="flex flex-1 flex-col gap-8 justify-between">
                        <img src={iconNewsletter} className="w-12" />
                        <div className="text-3xl md:text-4xl font-semibold leading-tight tracking-tight">
                            <div>Keep up to date</div>
                            <div>with us.</div>
                        </div>
                        <div className="text-base opacity-50">Get the latest news, tips and how-to's on the go, right in your inbox!</div>
                    </div>

                    <div className="flex flex-1 flex-col gap-6 justify-end md:px-52">
                        <div className="text-xl font-semibold leading-tighter tracking-tight">Signup to our newsletter.</div>
                        <div className=" flex flex-row items-center gap-4">
                            <div className="border border-lightblue300 rounded-xl px-3 py-1 flex-1">
                                <input type="text" placeholder="Add your email..." />
                            </div>
                            <button className="button">Subscribe</button>
                        </div>

                        <div className="text-base">
                            <span className="opacity-50">By subscribing you agree to our </span>
                            <a href="#" className="text-blue">
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>

                <div className="flex flex-1 flex-col text-center items-center md:items-left md:text-left md:flex-row gap-20 pt-10 md:pt-0">
                    <div className="md:w-2/3 flex flex-col gap-6 md:gap-20 items-center justify-center md:items-start">
                        <a
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/");
                            }}
                        >
                            <Logo className={`w-72 md:w-52`} />
                        </a>
                        <div className="text-lg md:text-xl leading-tight">
                            Create personalized<div>videos at scale</div>
                        </div>
                    </div>

                    <div className="links flex flex-col text-base gap-5 w-1/3">
                        {menu.map((item, i) => (
                            <a
                                key={i}
                                href={`${item.path}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`${item.path}`);
                                }}
                                className="px-3"
                            >
                                {item.label}
                            </a>
                        ))}
                    </div>

                    <div className="links flex flex-col text-base gap-5 w-1/3">
                        {menu.map((item, i) => (
                            <a
                                key={i}
                                href={`${item.path}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`${item.path}`);
                                }}
                                className="px-3"
                            >
                                {item.label}
                            </a>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col md:flex-row flex-1 items-center justify-between text-lg pt-12 gap-10">
                    <div className="flex flex-row gap-10 text-base text-gray-400">
                        <a href="#" className="">
                            Privacy Policy
                        </a>

                        <a href="#" className="">
                            Terms of Service
                        </a>
                    </div>

                    <div className="flex flex-row  gap-5">
                        <img src={iconFacebook} />
                        <img src={iconTwitter} />
                        <img src={iconLinkedin} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
