import { useEffect, useState } from "react";

const UiAlignment = (props) => {
    const [selected, setSelected] = useState(props.value);

    useEffect(() => {
        setSelected(props.value);
    }, [props.value]);

    const Item = (props) => {
        return (
            <div className="a-item flex-1 flex items-center justify-center min-h-5 min-w-5">
                <div
                    className={`transition-all origin-center flex rounded-full cursor-pointer  ${
                        selected === props.data ? `bg-blue min-w-3 min-h-3` : `min-h-1 min-w-1 bg-lightblue300 hover:min-w-3 hover:min-h-3`
                    }`}
                    onClick={() => {
                        setSelected(props.data);
                        props.OnSet(props.data);
                    }}
                ></div>
            </div>
        );
    };

    return (
        <div className="flex-1 flex flex-col my-3">
            <div className="flex-1 flex flex-row min-w-full">
                <Item
                    {...props}
                    data={7}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />

                <Item
                    {...props}
                    data={8}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />
                <Item
                    {...props}
                    data={9}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />
            </div>

            <div className="flex-1 flex flex-row min-w-full gap-1">
                <Item
                    {...props}
                    data={4}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />
                <Item
                    {...props}
                    data={5}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />
                <Item
                    {...props}
                    data={6}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />
            </div>

            <div className="flex-1 flex flex-row min-w-full gap-1">
                <Item
                    {...props}
                    data={1}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />
                <Item
                    {...props}
                    data={2}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />
                <Item
                    {...props}
                    data={3}
                    OnSet={(data) => {
                        props.OnSet(data);
                    }}
                />
            </div>
        </div>
    );
};

export default UiAlignment;
