import { useEffect, useState, useRef } from "react";
import Dbox from "src/common/components/dbox/dbox";
import { dateFormatter } from "src/common/utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import VideoPlayer from "src/common/components/video-player/video-player";

import Popup from "src/common/components/popup/popup";
import { deleteVoice } from "src/common/services/enduser/voices";
import { FiDownload } from "react-icons/fi";
import { RiMore2Fill } from "react-icons/ri";
import More from "./card-voice-more";

const CardVoice = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { voice, genData } = props;

    const [showMore, setShowMore] = useState();

    const handleVoice = async ({ opr, voiceId, voice }) => {
        switch (opr) {
            case "delete":
                if (voiceId) {
                    const del_res = await deleteVoice({ voiceId });
                    console.log("del_res :", del_res);

                    props.OnDelete(true);
                }
                break;
            case "onuse":
                if (voice) {
                    const thevoice = { ...voice };

                    if (thevoice.voiceId === genData?.voiceId) {
                        props.OnUse({});
                    } else {
                        thevoice.type = "voice";
                        props.OnUse({ ...thevoice });
                    }
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            <Dbox
                className={`aspect-square flex flex-col relative overflow-hidden  
                    ${(props.for && (genData && genData.voiceId !== voice.voiceId) )? "hover:border-4 hover:border-indigo-500" :  ""}
                    ${(props.for && (genData && genData.voiceId === voice.voiceId) )? "p-2 border-4 !border-indigo-500" :  ""}
            `}
                data-id={voice.voiceId}
            >
                <div
                    className={`relative max-h-full rounded-2xl flex-1 ${genData && genData.voiceId === voice.voiceId ? "bg-blue" : " bg-zinc-950"}`}
                    title={voice.voiceId}
                >
                    {voice.sample && (
                        <VideoPlayer src={voice.sample} className={`${genData && genData.voiceId === voice.voiceId ? "opacity-50" : ""}`} fluid={true} />
                    )}
                </div>
                <div
                    className="absolute top-0 left-0 w-full h-full z-10 cursor-pointer"
                    onClick={() => {
                        // setShowPrev(true);
                        if (voice.sample) {
                            {
                                props.for === "select" && handleVoice({ opr: "onuse", voice });
                            }
                            {
                                !props.for && navigate(location.pathname, { state: { show: "detail-voice", data: voice } });
                            }
                        }
                    }}
                ></div>
                {showMore && (
                    <More
                        thesrc={voice.sample}
                        voice={voice}
                        OnDelete={async () => {
                            await handleVoice({ opr: "delete", voiceId: voice.voiceId });
                        }}
                        OnClose={() => {
                            setShowMore(false);
                        }}
                    />
                )}

                <div className="p-3 absolute top-0 left-0 w-full flex justify-between z-20">
                    <div className="p-2">
                        {/* {genData?.voiceId !== voice.voiceId ? (
                            <button
                                className="button button-small px-4"
                                onClick={() => {
                                    handleVoice({ opr: "onuse", voice });
                                }}
                            >
                                use
                            </button>
                        ) : (
                            <div className="bg-indigo-500/20 px-4 py-1 rounded-full text-white border border-blue">selected</div>
                        )} */}

                        {/* {genData && genData.voiceId === voice.voiceId && (
                            <div className="bg-indigo-500/20 px-4 py-1 rounded-full text-white border border-blue">selected</div>
                        )} */}
                    </div>

                    {voice.sample && (
                        <div
                            className="bg-black/60 rounded-full p-3 cursor-pointer"
                            onClick={() => {
                                setShowMore(true);
                            }}
                        >
                            <RiMore2Fill className="text-white text-xl" />
                        </div>
                    )}

                    {/* <div
                        className="bg-black/60 rounded-full p-3 cursor-pointer flex items-center"
                        onClick={() => {
                            setShowPrev(true);
                        }}
                    >
                        <BiExpandAlt className="text-white" />
                    </div> */}
                    {/* <Tag color={`teal`} value={voice.state} /> */}
                </div>

                <div className="absolute bottom-5 left-5 bg-black/60  rounded-2xl px-3 py-1 text-white text-xs">
                    <div className="">{voice.voiceName}</div>
                    <div className="opacity-40">{dateFormatter(voice.date)}</div>
                </div>
            </Dbox>
        </>
    );
};

export default CardVoice;
