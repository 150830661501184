import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

import "src/common/assets/styles/fonts.css";
import "src/common/assets/styles/animate.css";
import "src/common/assets/styles/default.css";

import ProtectedRoute from "./common/components/protected-route";

import Dashboard from "./pages/admin/dashboard";
import Campaigns from "./pages/admin/campaigns";
import Integrations from "./pages/admin/integrations";
import Characters from "./pages/admin/characters";
import Videos from "./pages/admin/videos";
import Login from "./pages/marketing/login";
import Generate from "./pages/admin/generate";
import CampaignCreate from "./pages/admin/campaign-create";
import Templates from "./pages/admin/templates";
import TemplateCreate from "./pages/admin/template-create";
import Lists from "./pages/admin/lists";
import CampaignDash from "./pages/admin/campaign-dash";
import CampaignsLite from "./pages/admin/campaigns-lite";
import CampaignLiteCreate from "./pages/admin/campaign-lite-create";
import CampaignLiteDash from "./pages/admin/campaign-lite-dash";
import Home from "./pages/marketing/home";
import Features from "./pages/marketing/features";
import Pricing from "./pages/marketing/pricing";
import Examples from "./pages/marketing/examples";
import RealEstate from "./pages/marketing/real-estate";
import Character from "./pages/tester/character";
import Audio from "./pages/tester/audio";
import Video from "./pages/tester/video";
import RealEstateEditor from "./pages/admin/realstate-editor";

import GeneratedVideos from "./pages/admin/generated-videos";
import InputedVideos from "./pages/admin/inputed-videos";
import Voices from "./pages/admin/voices";
import InputedImages from "./pages/admin/inputed-images";
import GenerateVideo from "./pages/admin/generate-video";
import Instagram from "./pages/admin/instagram";

const App = () => {
    
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <Routes>
            
            {/* <Route path="/" element={<Home />} /> */}

            <Route path="/" element={<Home />} />


            <Route path="/features" element={<Features />} />
            <Route path="/examples" element={<Examples />} />
            <Route path="/pricing" element={<Pricing />} />

            <Route path="/real-estate" element={<RealEstate />} />

            <Route path="/login" element={<Login />} />

            {/* <Route path="/admin/" element={<ProtectedRoute component={CampaignsLite} />} /> */}

            {/* <Route path="/admin/dashboard" element={<ProtectedRoute component={Dashboard} />} />

            <Route path="/admin/campaigns" element={<ProtectedRoute component={Campaigns} />} />
            <Route path="/admin/campaigns/:campaignid" element={<ProtectedRoute component={CampaignDash} />} />
            <Route path="/admin/campaigns/create" element={<ProtectedRoute component={CampaignCreate} />} />

            <Route path="/admin/campaigns-lite" element={<ProtectedRoute component={CampaignsLite} />} />
            <Route path="/admin/campaigns-lite/create" element={<ProtectedRoute component={CampaignLiteCreate} />} />
            <Route path="/admin/campaigns-lite/edit/:campaignid" element={<ProtectedRoute component={CampaignLiteCreate} />} />
            <Route path="/admin/campaigns-lite/:campaignid" element={<ProtectedRoute component={CampaignLiteDash} />} />

            <Route path="/admin/integrations" element={<ProtectedRoute component={Integrations} />} />

            <Route path="/admin/templates" element={<ProtectedRoute component={Templates} />} />
            <Route path="/admin/templates/create" element={<ProtectedRoute component={TemplateCreate} />} />

            <Route path="/admin/lists" element={<ProtectedRoute component={Lists} />} />

            <Route path="/admin/characters" element={<ProtectedRoute component={Characters} />} />
            <Route path="/admin/generate/:modelId" element={<ProtectedRoute component={Generate} />} />

            <Route path="/admin/videos" element={<ProtectedRoute component={Videos} />} /> */}
            {/* <Route path="/character/:modelId" element={<ProtectedRoute component={Character}/>} /> */}
            <Route path="/admin/re-editor" element={<ProtectedRoute component={RealEstateEditor} />} />
          
            <Route path="/tester/character" element={<Character />}/>
            <Route path="/tester/audio" element={<Audio />}/>
            <Route path="/tester/video" element={<Video />} />


            {/* <Route path="/tester-api/"  element={<ProtectedRoute component={GeneratedVideos} />}/> */}
            <Route path="/admin/"  element={<ProtectedRoute component={GeneratedVideos} />}/>

            <Route path="/admin/generate-video"  element={<ProtectedRoute component={GenerateVideo} />}/>
            <Route path="/admin/generated-videos"  element={<ProtectedRoute component={GeneratedVideos} />}/>
            <Route path="/admin/input-videos"  element={<ProtectedRoute component={InputedVideos} />}/>
            <Route path="/admin/input-images"  element={<ProtectedRoute component={InputedImages} />}/>
            <Route path="/admin/voices"  element={<ProtectedRoute component={Voices} />}/>


            <Route path="/admin/instagram"  element={<ProtectedRoute component={Instagram} />}/>

            <Route path="*" element={<Home />} />
        </Routes>
    );
};

export default App;
