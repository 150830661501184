import { getStoredUser } from "../components/auth/auth";

export const all = (thevideo) => {
    return [
        {
            id: "curl",
            query: `curl --location 'https://svc.staging.squadz.live/api/generate/all?orderby=createdate%20desc' \
--header 'x-api-key: ${getStoredUser().id}'`,
        },
        {
            id: "http",
            query: `GET /api/generate/all?orderby=createdate desc HTTP/1.1
Host: svc.staging.squadz.live
x-api-key: ${getStoredUser().id}`,
        },
        {
            id: "javascript-fetch",

            query: `const myHeaders = new Headers();
myHeaders.append("x-api-key", "${getStoredUser().id}");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch("https://svc.staging.squadz.live/api/generate/all?orderby=createdate desc", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));`,
        },
    ];
};
