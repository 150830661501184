import { dateFormatter } from "src/common/utils/utils";

const DetailImage = (props) => {
    const { image } = props;
    return (
        <div className="bg-white p-4 gap-3 flex flex-col  ">
            {image.url && (
                // <image src={image.url} playsInline controls className="object-contain w-full h-full" />
                <img src={image.url} className="object-contain max-w-full max-h-600 min-h-600 bg-black relative overflow-hidden rounded-2xl" />
            )}

            <div className="flex flex-col px-3 pt-8 gap-3 justify-between ">
                <div className="flexp flex flex-row gap-4">
                    <div className="opacity-40">id : </div> <div>{image.id}</div>
                </div>

                <div className="flexp  flex flex-row gap-4">
                    <div className="opacity-40">Upload Date : </div> <div> {dateFormatter(image.uploadDate)}</div>
                </div>

                <div className="flex flex-row justify-between items-center pt-5  pb-2">
                    <div>
                        <a
                            href={image.url}
                            target="_blank"
                            download={image.url}
                            className="button cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                        >
                            Download
                        </a>
                    </div>

                    {/* <div>
                        <button
                            className="button button-gray cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                            onClick={async () => {
                                await handleImage({ opr: "delete", imageId: image.id });
                            }}
                        >
                            Delete
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default DetailImage;
