import { useEffect, useState } from "react";
import FileUploader from "../../../pages/tester-api/components/file-uploader";
import { createVoice } from "src/common/services/enduser/voices";
import { BiUserVoice } from "react-icons/bi";
import Preloader from "src/common/components/preloader/preloader";

const CreateVoice = (props) => {
    const tabs = [
        {
            id: "upload",
            label: "Upload",
        },
        {
            id: "url",
            label: "URL",
        },
    ];

    const [tab, setTab] = useState(tabs[0]);
    const [fetching, setFetching] = useState(false);

    const [voiceData, setVoiceData] = useState({
        voiceName: "",
        voiceDescription: "",
        fileURL: null,
    });

    useEffect(() => {
        
        if (props.onUse && props.onUse.url) {
            voiceData.fileURL = props.onUse.url;
            setVoiceData({ ...voiceData });
        }
    }, [props.onUse]);

    const handleCreate = async () => {
        if (voiceData.voiceName !== "" && voiceData.fileURL !== null && voiceData.voiceDescription !==null) {
            console.log(voiceData);
            setFetching(true);
            try {
                const create_res = await createVoice(voiceData);
                console.log(create_res);
                props.OnSuccess(true);
            } catch (error) {
                console.log(error);
                props.OnError(true);
            }
            setFetching(false);
        }
    };

    return (
        <div className="flex flex-col p-10 gap-6 relative overflow-hidden">
            {fetching && <Preloader/>}
            <div className="">
                <div className="text-xl font-medium tracking-snug">Create Voice</div>
            </div>

            <div className="flex flex-col gap-6 ">
                <div className="flex flex-col gap-2">
                    <div>Name: </div>
                    <div className="border border-lightblue100 border-2xl rounded-2xl">
                        <input
                            type="text"
                            placeholder="Voice name"
                            value={voiceData.voiceName}
                            onChange={(e) => {
                                voiceData.voiceName = e.target.value;
                                setVoiceData({ ...voiceData });
                            }}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div>Description: </div>
                    <div className="border border-lightblue100 border-2xl rounded-2xl">
                        <textarea
                            placeholder="Voice Description"
                            type="text"
                            className="p-4"
                            value={voiceData.voiceDescription}
                            onChange={(e) => {
                                voiceData.voiceDescription = e.target.value;
                                setVoiceData({ ...voiceData });
                            }}
                        />
                    </div>
                </div>
                {!props.onUse && (
                    <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2">
                        {tabs.map((thetab, t) => (
                            <div
                                key={t}
                                className={`transition flex-1 cursor-pointer ${
                                    thetab.id === tab.id ? "opacity-100 font-semibold bg-white rounded-2xl py-2" : "opacity-50"
                                }`}
                                onClick={() => {
                                    setTab(thetab);
                                }}
                            >
                                {thetab.label}
                            </div>
                        ))}
                    </div>
                )}

                {tab.id === "upload" && (
                    <FileUploader
                        file={voiceData.fileURL}
                        accepts={`video/*`}
                        OnUploading={() => {
                            setFetching(true);
                        }}
                        OnRemove={() => {
                            voiceData.fileURL = null;
                            setVoiceData({ ...voiceData });
                        }}
                        OnSuccess={(fileurl) => {
                            setFetching(false);
                            voiceData.fileURL = fileurl;
                            setVoiceData({ ...voiceData });
                        }}
                    />
                )}
                {tab.id === "url" && (
                    <div className="flex flex-col gap-4 py-2">
                        <input
                            type="text"
                            placeholder="https://your_audio_url_here"
                            className="w-full border border-lightblue100 bg-lightblue50 rounded-2xl"
                            onChange={(e) => {
                                voiceData.fileURL = e.target.value;
                                setVoiceData({ ...voiceData });
                            }}
                        />
                        <div className="opacity-50">Enter audio url.</div>
                    </div>
                )}

                <button
                    disabled={voiceData.voiceName === "" || voiceData.fileURL === null || voiceData.fileURL.indexOf("http") === -1 || voiceData.voiceDescription === ""}
                    className="button button-small py-3 flex gap-3 items-center justify-center"
                    onClick={async () => {
                        await handleCreate();
                    }}
                >
                    <BiUserVoice className="text-xl" />
                    Create Voice
                </button>
            </div>
        </div>
    );
};

export default CreateVoice;
