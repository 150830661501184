import { FiTrash2 } from "react-icons/fi";

import { fontFamily, fontSizes, fontStyles, fontWeights } from "../data-options";
import UiSelect from "../../components/ui-select";
import UiImage from "../../components/ui-image";
import UiDSection from "../../components/ui-dsection";
import UiDuration from "../../components/ui-duration";

const OpeningPage = (props) => {
    const { data, scene } = props;
    return (
        <>
            {scene && (
                <>
                    <div className="px-6 py-5   border-b border-lightblue100 flex items-center justify-between">
                        <div className="font-semibold">Opening Page</div>
                        <FiTrash2 className="opacity-40 hover:opacity-100 z-20 cursor-pointer" onClick={() => {}} />
                    </div>
                    <UiDSection title={`Details`}>
                        <div className="flex flex-col gap-2 ">
                            <div className="">Logo:</div>

                            <UiImage
                                data={scene.logo}
                                OnSuccess={(file) => {
                                    scene.logo = file;
                                    props.OnSet({ ...scene });
                                }}
                            />
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <div className="">Image:</div>
                            <UiImage
                                data={scene.image}
                                OnSuccess={(file) => {
                                    scene.image = file;
                                    props.OnSet({ ...scene });
                                }}
                            />
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <div className="">Address:</div>
                            <div className="p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                <textarea
                                    row="1"
                                    className="p-0"
                                    value={scene.address}
                                    onChange={(e) => {
                                        scene.address = e.target.value;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <div className="">Price:</div>
                            <div className="p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                <textarea
                                    row="1"
                                    className="p-0"
                                    value={scene.price}
                                    onChange={(e) => {
                                        scene.price = e.target.value;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex-1 flex flex-row items-center justify-between gap-2">
                            <div className="flex w-1/2">Square Feet :</div>

                            <div className="flex flex-row items-center gap-3 w-1/4 ">
                                <div className="p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                    <input
                                        type="number"
                                        min={0}
                                        className="w-full flex-1 outline-none text-center"
                                        value={scene.sqfeet}
                                        onChange={(e) => {
                                            scene.sqfeet = e.target.value;
                                            props.OnSet({ ...scene });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-1 flex flex-row items-center justify-between gap-2">
                            <div className="flex w-1/2">Beds :</div>

                            <div className="flex flex-row items-center gap-3 w-1/4 ">
                                <div className="p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                    <input
                                        type="number"
                                        min={0}
                                        className="w-full flex-1 outline-none text-center"
                                        value={scene.num_of_beds}
                                        onChange={(e) => {
                                            scene.num_of_beds = e.target.value;
                                            props.OnSet({ ...scene });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex-1 flex flex-row items-center justify-between gap-2">
                            <div className="flex w-1/2">Baths :</div>

                            <div className="flex flex-row items-center gap-3 w-1/4 ">
                                <div className="p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                    <input
                                        type="number"
                                        min={0}
                                        className="w-full flex-1 outline-none text-center"
                                        value={scene.num_of_baths}
                                        onChange={(e) => {
                                            scene.num_of_baths = e.target.value;
                                            props.OnSet({ ...scene });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </UiDSection>

                    <UiDSection title={`Duration`}>
                        <UiDuration
                            data={data}
                            scene={scene}
                            OnSet={(scene) => {
                                props.OnSet({ ...scene });
                            }}
                        />
                    </UiDSection>

                    <UiDSection title={`Typography`}>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-row gap-4">
                                <UiSelect
                                    data={fontFamily}
                                    value={scene.font}
                                    OnSet={(font) => {
                                        scene.font = font;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                                <div className="w-24">
                                    <UiSelect
                                        data={fontSizes}
                                        value={scene.font_size}
                                        OnSet={(fontsize) => {
                                            scene.font_size = fontsize;
                                            props.OnSet({ ...scene });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </UiDSection>
                </>
            )}
        </>
    );
};

export default OpeningPage;
