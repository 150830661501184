import { useEffect } from "react";
import axios from "axios";

const Instagram = (props) => {
    useEffect(() => {}, []);

    const auth = () => {
        const resi = axios.get(
            `https://api.instagram.com/oauth/authorize?client_id=500902639488027&redirect_uri=https://digitalu.live/admin/instagram&response_type=code&scope=instagram_basic,instagram_content_publish`
        );
    };

    return (
        <div className="flex flex-1 w-full h-full items-center justify-center">
            <button
                className="button"
                onClick={() => {
                    auth();
                }}
            >
                Auth
            </button>
        </div>
    );
};

export default Instagram;
