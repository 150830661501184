import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { getStoredUser, logout } from "src/common/components/auth/auth";

import defaultPic from "src/common/assets/images/default_pic_small.png";
import Iicon from "src/common/components/iicon/iicon";

const UserCard = (props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState();

    useEffect(() => {
        let theUser = getStoredUser();
      
        if (theUser && theUser.id) {
            setUser(theUser);
        }
    }, []);
    return (
        <>
            {user && user.id && (
                <div className="flex items-center justify center gap-3">
                    <div
                        className="w-12 h-12 rounded-2xl bg-lightblue100 relative overflow-hidden border border-lightblue100"
                        onClick={() => {
                            navigate(`/admin/dashboard`);
                        }}
                    >
                        {user.tkey !== "" ? (
                            
                            <img className="object-cover w-full h-full" src={`https://${user.tbucket}.s3.amazonaws.com/${user.tkey}`} />
                        ) : (
                            <img className="object-cover  w-full h-full" src={defaultPic} />
                        )}
                    </div>
                    {user.name !=='' &&
                    <div className="flex-1">
                        {user.name}
                    </div>
}

                    <div
                        className="border-l border-zinc-500 pl-4 ms-3 cursor-pointer opacity-40 hover:opacity-100 transition-all"
                        onClick={() => {
                            logout();
                            setUser(null);
                            navigate("/login");
                        }}
                    >
                        <Iicon icon={`logout`} />
                    </div>
                </div>
            )}
        </>
    );
};

export default UserCard;
