import { useEffect, useState, useRef } from "react";
import Dbox from "src/common/components/dbox/dbox";
import { dateFormatter } from "src/common/utils/utils";
import VideoPlayer from "src/common/components/video-player/video-player";
import { RiMore2Fill } from "react-icons/ri";
import Popup from "src/common/components/popup/popup";
import { deleteInputVideo } from "src/common/services/enduser/input";
import { useLocation, useNavigate } from "react-router-dom";
import More from "./card-video-more";

const CardVideo = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { video, genData } = props;
    const [showMore, setShowMore] = useState();

    const vname = video.url.split('/')[ video.url.split('/').length - 1]

    const handleVideo = async ({ opr, videoId, video }) => {
        switch (opr) {
            case "delete":
                if (videoId) {
                    const del_res = await deleteInputVideo({ videoId });
                    console.log("del_res :", del_res);

                    props.OnDelete(true);
                }
                break;
            case "onuse":
                if (video) {
                    const thevideo = { ...video };

                    if (thevideo.id === genData?.videoId) {
                        props.OnUse({});
                    } else {
                        thevideo.type = "video";
                        props.OnUse({ ...thevideo });
                    }
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            <Dbox
                className={`aspect-square flex flex-col relative overflow-hidden 
                    ${props.for && genData && genData.videoId !== video.id ? "hover:border-4 hover:border-indigo-500" : ""}
                    ${props.for && genData && genData.videoId === video.id ? "p-2 border-4 !border-indigo-500" : ""}
           `}
                data-id={video.id}
            >
                <div
                    className={`relative max-h-full rounded-2xl flex-1 cursor-pointer ${genData && genData.videoId === video.id ? "bg-blue" : " bg-zinc-950"}`}
                >
                    {video.url && (
                        <VideoPlayer
                            image={`${video.url}/frame1.jpg`}
                            src={video.url}
                            className={`${genData && genData.videoId === video.id ? "opacity-50" : ""}`}
                            rounded={false}
                            fluid={true}
                            // hoverPlay={props.for ? false : true}
                            // controls={props.for ? true : false}
                            onClick={() => {
                                if (video.url) {
                                    {
                                        props.for === "select" && handleVideo({ opr: "onuse", video });
                                    }
                                    {
                                        !props.for && navigate(location.pathname, { state: { show: "detail-video", data: video } });
                                    }
                                }
                                // setShowPrev(true);
                            }}
                        />
                    )}
                </div>
                {/* <div
                    className="absolute top-0 left-0 w-full h-full z-10 cursor-pointer"
                    onClick={() => {
                        if (video.url) {
                            {
                                props.for === "select" && handleVideo({ opr: "onuse", video });
                            }
                            {
                                !props.for && navigate(location.pathname, { state: { show: "detail-video", data: video } });
                            }
                        }
                        // setShowPrev(true);
                    }}
                ></div> */}
                {showMore && (
                    <More
                        thesrc={video.url}
                        video={video}
                        OnDelete={async () => {
                            await handleVideo({ opr: "delete", videoId: video.id });
                        }}
                        OnClose={() => {
                            setShowMore(false);
                        }}
                    />
                )}

                <div className="p-3 absolute top-0 left-0 w-full flex justify-between z-20">
                    <div className="p-2">
                        {/* {genData?.videoId !== video.id ? (
                            <button
                                className="button button-small px-4"
                                onClick={() => {
                                    handleVideo({ opr: "onuse", video });
                                }}
                            >
                                use
                            </button>
                        ) : (
                            <div className="bg-indigo-500/20 px-4 py-1 rounded-full text-white border border-blue">selected</div>
                        )} */}

                        {/* {genData && genData.videoId !== video.id && <div className="bg-indigo-500/20 px-4 py-1 rounded-full text-white border border-blue">selected</div>} */}
                    </div>

                    {video.url && (
                        <div
                            className="bg-black/60 rounded-full p-3 cursor-pointer"
                            onClick={() => {
                                setShowMore(true);
                            }}
                        >
                            <RiMore2Fill className="text-white text-xl" />
                        </div>
                    )}

                    {/* <div
                        className="bg-black/60 rounded-full p-3 cursor-pointer flex items-center justify-center"
                        onClick={() => {
                            setShowPrev(true);
                        }}
                    >
                        <BiExpandAlt className="text-white" />
                    </div> */}
                  
                </div>

                <div className="absolute bottom-5 left-5 bg-black/60  rounded-2xl px-3 py-1 text-white text-xs">
                    <div>{vname}</div>
                    <div className="opacity-40">{dateFormatter(video.uploadDate)}</div>
                </div>
            </Dbox>
        </>
    );
};

export default CardVideo;
