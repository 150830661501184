import { useEffect, useState } from "react";
import { getStoredUser } from "src/common/components/auth/auth";

import { copyToClipboard } from "../utils/utils";
import { FaRegCopy } from "react-icons/fa";
import { LuCheckCircle } from "react-icons/lu";

const ApiBox = (props) => {
    const { video, data } = props;
    const [copied, setCopied] = useState(false);

    const thevideo = { ...video };
    delete thevideo.id;
    delete thevideo.createdate;
    delete thevideo.result;
    delete thevideo.state;

    //     const apis = [
    //         {
    //             id: "curl",
    //             query: `curl --location 'https://svc.staging.squadz.live/api/generate/video'
    // --header 'x-api-key: ${getStoredUser().id}'
    // --header 'Content-Type: application/json'
    // --data '${JSON.stringify(thevideo)}'`,
    //         },
    //         {
    //             id: "http",
    //             query: `POST /api/generate/video HTTP/1.1
    // Host: svc.staging.squadz.live
    // x-api-key: ${getStoredUser().id}

    // Content-Type: application/json
    // Content-Length: 482

    // ${JSON.stringify(thevideo)}`,
    //         },
    //         {
    //             id: "javascript-fetch",
    //             query: `const myHeaders = new Headers();
    // myHeaders.append("x-api-key",  "${getStoredUser().id}");
    // myHeaders.append("Content-Type", "application/json");

    // const raw = JSON.stringify(${JSON.stringify(thevideo)});

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow"
    // };

    // fetch("https://svc.staging.squadz.live/api/generate/video", requestOptions)
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) => console.error(error));`,
    //         },
    //     ];

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
                clearTimeout(timeout);
            }, 2000);
        }
    }, [copied]);

    const [selected, setSelected] = useState();

    useEffect(() => {
        if (data) {
            setSelected(data[0]);
        }
    }, [data]);

    return (
        <div className="flex-1 flex flex-col pb-3 border border-lightblue100 rounded-2xl bg-lightblue50 h-full relative">
            <div className="flex flex-row gap-2 p-3">
                {data &&
                    data.map((api, a) => (
                        <div
                            key={a}
                            className={`px-4 py-1 cursor-pointer ${
                                selected && selected.id === api.id ? "bg-white rounded-full shadow-lg shadow-indigo-500/10" : "opacity-40"
                            }`}
                            onClick={() => {
                                setSelected(api);
                            }}
                        >
                            {api.id}
                        </div>
                    ))}
            </div>
            {selected && (
                <>
                    <div
                        className="transition-all absolute bottom-4 right-4 bg-white rounded-full p-4 shadow-lg shadow-indigo-500/30 cursor-pointer hover:opacity-50 z-50 "
                        onClick={() => {
                            setCopied(copyToClipboard(selected.query));
                        }}
                    >
                        {!copied ? <FaRegCopy className="text-lg" /> : <LuCheckCircle className="text-lg text-emerald-500" />}
                    </div>

                    <div className="flex-1 relative overflow-hidden">
                        <div className="absolute p-6 left-0 top-0 w-full h-full overflow-y-auto">
                            <pre className="text-wrap">{selected.query}</pre>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ApiBox;
