import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Iicon from "src/common/components/iicon/iicon";
import Logo from "src/common/components/logo/logo";

import { BiUserVoice } from "react-icons/bi";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { FaRegImages } from "react-icons/fa";
import { PiVideoBold } from "react-icons/pi";

const AdminSidebar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { pathname } = useLocation();
    const [showMenu, setShowMenu] = useState(false);

    const mainmenu = [
        {
            id: "generated-videos",
            path: "/admin/generated-videos",
            name: "Generated Videos",
            icon: <MdOutlineVideoLibrary className="text-lg" />,
        },
        {
            id: "input-videos",
            path: "/admin/input-videos",
            name: "Your Videos",
            icon: <MdOutlineOndemandVideo className="text-lg" />,
        },
        {
            id: "input-images",
            path: "/admin/input-images",
            name: "Your Images",
            icon: <FaRegImages className="text-lg" />,
        },
        {
            id: "voices",
            path: "/admin/voices",
            name: "Your Voices",
            icon: <BiUserVoice className="text-lg" />,
        },

        // {
        //     id: "dashboard",
        //     name: "Dashboard",
        //     icon: "home-blue",
        //     path:"/admin/dashboard"
        // },

        // {
        //     id: "separator",
        // },
        // {
        //     id: "campaigns",
        //     name: "Campaigns",
        //     icon: "campaign-blue",
        //     path:"/admin"
        // },

        // {
        //     id: "integrations",
        //     name: "Integrations",
        //     icon: "puzzle-blue",
        //     path:"/admin/integrations"
        // },

        // {
        //     id: "separator",
        // },
        // {
        //     id: "campaigns",
        //     name: "Campaigns",
        //     icon: "campaign-blue",
        //     path:"/admin/campaigns"
        // },

        // {
        //     id: "templates",
        //     name: "Templates",
        //     icon: "script-blue",
        //     path:"/admin/templates"
        // },
        // {
        //     id: "lists",
        //     name: "Lists",
        //     icon: "csv-blue",
        //     path:"/admin/lists"
        // },
        // {
        //     id: "integrations",
        //     name: "Integrations",
        //     icon: "puzzle-blue",
        //     path:"/admin/integrations"
        // },

        // {
        //     id: "separator",
        // },
        // {
        //     id: "characters",
        //     name: "Characters",
        //     icon: "character-blue",
        //     path: "/admin/characters",
        // },
        // {
        //     id: "videos",
        //     name: "Videos",
        //     icon: "video-blue",
        //     path: "/admin/videos",
        // },
    ];

    return (
        <>
            {showMenu && (
                <div
                    className="fixed w-full h-full bg-slate-800 opacity-80 z-50"
                    onClick={() => {
                        setShowMenu(false);
                    }}
                ></div>
            )}
            <div className="md:hidden flex bg-white border-b border-b-lightblue100 p-8 items-center gap-8 sticky">
                <div
                    className={``}
                    onClick={() => {
                        setShowMenu(true);
                    }}
                >
                    <Iicon icon={"menu"} className={`cursor-pointer`} />
                </div>
                <Logo />
            </div>
            <div className={`admin-sidebar transition-all fixed md:relative w-72 h-full top-0 md:flex  md:left-0  ${showMenu ? "left-0 z-50 " : "-left-72"}`}>
                <div
                    className={` transition-all  fixed w-72 h-full top-0 md:left-0 bg-white dshadow flex flex-col gap-5 text-sm ${
                        showMenu ? "left-0 " : "-left-72"
                    }`}
                >
                    <div className="px-8 py-10 flex justify-center items-center mb-8">
                        <Logo />
                    </div>

                    <div className=" flex justify-center items-center mb-8">
                        <button
                            className="button flex items-center gap-5"
                            onClick={() => {
                                // navigate(`${location.pathname}`, { state: { show: "create-video" } });
                                navigate(`/admin/generate-video`, { state: { todo: "create" } });
                            }}
                        >
                            <div>Generate Video</div>
                            <PiVideoBold className="text-xl" />
                        </button>
                    </div>

                    <div className="menu flex flex-col gap-7 flex-1 ">
                        {mainmenu.map((item, i) => (
                            <React.Fragment key={i}>
                                {item.id !== "separator" ? (
                                    <a
                                        href={`/admin/${item.id}`}
                                        className={`transition-all menu-item px-8 py-1 flex gap-4 items-center text-black  hover:opacity-100 ${
                                            item.path === pathname
                                                ? "opacity-100 border-r-4 border-r-blue text-blue"
                                                : "hover:text-black hover:translate-x-1 opacity-60"
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(`${item.path}`);
                                        }}
                                    >
                                        {item.icon}
                                        {/* <Iicon icon={item.icon} /> */}
                                        <div>{item.name}</div>
                                    </a>
                                ) : (
                                    <div className="h-px bg-slate-100"></div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>

                    {/* <div className="flex gap-4 items-center p-8 ">
                        <UserCard />
                        <div className="w-12 h-12 rounded-2xl bg-lightblue100"></div>
                    <div className="flex-1">James</div> 
                         <Iicon icon={"more"} />
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default AdminSidebar;
