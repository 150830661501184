import { useEffect, useState } from "react";
import { getUser, loginUser, updateUser } from "src/common/services/user";
import { useNavigate } from "react-router-dom";
import { countries } from "src/common/data/countries";
import FormInput from "src/common/components/form/input";
import Preloader from "src/common/components/preloader/preloader";
import UiSelect from "./realestate/components/ui-select";

const AdminLogin = (props) => {
    const navigate = useNavigate();
    const [number, setNumber] = useState("");
    const [areaCode, setAreaCode] = useState("");
    const [country, setCountry] = useState();

    const regNum = /^\d{0,10}$/;

    const regAreaCode = /^\d{1,4}$/;
    const [fetching, setFetching] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    useEffect(() => {
        setAreaCode("+1");
    }, []);

    useEffect(() => {
        if (areaCode) {
            const find = countries.find((cntry) => cntry.code === areaCode);
            if (find) {
                setCountry({ ...find });
            }
        }
    }, [areaCode]);

    const handlePhoneNumberChange = (event) => {
        setErrorMsg(false);
        const inputValue = event.target.value;
        const regex = new RegExp(`^\\d{0,${country?.phoneDigits}}$`);
        // const regex = /^\d{0,10}$/;
        if (regex.test(inputValue)) {
            setNumber(inputValue);
        }
        // if (regex.test(inputValue)) {
        //     setNumber(inputValue);
        //     setIsValid(/^\d*$/.test(areaCode) && regex.test(inputValue));
        // } else {
        //     setIsValid(false);
        // }
    };

    const handleAreaCodeChange = (event) => {
        setErrorMsg(false);
        const inputValue = event.target.value;
        const regex = /^\d{0,4}$/;
        if (regex.test(inputValue)) {
            setAreaCode(inputValue);
        }
        // if (regex.test(inputValue)) {
        //   setAreaCode(inputValue);
        //   setIsValid(regex.test(inputValue) && /^\d*$/.test(number));
        // } else {
        //   setIsValid(false);
        // }
    };

    const signIn = async () => {
        setErrorMsg(false);
        const regex = new RegExp(`^\\d{0,${country?.phoneDigits}}$`);
        const isValid = regex.test(number);

        if (isValid) {
            setFetching(true);
            try {
                let loginRes = await loginUser(`${areaCode}${number}`);
                console.log(`+${areaCode}${number} :: `, loginRes);
                if (loginRes && loginRes.auth) {
                    localStorage.setItem("DIGITALU_auth", loginRes.auth);
                }

                if (loginRes && loginRes.userid) {
                    try {
                        let theUser = await getUser({ id: loginRes.userid }, { userid: loginRes.userid, auth: loginRes.auth });
                        console.log(theUser);

                        if (theUser.isteam !== "true") {
                            await updateUser(loginRes.userid, { isteam: "true" }, false, loginRes.auth);
                        }

                        if (theUser.error) {
                            navigate("/login");
                            //handle error here
                        } else {
                            localStorage.setItem("DIGITALU_user", JSON.stringify(theUser));

                            await new Promise((res) => setTimeout(() => res(), 500));
                            navigate("/admin/");
                        }
                    } catch (error) {
                        setFetching(false);
                        console.log(`getUser error :` + error);
                    }
                } else {
                    setFetching(false);
                }
            } catch (error) {
                setFetching(false);
                console.log(`login error :` + error);
            }
        } else {
            setErrorMsg(true);
        }
    };

    return (
        <>
            {fetching && <Preloader type={"full"} />}
            <div className="flex flex-col gap-4">
                <div className="form-item">
                    <div className="form-item-label mb-2">Phone Number : </div>
                    <div className="flex flex-row gap-3 items-center">
                        <div className="form-item-input flex items-center max-w-52">
                            {/* <div className="pl-4"> + </div> */}
                            {/* <input
                            type="text"
                            className="pl-2 text-center"
                            placeholder={`00`}
                            value={areaCode}
                            onChange={(e) => {
                                // setNumber(e.target.value);
                                handleAreaCodeChange(e);
                            }}
                            onKeyDown={async (e) => {
                                // if (e.key === "Enter" && e.target.value !== "") {
                                //     signIn();
                                // }
                            }}
                        /> */}
                            {country && <div className="pl-5">{country.flag}</div>}
                            <select
                                className="!pl-2"
                                onChange={(e) => {
                                    setAreaCode(e.target.value);

                                    //handleAreaCodeChange(e);
                                }}
                            >
                                {countries.map((country, c) => (
                                    <option key={c} value={country.code} data-country={JSON.stringify(country)}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-item-input">
                            <input type="text" className="max-w-16 !pr-0" value={areaCode} readOnly />
                            <input
                                type="text"
                                style={{ letterSpacing: "1px" }}
                                value={number}
                                placeholder={`${"88888812346789".substring(0, country?.phoneDigits)}`}
                                onChange={(e) => {
                                    handlePhoneNumberChange(e);
                                    // setNumber(e.target.value);
                                }}
                                onKeyDown={async (e) => {
                                    // if (e.key === "Enter" && e.target.value !== "") {
                                    //     signIn();
                                    // }
                                }}
                            />
                        </div>
                    </div>
                    {errorMsg === true && <div className="text-rose-500 mt-4">Please add a valid phone number.</div>}
                </div>

                <div className="flex text-center gap-1 items-center justify-center">or <div className="cursor-pointer text-blue">Login</div> using your email</div>
            </div>
            <div className="flex flex-col gap-4">
                <button
                    className="button text-base rounded-full px-8 py-6"
                    onClick={() => {
                        signIn();
                    }}
                >
                    Login
                </button>
                <div className="flex gap-2  items-center justify-center">Don't have an account yet. <div className="cursor-pointer text-blue">Signup here.</div></div>
            </div>
        </>
    );
};

export default AdminLogin;
