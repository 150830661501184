import { useEffect, useState } from "react";
import { MdAlternateEmail, MdPhoneIphone } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";

import { copyToClipboard } from "src/common/utils/utils";
import { FaRegCopy } from "react-icons/fa";
import { LuCheckCircle } from "react-icons/lu";

const Share = (props) => {
    const [copied, setCopied] = useState(false);
    const tabs = [
        {
            id: "sms",
            label: "SMS",
            icon: <MdPhoneIphone className="text-3xl" />,
        },
        {
            id: "email",
            label: "Email",
            icon: <MdAlternateEmail className="text-2xl" />,
        },
        {
            id: "twitter",
            label: "Twitter",
            icon: <FaXTwitter className="text-2xl" />,
        },
    ];

    return (
        <div className="flex flex-col gap-10 p-10">
            <div className="">
                <div className="text-xl font-medium tracking-snug">Share Video</div>
            </div>

            <div className="flex flex-row gap-10 items-center justify-center py-6">
                {tabs.map((tab, t) => (
                    <div key={t} className="flex-1 flex flex-col gap-4 justify-center items-center group  cursor-pointer">
                        <div className="transition-all border border-lightblue100 bg-lightblue50 flex w-24 h-24 items-center justify-center rounded-full text-blue group-hover:bg-blue group-hover:text-white">
                            {tab.icon}
                        </div>
                        <div className="group-hover:text-blue">{tab.label}</div>
                    </div>
                ))}
            </div>

            <div className="flex flex-col gap-4">
                <div>Video link : </div>
                <div className="border border-lightblue100 bg-lightblue50 rounded-2xl p-4 items-center flex relative overflow-auto">
                    <div className="flex-1 ">{props.video.result}</div>
                    
                </div>
                <div
                        className="transition-all absolute bottom-4 right-4 bg-white rounded-full p-4 shadow-lg shadow-indigo-500/30 cursor-pointer hover:opacity-50 z-50 "
                        onClick={() => {
                            setCopied(copyToClipboard(props.video.result));
                        }}
                    >
                        {!copied ? <FaRegCopy className="text-lg" /> : <LuCheckCircle className="text-lg text-emerald-500" />}
                    </div>
            </div>
        </div>
    );
};

export default Share;
