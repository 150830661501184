import { useEffect, useState } from "react";
import Knob from "src/common/components/form/knob";
import { fontFamily, fontSizes, fontStyles, fontWeights, transitions } from "../data-options";
import UiSelect from "../../components/ui-select";
import UiPhotos from "../../components/ui-photos";
import Photo from "./photo";
import UiAlignment from "../../components/ui-alignment";
import UiDuration from "../../components/ui-duration";
import UiDSection from "../../components/ui-dsection";

import { FiTrash2 } from "react-icons/fi";

const Scene = (props) => {
    const { data, scene } = props;
    const [selPhoto, setSelPhoto] = useState(null);

    useEffect(() => {
        if (scene.FROM_TIMELINE) {
            setSelPhoto(null);
            delete scene.FROM_TIMELINE;
        }
    }, [scene]);

    return (
        <>
            {scene && (
                <div className={"relative h-full w-full"}>
                    {selPhoto && (
                        <Photo
                            data={data}
                            scene={scene}
                            photo={selPhoto}
                            OnSet={(photo) => {
                                scene.photos.map((p) => (p.id === photo.id ? photo : p));
                                props.OnSet({ ...scene });
                            }}
                            OnClose={() => {
                                setSelPhoto(null);
                            }}
                        />
                    )}

                    <div className="px-6 py-5 border-b border-lightblue100 flex items-center justify-between">
                        <div className="font-semibold">Scene</div>
                        <FiTrash2 className="opacity-40 hover:opacity-100 z-20 cursor-pointer" onClick={() => {}} />
                    </div>

                    <UiDSection title={`Name`}>
                        <div className="flex flex-row items-center">
                            <div className="flex-1 p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                <textarea
                                    row={1}
                                    className="p-0"
                                    value={scene.scene}
                                    onChange={(e) => {
                                        scene.scene = e.target.value;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                            </div>
                        </div>
                    </UiDSection>
                    <UiDSection title={`Photos`}>
                        <div className="flex flex-col gap-4 ">
                            <UiPhotos
                                data={data}
                                scene={scene}
                                onClick={(photo) => {
                                    setSelPhoto(photo);
                                }}
                                OnSet={(scene) => {
                                    props.OnSet({ ...scene });
                                }}
                                OnAdd={(photoObj) => {
                                    scene.photos = [...scene.photos, photoObj];
                                    props.OnSet({ ...scene });
                                }}
                            />
                        </div>
                    </UiDSection>

                    <UiDSection title={`Duration`}>
                        <div className="flex flex-col gap-4">
                            <UiDuration
                                data={data}
                                scene={scene}
                                OnSet={(scene) => {
                                    props.OnSet({ ...scene });
                                }}
                            />
                            <div className="flex-1 flex">
                                <UiSelect
                                    data={transitions}
                                    value={scene.transition_effect}
                                    OnSet={(trans) => {
                                        scene.transition_effect = trans;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                            </div>
                        </div>
                    </UiDSection>

                    {/* <UiDSection title={`Transition`}>
                        <div className="flex-1 flex">
                            <UiSelect data={transitions} value={scene.transition_effect} />
                        </div>
                    </UiDSection> */}

                    <UiDSection title={`Settings`}>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-row">
                                <div className="flex-1">Show AI Model : </div>
                                <Knob
                                    value={scene.bot.visible}
                                    OnChange={(val) => {
                                        scene.bot.visible = val;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                            </div>
                            {scene.bot.visible && (
                                <div className="flex flex-row gap-4 items-center justify-between">
                                    <div className="text-nowrap">x : </div>
                                    <div className="flex gap-1 items-center p-2 rounded-xl relative overflow-hidden outline-none border border-lightblue100">
                                        <input
                                            type="number"
                                            className="w-full flex-1 outline-none text-center"
                                            value={scene.bot.x}
                                            onChange={(e) => {
                                                scene.bot.x = parseInt(e.target.value);
                                                props.OnSet({ ...scene });
                                            }}
                                        />
                                        <div className="opacity-40 text-xs">px</div>
                                    </div>

                                    <div className="text-nowrap">y : </div>
                                    <div className="flex gap-1 items-center p-2 rounded-xl relative overflow-hidden outline-none border border-lightblue100">
                                        <input
                                            type="number"
                                            className="w-full flex-1 outline-none text-center"
                                            value={scene.bot.y}
                                            onChange={(e) => {
                                                scene.bot.y = parseInt(e.target.value);
                                                props.OnSet({ ...scene });
                                            }}
                                        />
                                        <div className="opacity-40 text-xs">px</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </UiDSection>

                    <UiDSection title={`Subtitle`}>
                        <div className="flex flex-row">
                            <div className="flex-1">Show Subtitle :</div>
                            <Knob
                                value={scene.text.visible}
                                OnChange={(val) => {
                                    scene.text.visible = val;
                                    props.OnSet({ ...scene });
                                }}
                            />
                        </div>
                        {scene.text.visible === true && (
                            <>
                                <div className="flex-1 flex flex-row items-center">
                                    <div className="flex-1  flex w-1/2">Position : </div>
                                    <div>
                                        <div className="flex-1  flex flex-col  gap-1">
                                            <UiAlignment
                                                value={scene.text.alignment}
                                                scene={scene}
                                                OnSet={(data) => {
                                                    scene.text.alignment = data;
                                                    props.OnSet({ ...scene });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1  flex uppercase text-2xs opacity-40 font-semibold">Margin </div>
                                <div className="flex flex-row gap-4">
                                    <div className="flex flex-col gap-2">
                                        <div className="flex-1 flex">Left : </div>
                                        <div className="flex gap-1 items-center p-2 rounded-xl relative overflow-hidden outline-none border border-lightblue100">
                                            <input
                                                type="number"
                                                className="w-full flex-1 outline-none text-center"
                                                value={scene.text.margin_left}
                                                onChange={(e) => {
                                                    let theval = !e.target.value ? 0 : e.target.value;
                                                    scene.text.margin_left = parseInt(theval);
                                                    props.OnSet({ ...scene });
                                                }}
                                            />
                                            <div className="opacity-40 text-xs">px</div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <div className="flex-1 flex">Top : </div>
                                        <div className="flex gap-1 items-center p-2 rounded-xl relative overflow-hidden outline-none border border-lightblue100">
                                            <input
                                                type="number"
                                                className="w-full flex-1 outline-none text-center"
                                                value={scene.text.margin_vertical}
                                                onChange={(e) => {
                                                    let theval = !e.target.value ? 0 : e.target.value;
                                                    scene.text.margin_vertical = parseInt(theval);
                                                    props.OnSet({ ...scene });
                                                }}
                                            />
                                            <div className="opacity-40 text-xs">px</div>
                                        </div>
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <div className="flex-1 flex">Right : </div>
                                        <div className="flex gap-1 items-center p-2 rounded-xl relative overflow-hidden outline-none border border-lightblue100">
                                            <input
                                                type="number"
                                                className="w-full flex-1 outline-none text-center"
                                                value={scene.text.margin_right}
                                                onChange={(e) => {
                                                    let theval = !e.target.value ? 0 : e.target.value;
                                                    scene.text.margin_right = parseInt(theval);
                                                    props.OnSet({ ...scene });
                                                }}
                                            />
                                            <div className="opacity-40 text-xs">px</div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </UiDSection>

                    <UiDSection title={`Typography`}>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-row gap-4">
                                <UiSelect
                                    data={fontFamily}
                                    value={scene.font}
                                    OnSet={(font) => {
                                        scene.font = font;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                                <div className="w-24">
                                    <UiSelect
                                        data={fontSizes}
                                        value={scene.font_size}
                                        OnSet={(fontsize) => {
                                            scene.font_size = fontsize;
                                            props.OnSet({ ...scene });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </UiDSection>
                </div>
            )}
        </>
    );
};

export default Scene;
