import { useState } from "react";
import Preloader from "src/common/components/preloader/preloader";
import { upload } from "src/common/services/utils";

const UiImage = (props) => {
    const { data } = props;
    const [fetching, setFetching] = useState(false);
 

    const handleUpload = async (file) => {
        setFetching(true)
        try {
            const res = await upload({ file });
            console.log("upload res :: ", res);
            if(res.data[0]?.cache){
                props.OnSuccess(res.data[0]?.cache)
            }
        } catch (error) {
            console.log("upload error :: ", error);
        }
        setFetching(false)
    };

    return (
        <div className="border p-2 bg-lightblue50 border-lightblue100 rounded-xl relative overflow-hidden flex flex-row items-center gap-4">
           {fetching &&  <div className="absolute w-full h-full z-50 left-0 top-0 bg-white/80 flex items-center justify-center"><Preloader type={`inline`} /></div> }

            <div className="relative min-h-16 max-h-16  min-w-16 max-w-16 relative overflow-hidden rounded-lg flex items-center justify-center">
                <img src={data} className="absolute left-0 top-0 object-cover w-full h-full" />
            </div>
            <div className="flex-1 opacity-60">...{data.slice(-10)}</div>
            <label className="relative cursor-pointer">
                <button className="button button-small button-white px-4 text-xs">change</button>
                <input
                    type="file"
                    accept={`image/*`}
                    className="absolute z-30 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                    onChange={async (e) => {
                        console.log(e.target.files[0]);
                        await handleUpload(e.target.files[0])
                    }}
                />
            </label>


        </div>
    );
};

export default UiImage;
