import { useEffect, useState } from "react";
import Dbox from "src/common/components/dbox/dbox";
import VideoPlayer from "src/common/components/video-player/video-player";
import { checkFileType } from "src/common/utils/utils";

const VideoUrl = (props) => {
    const { genData } = props;

    const [val, setVal] = useState("");

    useEffect(() => {
        if (genData.videoURL) {
            setVal(genData.videoURL);
        } else if (genData.imageURL) {
            setVal(genData.imageURL);
        }else if (genData.URL) {
            setVal(genData.URL);
        }
    }, [genData]);

    useEffect(() => {

        if (checkFileType(val) === "image") {
            delete genData.videoId;
            delete genData.videoURL;
            delete genData.imageId;
            genData.imageURL = val;
        } else if (checkFileType(val) === "video") {
            delete genData.videoId;
            genData.videoURL = val;
            delete genData.imageId;
            delete genData.imageURL;
        }else{
            delete genData.videoId;
            delete genData.videoURL;
            delete genData.imageId;
            delete genData.imageURL;
            genData.URL = val; //TEMPORARY
        }

        props.OnSet({ ...genData });
    }, [val]);

    return (
        <Dbox className="p-10">
            <div className="flex flex-col gap-4 py-2">
                {genData.imageURL && (
                    <div className="w-96 h-96 relative overflow-hidden aspect-square bg-black mx-auto rounded-2xl">
                        <img src={genData.imageURL} />
                    </div>
                )}
                {genData.videoURL && (
                    <div className="w-96 h-96 relative overflow-hidden aspect-square bg-black mx-auto rounded-2xl">
                        <VideoPlayer src={genData.videoURL} className={``} fluid={true} />
                    </div>
                )}

                <div className="w-912 mx-auto">
                    <div className="flex flex-col gap-4 py-2">
                        <div className="font-semibold">Enter a url to a video.</div>
                        <div className="border border-lightblue100 bg-lightblue50 rounded-2xl">
                            <input
                                type="text"
                                value={val}
                                placeholder="https://your_video_url_here"
                                className="w-full "
                                onChange={(e) => {
                                    setVal(e.target.value);

                                    // if (checkFileType(e.target.value) === "image") {
                                    //     delete genData.videoId;
                                    //     delete genData.videoURL;
                                    //     delete genData.imageId;
                                    //     genData.imageURL = e.target.value;
                                    // } else if (checkFileType(e.target.value) === "video") {
                                    //     delete genData.videoId;
                                    //     genData.videoURL = e.target.value;
                                    //     delete genData.imageId;
                                    //     delete genData.imageURL;
                                    // }w
                                    // props.OnSet({ ...genData });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Dbox>
    );
};

export default VideoUrl;
