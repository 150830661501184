import { useNavigate } from "react-router-dom";

import vidStep1 from "src/common/assets/vids/step1.mp4";
import vidStep2 from "src/common/assets/vids/step2.mp4";
import vidStep3 from "src/common/assets/vids/step3.mp4";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useState } from "react";
gsap.registerPlugin(useGSAP);

const How = (props) => {
    const main = useRef();
    const navigate = useNavigate();
    const [selectedStep, setSelectedStep] = useState(1);

    useGSAP(
        () => {
            gsap.set(`.step-video-1`, {
                opacity: 1,
                x: 0,
            });

            gsap.from(".container", {
                scale: 1.3,
                opacity:0,
                y: "200",
                scrollTrigger: {
                    trigger: ".container",
                    start: "0% 80%",
                    end: "+=100",
                    scrub: 1,
                },
            });
        },
        { scope: main }
    );

    const StepItem = (props) => {
        return (
            <div
                ref={main}
                className={`step-item step-item-${props.index}  flex flex-col md:flex-row gap-4 md:gap-10 ${
                    props.selected ? "opacity-100" : "opacity-40"
                } cursor-pointer transition-all hover:opacity-100 hover:translate-x-2`}
                onClick={() => {
                    setSelectedStep(props.index);

                    const tl = gsap.timeline();
                    tl.to(`.step-video`, {
                        opacity: 0,
                        x: 100,

                        duration: 0.1,
                    });
                    tl.to(`.step-video-${props.index}`, {
                        opacity: 1,
                        x: 0,

                        duration: 0.3,
                    });
                }}
            >
                <div
                    className={`${
                        props.selected ? "bg-blue text-white" : "bg-lightblue100 text-blue"
                    }  rounded-2xl text-base md:text-xl p-3 md:p-5 w-16 md:w-20 h-16 flex items-center justify-center text-center`}
                >
                    {`0${props.index}`}
                </div>
                <div className="flex flex-col gap-3 md:gap-6">
                    <div className="text-lg md:text-2xl font-semibold leading-tight tracking-tight">{props.title}</div>
                    <div className="text-sm md:text-lg opacity-50 leading-snug tracking-snug hidden md:flex">{props.children}</div>
                </div>
            </div>
        );
    };

    return (
        <div ref={main} className="section-how section flex relative w-full z-20">
            <div
                className="bg-blur absolute rounded-full z-0 opacity-50 left-1/2 -translate-x-1/2 bottom-0"
                style={{ background: "#6676FF", width: 600, height: 500, filter: `blur(300px)` }}
            ></div>

            <div className="container mx-auto relative flex-1 flex flex-col z-20 bg-white/50 rounded-3xl px-2 py-14 z-20  md:p-14 backdrop-blur gap-20 md:gap-20">
                <div className="section-title flex flex-col items-center text-cente gap-10 p-10">
                    <div className="sub-title bg-lightblue300/50 backdrop-blur rounded-full py-3 px-10 text-blue uppercase ">From idea to video in minutes</div>
                    <div className="g-title text-center text-4xl leading-tighter md:text-7xl md:leading-tighter tracking-tighter font-semibold ">
                        How our stuff works
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-10  md:gap-20 ">
                    <div className="steps md:w-3/6 flex flex-row md:flex-col flex-1 justify-center gap-12 md:gap-32 py-0 md:py-32 px-6 md:px-10">
                        <StepItem selected={selectedStep === 1 ? true : false} index={1} title={`Upload Base Video`}>
                            We will create an avatar based on you uploaded video
                        </StepItem>

                        <StepItem selected={selectedStep === 2 ? true : false} index={2} title={`Personalize Script`}>
                            We will create an avatar based on you uploaded video
                        </StepItem>

                        <StepItem selected={selectedStep === 3 ? true : false} index={3} title={`Generate Videos`}>
                            We will create an avatar based on you uploaded video
                        </StepItem>
                    </div>

                    <div className="steps-preview aspect-video md:min-w-912 md:max-w-912 flex relative">
                        {/* <img src={ssUpload} className="w-full h-full object-cover"  /> */}
                        {/* {selectedStep === 1 && <video src={vidStep1} autoPlay muted playsInline loop className="w-full h-full object-contain" />}
                    {selectedStep === 2 && <video src={vidStep2} autoPlay muted playsInline loop className="w-full h-full object-contain" />}
                    {selectedStep === 3 && <video src={vidStep3} autoPlay muted playsInline loop className="w-full h-full object-contain" />} */}

                        <video
                            src={vidStep1}
                            autoPlay
                            muted
                            playsInline
                            loop
                            className="step-video step-video-1 opacity-0 w-full h-full object-contain bg-white/90 rounded-3xl shadow-2xl border border-lightblue100 shadow-indigo-500/10 w-full h-full absolute overflow-hidden"
                        />
                        <video
                            src={vidStep2}
                            autoPlay
                            muted
                            playsInline
                            loop
                            className="step-video step-video-2 opacity-0 w-full h-full object-contain bg-white/90 rounded-3xl shadow-2xl border border-lightblue100 shadow-indigo-500/10 w-full h-full absolute overflow-hidden"
                        />
                        <video
                            src={vidStep3}
                            autoPlay
                            muted
                            playsInline
                            loop
                            className="step-video step-video-3 opacity-0 w-full h-full object-contain bg-white/90 rounded-3xl shadow-2xl border border-lightblue100 shadow-indigo-500/10 w-full h-full absolute overflow-hidden"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default How;
