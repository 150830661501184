import AdminLayout from "src/layouts/admin-layout";
import { BiUserVoice } from "react-icons/bi";
import ListVoices from "src/modules/admin/voices/list-voices";
import { useLocation, useNavigate } from "react-router-dom";

const Voices = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
            <AdminLayout
                header={() => (
                    <div className="flex flex-1 items-center justify-between">
                        <div className="flex flex-1 flex-row px-0 items-center gap-6">
                            <div className="flex text-xl font-semibold">Your Voices</div>
                            <div
                                className="transition-all bg-white border border-zinc-500/40 rounded-full p-2 px-5  flex items-center gap-2 opacity-60 hover:opacity-100 hover:text-blue hover:border-blue cursor-pointer"
                                onClick={() => {
                                    navigate(location.pathname, { state: { show: "api-box" } });
                                }}
                            >
                                <div>Api</div>
                            </div>

                            {/* <button
                                className="button button-small py-3 flex gap-3 items-center justify-center"
                                onClick={async () => {
                                    navigate(location.pathname, { state: { show: "create-voice" } });
                                }}
                            >
                                <BiUserVoice className="text-xl" />
                                Create Voice
                            </button> */}
                        </div>
                    </div>
                )}
            >
                <div className="container mx-auto flex flex-col flex-1 h-full">
                    <ListVoices />
                </div>
            </AdminLayout>
        </>
    );
};

export default Voices;
