export const fontFamily = ["Montserrat", "Roboto", "Lato", "Open Sans", "Poppins", "Merriweather", "PT Serif"];

export const fontSizes = [10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 40, 48, 64, 96, 128];

export const fontWeights = [
    {
        id: "regular",
        name: "Regular",
    },
    {
        id: "medium",
        name: "Medium",
    },
    {
        id: "semibold",
        name: "Semibold",
    },
    {
        id: "bold",
        name: "Bold",
    },

    {
        id: "black",
        name: "Black",
    },
];

export const fontStyles = [
    {
        id: "none",
        name: "None",
    },
    {
        id: "italic",
        name: "Italic",
    },
    {
        id: "underline",
        name: "Underline",
    },
];

export const transitions = [
    {
        id: "instant",
        name: "Instant",
    },
    {
        id: "fade",
        name: "Fade",
    },
    {
        id: "slide",
        name: "Slide",
    },
    {
        id: "zoom",
        name: "Zoom",
    },
];

export const effects = [
    {
        id: "zoom_in",
        name: "Zoom In"
    },

    {
        id: "zoom_out",
        name: "Zoom Out"
        
    },

    {
        id: "slide_left",
        name: "Slide Left",
    },

    {
        id: "chroma_key",
        name: "Chroma Key",
    },

    {
        id: "bokeh_blur",
        name: "Bokeh Blur",

        radius: "90",
        sigma: "21",
    },
];

export const alignments = [
    {
        id: 1,
        name: "Bottom Left",
    },
    {
        id: 2,
        name: "Bottom Center",
    },
    {
        id: 3,
        name: "Bottom Right",
    },
    {
        id: 4,
        name: "Middle Left",
    },
    {
        id: 5,
        name: "Center",
    },
    {
        id: 6,
        name: "Middle Right",
    },
    {
        id: 7,
        name: "Top Left",
    },
    {
        id: 8,
        name: "Top Center",
    },
    {
        id: 9,
        name: "Top Right",
    },
];
