import Dbox from "src/common/components/dbox/dbox";
import FormInput from "src/common/components/form/input";
import { TbAlertCircle } from "react-icons/tb";


const AddInfo = (props) => {
    const { genData } = props;
    return (
        <div className="flex flex-col gap-10 py-10">
            <div className="flex flex-col gap-3 text-center">
                <div className="text-xl font-semibold">1. Video Details</div>
                <div className="opacity-50">Add a name for your video this will help identify your video.</div>
            </div>
            <div className="flex h-full flex-1 items-center  min-w-912">
            <Dbox className={`relative  overflow-hidden w-full`}>
                <div className="flex-1 flex flex-col p-20 gap-8">
                    <FormInput
                        type={"text"}
                        label={"Name"}
                        description={`Add a name for your video this will help identify your video.`}
                        placeholder={"Your video name"}
                        value={genData.name}
                        onChange={(e) => {
                            const allowedPattern = /^[a-zA-Z0-9\s]*$/;

                            // If the input value does not match the allowed pattern, remove the last character
                            if (!allowedPattern.test(e.target.value)) {
                                e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, "");
                            }

                            genData.name = e.target.value;
                            props.OnSet({ ...genData });
                        }}
                    />

                    <div className="opacity-40 flex gap-2 items-center "><TbAlertCircle className="text-lg"/> Special characters are not allowed.</div>
                </div>
            </Dbox>
            </div>
        </div>
    );
};

export default AddInfo;
