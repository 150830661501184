import { useEffect, useRef, useState } from "react";

import { ReactLenis, useLenis } from "lenis/react";
import { useNavigate } from "react-router-dom";

import Header from "src/layouts/marketing/header";
import Footer from "src/layouts/marketing/footer";
import CTA from "src/layouts/marketing/cta";

// import gsap from "gsap";
// import { useGSAP } from "@gsap/react";
// gsap.registerPlugin(useGSAP, ScrollTrigger);

const MarketingLayout = (props) => {
    const lenis = useLenis((data) => {});

    return (
        
        <ReactLenis root options={{ lerp: 0.1, smoothTouch: true }}>
            <div id="wrapper" className="relative overflow-hidden lightgray10">
                
                <Header />
                {props.children}

                {props.type !== 'no-footer' &&
                <div className="sections flex flex-col gap-20 mt-32">
                    <CTA />
                    <Footer />
                </div>
}
            </div>
        </ReactLenis>
    );
};

export default MarketingLayout;
