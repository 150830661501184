import { useEffect, useRef, useState, useCallback } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

const UiSelect = (props) => {
    const { data } = props;
    const [showSelect, setShowSelect] = useState(false);
    const [selected, setSelected] = useState(props.value);
    const ddRef = useRef(null);


  
    useEffect(() => {
        setSelected(props.value);
    }, [props.value]);

    useEffect(() => {
        if (selected) {
            setShowSelect(false);
        }
    }, [selected]);

    return (
        <div className={`flex-1 border border-lightblue100 rounded-xl relative ${props.className ? props.className : ""}`}>
           
                <div
                    className="p-2 flex justify-between items-center"
                    onClick={() => {
                        setShowSelect(!showSelect);
                    }}
                >
                    <div>{typeof selected === 'object' ? selected.name : selected}</div>
                    <RiArrowDownSLine className={`transition-transform text-lg cursor-pointer ${showSelect ? "rotate-180" : ""}`} />
                </div>
            

            <div
                ref={ddRef}
                className={`transition-all absolute overflow-hidden bg-white border border-lightblue100 rounded-xl top-10 left-0 flex flex-col z-20 w-full shadow-lg shadow-indigo-900/20 ${
                    !showSelect ? "h-0 opacity-0 p-0" : "p-2"
                }`}       
            >
                {data.map((item, i) => {
                    let thelabel;
                    let thevalue;
                    let isSelected;

                    switch (typeof item) {
                        case "object":
                            thelabel = item.name;
                            thevalue = item.id;
                            if (selected && selected.id === thevalue) {
                                isSelected = true;
                            }
                            break;
                        default:
                            thelabel = item;
                            thevalue = item;
                            if (selected === thevalue) {
                                isSelected = true;
                            }
                            break;
                    }

              

                    return (
                        <div
                            className={`transition-all relative flex flex-1 cursor-pointer rounded-lg p-1 ${
                                isSelected ? "bg-blue text-white" : "hover:bg-lightblue80"
                            }`}
                            key={i}
                            onClick={() => {
                                setSelected(item);
                                props.OnSet(item)
                            }}
                        >
                            {thelabel}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UiSelect;
