import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import MarketingLayout from "src/layouts/marketing/marketing-layout";
import Plans from "src/layouts/marketing/plans";
import Why from "src/layouts/marketing/why";
import How from "src/layouts/marketing/how";

import iconCalendar from "src/common/assets/images/marketing/icon-calendar.svg";
import iconBars from "src/common/assets/images/marketing/icon-bars.svg";
import iconTrust from "src/common/assets/images/marketing/icon-trust.svg";

import ada from "src/common/assets/images/marketing/ada-orig.mp4";
import jason from "src/common/assets/images/marketing/jason.mp4";
import julia from "src/common/assets/images/marketing/julia.mp4";
import paige from "src/common/assets/images/marketing/paige.mp4";
import olivia from "src/common/assets/images/marketing/olivia.mp4";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(useGSAP);

const Home = () => {
    const navigate = useNavigate();
    const main = useRef();

    const feats = [
        {
            icon: iconCalendar,
            title: "Book more meetings",
            desc: "Add personality to your outreach sequences with personalized videos",
        },
        {
            icon: iconBars,
            title: "Convert more sales",
            desc: "Add personality to your outreach sequences with personalized videos",
        },
        {
            icon: iconTrust,
            title: "Build more trust",
            desc: "Add personality to your outreach sequences with personalized videos",
        },
    ];

    useGSAP(
        () => {
            const tl = gsap.timeline({ defaults: { duration: 5 } });

            tl.from(".g-hero-title", {
                y: 200,
                opacity: 0,
                duration: 0.5,
            });

            gsap.from("#person-1", {
                x: "10",

                yPercent: -30,
                scale: 0.8,
                scrollTrigger: {
                    trigger: ".g-persons",
                    start: `20% 40%`,
                    end: "+=300",
                    scrub: 2,
                },
            });

            gsap.from("#person-2", {
                x: "10",
                y: "200",
                scale: 0.8,
                opacity: 0,
                scrollTrigger: {
                    trigger: ".g-persons",
                    start: `50% 60%`,
                    end: "+=300",
                    scrub: 2,
                },
            });

            gsap.from("#person-3", {
                x: "-200",
                y: "200",
                scale: 0.8,
                opacity: 0,
                scrollTrigger: {
                    trigger: ".g-persons",
                    start: `50% 60%`,
                    end: "+=300",
                    scrub: 2,
                },
            });

            gsap.from("#person-4", {
                x: "200",
                y: "100",
                scale: 0.9,
                opacity: 0,
                scrollTrigger: {
                    trigger: ".g-persons",
                    start: `50% 60%`,
                    end: "+=300",
                    scrub: 2,
                },
            });

            gsap.from("#person-5", {
                x: "100",
                y: "300",
                scale: 0.8,
                opacity: 0,
                scrollTrigger: {
                    trigger: ".g-persons",
                    start: `50% 60%`,
                    end: "+=300",
                    scrub: 2,
                },
            });

            const gcols = gsap.utils.toArray(".g-col");
            gcols.forEach((item, i) => {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        start: "50% 100%", // when the top of the trigger hits the top of the viewport
                        end: "+=400", // end after scrolling 500px beyond the start
                        scrub: 1,
                        // toggleActions: "play reverse play none",
                    },
                });
                tl.from(item, {
                    y: "-20",
                    opacity: 0,
                    duration: 0.5,
                    delay: i + 1,
                });
            });
        },
        { scope: main }
    );

    return (
        <MarketingLayout>
            <div className="fixed scale-75 translate-y-32 md:translate-y-0 md:scale-100 left-0 top-0 w-full min-h-screen flex items-center justify-center z-0 pt-32">
                <div
                    className="bg-blur absolute rounded-full z-0 opacity-60 left-1/2 -translate-x-1/2 top-2/3 "
                    style={{ background: "#6676FF", width: 1000, height: 300, filter: `blur(300px)` }}
                ></div>
            </div>
            <div className="sections flex flex-col gap-32 md:gap-52 relative z-10" ref={main}>
                {/* HERO */}
                <div className="section-hero section flex flex-col w-full relative min-h-screen items-start justify-center z-30">
                    <div
                        className="bg-blur-1 bg-blur-right absolute rounded-full z-0 opacity-20 left-2/3 -top-3/4"
                        style={{ background: "#6676FF", width: 1700, height: 1700, filter: `blur(300px)` }}
                    ></div>

                    <div
                        className="bg-blur-2 bg-blur-left absolute rounded-full z-0 opacity-10 right-2/3 -bottom-1/2"
                        style={{ background: "#6676FF", width: 1000, height: 1000, filter: `blur(200px)` }}
                    ></div>

                    <div className="container mx-auto flex-1 flex flex-col  justify-center pt-52 z-10">
                        <div className="g-hero-title flex-1 flex flex-col text-center h-full py-10 justify-center items-center gap-10">
                            <div className="sub-title bg-lightblue300/50 backdrop-blur rounded-full py-3 px-10 text-blue uppercase">
                                From idea to video in minutes
                            </div>
                            <div className="g-title text-7xl leading-tight md:text-9xl md:leading-tighter tracking-tighter font-semibold">
                                <div>Bespoke AI Videos.</div>
                            </div>
                            <div className="g-title g-subtitle text-lg md:text-xl px-20">
                                Increase response rates by up to 500% with Digitalu's dynamic video personalization.
                            </div>
                            <div className="flex-1 items-end mt-10">
                                <button
                                    className="button text-xl rounded-full px-12"
                                    onClick={() => {
                                        navigate("/admin/dashboard");
                                    }}
                                >
                                    Try For Free
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className=" g-persons flex-1 flex gap-2 md:gap-10 p-4 md:p-24 pt-32 md:pt-52 relative " style={{ minHeight: 300 }}>
                        <div className="w-1/3 flex flex-col gap-2 md:gap-10 justify-end">
                            <div id="person-2" className="aspect-square  max-w-full rounded-xl md:rounded-4xl relative overflow-hidden">
                                <video src={jason} playsInline autoPlay className="w-full h-full object-cover" loop muted />
                            </div>
                            <div className="flex flex-1 justify-end items-end">
                                <div id="person-3" className="aspect-square w-2/3   rounded-xl md:rounded-4xl relative overflow-hidden">
                                    <video src={julia} playsInline autoPlay className="w-full h-full object-cover" loop muted />
                                </div>
                            </div>
                        </div>
                        <div className="w-2/3 flex">
                            <div id="person-1" className="aspect-video max-w-full  rounded-2xl md:rounded-4xl relative overflow-hidden">
                                <video src={ada} playsInline autoPlay className="w-full h-full object-cover" loop muted />
                                {/* <img src={person1} className="w-full h-full object-cover" /> */}
                            </div>
                        </div>
                        <div className="w-1/3 flex flex-col gap-2 md:gap-10">
                            <div className="flex flex-1">
                                <div id="person-4" className="aspect-square w-2/3   rounded-xl md:rounded-4xl relative overflow-hidden">
                                    <video src={paige} playsInline autoPlay className="w-full h-full object-cover" loop muted />
                                </div>
                            </div>
                            <div id="person-5" className="aspect-square  max-w-full  rounded-xl md:rounded-4xl relative overflow-hidden">
                                <video src={olivia} playsInline autoPlay className="w-full h-full object-cover" loop muted />
                            </div>
                        </div>
                    </div>

                    <div className="g-cols container mx-auto flex flex-col md:flex-row items-center justify-between pt-16 px-5 md:px-32  gap-2 md:gap-52">
                        {feats.map((feat, f) => (
                            <div key={f} className="g-col flex flex-col gap-8 items-start p-10 md:p-0">
                                <div className="bg-lightblue300/50 backdrop-blur p-6 rounded-3xl mb-10">
                                    <img src={feat.icon} className="w-14 h-14 object-contain" />
                                </div>

                                <div className="text-3xl font-semibold leading-tight tracking-tight">{feat.title}</div>
                                <div className="text-lg opacity-50">{feat.desc}</div>
                            </div>
                        ))}
                    </div>
                    {/* 
                    <div className="g-cta-small flex bg-gray-900/25 rounded-wide p-20 mx-52 mt-52">
                        <div className="flex flex-1 gap-32 item-center justify-center">
                            <div className="text-6xl font-semibold leading-tight tracking-tighter text-white">
                                Start creating your personalized videos at scale.
                            </div>
                            <button
                                className="button button-blue text-2xl rounded-full px-20  py-6 font-semibold text-white"
                                onClick={() => {
                                    navigate("/admin/dashboard");
                                }}
                            >
                                Try For Free
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="sections flex flex-col gap-32 md:gap-52 relative z-10 pt-12 md:pt-52">
                <How />
                <Why />
                <Plans />
            </div>
        </MarketingLayout>
    );
};

export default Home;
