import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animateTitle, horizontalLoop } from "./animation";

//gsap.registerPlugin(ScrollTrigger);

export const animateFeatItems = () => {

    animateTitle({trigger: ".section-hero-features .g-title", scrub: false})

    // gsap.utils.toArray(".g-feat-item").forEach((item, i) => {
    //     const tl = gsap.timeline({
    //         scrollTrigger: {
    //             trigger: `.g-feat-wrapper`,
    //             start: `0% bottom`,
    //             end: "+=1000",
    //         },
    //     });
    //     tl.from(item, {
    //         opacity: 0,
    //         y: "-200px",
    //         delay: i / 2,
    //     });
    // });

    gsap.from(".g-feat-vidthumbs-1", {
        xPercent: -95,
        scrollTrigger: {
            trigger: `.g-feat-vidthumbs`,
            start: `top bottom`,
            end: "+=1000",
            scrub: 2,
        },
    });

    gsap.from(".g-feat-vidthumbs-2", {
        xPercent: 95,
        scrollTrigger: {
            trigger: `.g-feat-vidthumbs`,
            start: `top bottom`,
            end: "+=1000",
            scrub: 2,
        },
    });

    gsap.utils.toArray(".g-marquee").forEach((line, i) => {
        const links = line.querySelectorAll(".item"),
            tl = horizontalLoop(links, {
                repeat: -1,
                speed: 1 + i * 0.5,
                paddingRight: parseFloat(gsap.getProperty(links[0], "marginRight", "px")), // otherwise first element would be right up against the last when it loops. In this layout, the spacing is done with marginRight.
            });
        links.forEach((link) => {
            // link.addEventListener("mouseenter", () => tl.pause());
            // link.addEventListener("mouseleave", () => tl.resume());
        });
    });

    gsap.from(".g-bgs", {
        xPercent: -95,
        scrollTrigger: {
            trigger: `.g-bgs-wrapper`,
            start: `bottom bottom`,
            end: "+=1000",
            scrub: 2,
        },
    });


    

   
};
