import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import ada from "src/common/assets/images/marketing/ada-orig.mp4";

import { animateExampleItems } from "src/layouts/marketing/animation/examples";

import vidHouse1 from "src/common/assets/images/marketing/vid-house-1.mp4";
import vidProduct1 from "src/common/assets/images/marketing/vid-product-1.mp4";

import picHouse1 from "src/common/assets/images/marketing/house-1.jpg";
import picHouse2 from "src/common/assets/images/marketing/house-2.jpg";
import picHouse3 from "src/common/assets/images/marketing/house-3.jpg";

import Plans from "src/layouts/marketing/plans";
import MarketingLayout from "src/layouts/marketing/marketing-layout";

gsap.registerPlugin(useGSAP);

const Examples = () => {
    const main = useRef();

    useGSAP(
        () => {
            animateExampleItems();
        },
        { scope: main }
    );

    const examples = [
        {
            title: "Meeting requests",
            desc: `Authentically engage your audience to request a meeting`,
            content: (
                <>
                    <div className="absolute left-0 top-0 w-full h-full z-20" style={{ boxShadow: "inset 0 0 130px rgba(194, 199, 255, 0.4" }}></div>
                    <video src={vidHouse1} playsInline autoPlay className="w-full h-full object-cover relative z-10" loop muted />

                    <div className="absolute rounded-full w-32 h-32 md:w-64 md:h-64 bottom-14 right-14 overflow-hidden">
                        <video src={ada} playsInline autoPlay className="w-full h-full object-cover relative z-10" loop muted />
                    </div>
                </>
            ),
        },
        {
            title: "Sales outreach",
            desc: `Creatively showcase your value proposition`,
            content: (
                <>
                    <img src={picHouse1} className="w-full h-full object-cover" />
                    <div className="absolute rounded-full w-32 h-32 md:w-64 md:h-64 top-14 right-14 overflow-hidden">
                        <video src={ada} playsInline autoPlay className="w-full h-full object-cover relative z-10" loop muted />
                    </div>
                </>
            ),
        },
        {
            title: "Product demos",
            desc: `Personalized product demos with zero hassle`,
            content: (
                <>
                    <video src={vidProduct1} playsInline autoPlay className="w-full h-full object-cover relative z-10" loop muted />

                    <div className="absolute rounded-full w-32 h-32 md:w-64 md:h-64 top-14 left-14 overflow-hidden">
                        <video src={ada} playsInline autoPlay className="w-full h-full object-cover relative z-10" loop muted />
                    </div>
                </>
            ),
        },
        {
            title: "Follow-up emails",
            desc: `Build trust with personalized front-cam videos`,
            content: (
                <>
                    <img src={picHouse3} className="w-full h-full object-cover" />
                    <div className="absolute rounded-full w-32 h-32 md:w-64 md:h-64 bottom-14 right-14 overflow-hidden">
                        <video src={ada} playsInline autoPlay className="w-full h-full object-cover relative z-10" loop muted />
                    </div>
                </>
            ),
        },
    ];

    return (
        <MarketingLayout>
            <div className="fixed scale-75 translate-y-32 md:translate-y-0 md:scale-100 left-0 top-0 w-full min-h-screen flex items-center justify-center z-0 pt-32">
                <div
                    className="bg-blur absolute rounded-full z-0 opacity-60 left-1/2 -translate-x-1/2 top-2/3 "
                    style={{ background: "#6676FF", width: 1000, height: 300, filter: `blur(300px)` }}
                ></div>
            </div>
            <div className="sections flex flex-col gap-32 " ref={main}>
                {/* HERO */}

                <div className="section-hero-examples section flex w-full relative items-center pt-60 pb-24 rounded-b-wide justify-center z-30">
                    <div className="container flex-1 flex justify-center items-center">
                        <div className="flex flex-col items-center text-center gap-20">
                            <div className="sub-title bg-lightblue300/50 backdrop-blur rounded-full py-3 px-10 text-blue uppercase">
                                From idea to video in minutes
                            </div>
                            <div className="g-title text-6xl leading-tight md:text-8xl md:leading-tighter tracking-tighter font-semibold">
                                <div>Examples of our</div>
                                <div>video prospecting.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-examples flex-1 flex w-full relative md:min-h-screen pb-52 items-start justify-center rounded-wide z-30">
                    <div className="flex-1 flex justify-start items-start">
                        <div className="g-examples-wrapper container mx-auto gap-10 z-10 ">
                            {examples.map((example, e) => (
                                <div key={e} className="ex-panel flex min-h-600 min-w-screen max-h-600 px-4">
                                    <div
                                        className=" flex-1 flex p-5 md:p-10 bg-white/40 rounded-4xl min-w-screen"
                                        style={{ boxShadow: "0 0 40px 0 rgba(102, 118,255, 0.1)" }}
                                    >
                                        <div className="flex-1 flex flex-col md:flex-row justify-between md:gap-12">
                                            <div className="md:p-10 flex-1 flex flex-col md:min-h-96">
                                                <div className="flex items-start md:mb-10">
                                                    <div className="flex flex-0 bg-indigo-100 rounded-full px-8 py-2 text-2xl text-blue flex-0">
                                                        {`0${e + 1}`}
                                                    </div>
                                                </div>
                                                <div className="md:p-10 flex-1 flex flex-col gap-4 justify-center ">
                                                    <div className="flex flex-col gap-5">
                                                        <div className="text-4xl md:text-7xl font-semibold leading-tight tracking-tighter">{example.title}</div>
                                                        <div className="text-lg">{example.desc}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex-1 flex bg-lightblue80  rounded-3xl w-full  relative overflow-hidden border-1 border-1-lightblue100 items-center justify-center">
                                                {example.content}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Plans />
        </MarketingLayout>
    );
};

export default Examples;
