export const dateFormatter = (dateString, short) => {
    // Input date string
    if (dateString) {
        var t = dateString.split(/[- :]/);

        // Apply each element to the Date function
        var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5]);
        // Create a Date object from the input string
        const dateObj = new Date(d);

        // Define an array for months to convert the numeric month to a string
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Extract date components
        const year = dateObj.getFullYear();
        const month = months[dateObj.getMonth()];
        const day = dateObj.getDate();
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const seconds = dateObj.getSeconds();
        const timezone = dateObj.getTimezoneOffset();

        // Format the date and time
        const formattedDate = `${month} ${day}, ${year}`;
        const formattedTime = `${hours}:${minutes}:${seconds}`;
        const formattedTimezone = `GMT${timezone >= 0 ? "-" : "+"}${Math.abs(timezone / 60)
            .toString()
            .padStart(2, "0")}:${Math.abs(timezone % 60)
            .toString()
            .padStart(2, "0")}`;

        // Combine all the formatted components
        const formattedDateTime = `${formattedDate}, ${formattedTime}`;

        // Output the formatted date and time
        return formattedDateTime;
    }
};

export const isVideoMoreThanOneMinute = (file, callback) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
        const duration = video.duration;
        if (duration > 60) {
            callback(true);
        } else {
            callback(false);
        }
    };

    const reader = new FileReader();
    reader.onload = function () {
        video.src = URL.createObjectURL(file);
    };
    reader.readAsDataURL(file);
};

export const validateMinMax = (input) => {
    let value = parseInt(input.value);
    if (value < input.min) {
        input.value = input.min;
    } else if (value > input.max) {
        input.value = input.max;
    }
};

export const toEpoch = (thedate) => {
    if (thedate) {
        const dateStr = thedate;
        const cleanDateStr = dateStr.replace(" +0000 +0000", "+0000");
        const date = new Date(cleanDateStr);
        const epochTime = date.getTime();

        return epochTime;
    }
};

export const checkFileType = (url) => {
    // Check if the URL starts with https://
    if (!url.startsWith("https://")) {
        return "invalid URL (must use https)";
    }

    // Define the extensions for images and videos
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
    const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "webm", "mkv"];

    // Extract the file extension from the URL
    const extension = url.split(".").pop().toLowerCase();

    // Check if the extension matches image or video types
    if (imageExtensions.includes(extension)) {
        return "image";
    } else if (videoExtensions.includes(extension)) {
        return "video";
    } else {
        return "unknown file type";
    }
};


export const copyToClipboard = (text) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            console.log("Text copied to clipboard");
            return true;
        })
        .catch((err) => {
            console.error("Failed to copy text: ", err);
            return false;
        });
};