import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CardGen from "src/modules/admin/generated-videos/card-gen";
import OnBottom from "src/common/components/on-bottom/on-bottom";
import Preloader from "src/common/components/preloader/preloader";

import { getVideos } from "src/common/services/enduser/generate";

const ListGen = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [videos, setVideos] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [fetching, setFetching] = useState(null);

    useEffect(() => {
        handleVideos({ opr: "list", pageNum: 1 });
    }, []);

    const handleVideos = async ({ opr, pageNum }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                try {
                    const list_res = await getVideos({ pageNum });
                    console.log(`list_res ${pageNum}:: `, list_res);

                    if (list_res && pageNum > 1) {
                        setVideos([...videos, ...list_res]);
                    } else {
                        setVideos([...list_res]);
                    }

                    if (list_res.length > 0) {
                        setPageNum(pageNum + 1);
                    }
                } catch (error) {
                    console.log(error);
                }

                break;
            default:
                break;
        }
        setFetching(false);
    };
    return (
        <>
            <div className="min-h-full  h-full relative overflow-hidden slideFromTop">
                {fetching && pageNum === 1 && <Preloader />}

                <OnBottom
                    OnBottom={async (val) => {
                        //console.log("bottom");
                        await handleVideos({ opr: "list", pageNum });
                    }}
                >
                    {videos?.length > 0 && (
                        <div className="grid grid-cols-4 gap-6 py-6 ">
                            {videos.map((video, v) => (
                                <CardGen
                                    key={v}
                                    video={video}
                                    OnDelete={async () => {
                                        await handleVideos({ opr: "list", pageNum: 1 });
                                    }}
                                />
                            ))}
                        </div>
                    )}
                    {videos?.length <= 0 && fetching !== null && (
                        <div className="flex h-full flex-1 items-center justify-center">
                            <div className="flex flex-col gap-4 items-center">
                                {/* <div className="border-2 border-indigo-500/10 rounded-full w-28 h-28 flex items-center justify-center mb-6"><CiImageOff className="text-6xl text-indigo-500/20 " /></div> */}
                                You have not generate any videos yet.
                                <button className="button button-small px-4" onClick={()=>{
                                    navigate(`/admin/generate-video`, { state: { todo: "create" } });
                                }}>Generate</button>
                            </div>
                        </div>
                    )}
                </OnBottom>
            </div>
            {/* <div></div> */}
        </>
    );
};

export default ListGen;
