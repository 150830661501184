import { useNavigate } from "react-router-dom";
import Logo from "src/common/components/logo/logo";
import iconMenu from "src/common/assets/images/marketing/icon-menu.svg";
import { useEffect, useState } from "react";

const Header = (props) => {
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    
    useEffect(() => {
        if (window.innerWidth > 768) {
            setShowMenu(true);
        } else {
            setShowMenu(false);
        }
    }, []);

    const menu = [
        {
            label: "Real Estate",
            path: "/real-estate",
        },
        {
            label: "Features",
            path: "/features",
        },
        {
            label: "Examples",
            path: "/examples",
        },
        {
            label: "Pricing",
            path: "/pricing",
        },
        
        {
            label: "Login",
            path: "/login",
        },
    ];

    return (
        <div id="header" className="transition-transform header fixed w-full left-0 top-0 z-50 p-4 md:pt-5">
            <div className="transition-all container mx-auto relative flex flex-col md:flex-row items-start backdrop-blur-xl bg-white/40 rounded-3xl md:rounded-full p-4 md:px-8 md:pl-14 md:py-6  gap-6 md:gap-10">
                <div className="flex w-full md:w-auto items-center justify-between px-2 slideFromBot ">
                    <a
                        href="/"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate("/");
                        }}
                        className="flex"
                    >
                        <Logo className={`md:w-40`} />
                    </a>
                    <div className="flex items-center md:hidden justify-center opacity-80" onClick={()=>{
                        setShowMenu(!showMenu)
                    }}>
                        <img src={iconMenu} />
                    </div>
                </div>
                {showMenu && (
                    <div className="slideFromBot flex-1 flex flex-col md:flex-row gap-6 md:items-center">
                        <div className={`menu text-base flex-1 flex flex-col md:flex-row gap-6 md:gap-10 justify-center`}>
                            {menu.map((item, i) => (
                                <a
                                    key={i}
                                    href={`${item.path}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(`${item.path}`);
                                    }}
                                    className="text-black px-3"
                                >
                                    {item.label}
                                </a>
                            ))}
                        </div>
                        <button
                            className="button text-base rounded-full py-3 px-6 md:px-8"
                            onClick={() => {
                                navigate("/admin/dashboard");
                            }}
                        >
                            Try For Free
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Header;
