import { useState, useEffect } from "react";

import VideoUrl from "./tabs/video-url";
import VideoUpload from "./tabs/video-upload";

import ListVideos from "src/modules/admin/inputed-videos/list-videos";
import ListImages from "src/modules/admin/inputed-images/list-images";

const AddVideo = (props) => {
    const { genData, onUse } = props;

    const videoTabs = [
        {
            id: "your-videos",
            label: "Your Videos",
            desc: "Select from videos you have uploaded.",
        },
        {
            id: "your-images",
            label: "Your Images",
            desc: "Select from images you have uploaded.",
        },
        {
            id: "upload",
            label: "Upload",
            desc: "Upload a new video or image.",
        },
        {
            id: "url",
            label: "URL",
            desc: "Add a URL of a video or image.",
        },
    ];

    const [videoTab, setVideoTab] = useState(videoTabs[0].id);

    useEffect(() => {
        if (genData) {
            if (genData.videoURL || genData.imageURL) {
                setVideoTab("url");
            } else if (genData.videoId) {
                setVideoTab("your-videos");
            } else if (genData.imageId) {
                setVideoTab("your-images");
            }
        }
    }, []);

    useEffect(() => {
        props.OnSet({ ...genData });
    }, [videoTab]);

    return (
        <div className="flex flex-1 flex-col gap-8 py-10 w-full mx-auto">
            <div className="flex flex-col gap-1">
                <div className="text-xl font-semibold">2. Add Video / Image</div>
                <div className="opacity-50">Add a video or an image.</div>
            </div>

            {(!onUse || (onUse && onUse.type === "voice")) && (
                <>
                    <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2">
                        {videoTabs.map((tab, t) => (
                            <div
                                key={t}
                                className={`transition flex-1 cursor-pointer ${videoTab === tab.id ? "opacity-100 bg-white rounded-2xl py-4" : "opacity-50"}`}
                                onClick={() => {
                                    delete genData.videoId;
                                    delete genData.videoURL;
                                    delete genData.imageId;
                                    delete genData.imageURL;
                                    //props.OnSelectTab(tab.id);
                                    setVideoTab(tab.id);
                                }}
                            >
                                <div className="font-semibold">{tab.label}</div>
                                <div className="opacity-40">{tab.desc}</div>
                            </div>
                        ))}
                    </div>

                    {videoTab === "your-videos" && (
                        <div className="min-h-full">
                            <ListVideos
                                genData={genData}
                                for={"select"}
                                OnUse={(video) => {
                                    if (video.id) {
                                        genData.videoId = video.id;
                                    } else {
                                        delete genData.videoId;
                                    }
                                    delete genData.videoURL;
                                    delete genData.imageId;
                                    delete genData.imageURL;

                                    props.OnSet({ ...genData });
                                }}
                            />
                        </div>
                    )}

                    {videoTab === "your-images" && (
                        <div className="min-h-full">
                            <ListImages
                                genData={genData}
                                for={"select"}
                                OnUse={(image) => {
                                    if (image.id) {
                                        genData.imageId = image.id;
                                    } else {
                                        delete genData.imageId;
                                    }

                                    delete genData.videoId;
                                    delete genData.videoURL;
                                    delete genData.imageURL;

                                    props.OnSet({ ...genData });
                                }}
                            />
                        </div>
                    )}

                    {videoTab === "upload" && (
                        <VideoUpload
                            genData={genData}
                            OnSet={(data) => {
                                props.OnSet({ ...data });
                                //  setGenData({ ...data });
                            }}
                        />
                    )}

                    {videoTab === "url" && (
                        <VideoUrl
                            genData={genData}
                            OnSet={(data) => {
                                props.OnSet({ ...data });
                                //setGenData({ ...data });
                            }}
                        />
                    )}
                </>
            )}

            {/* /////// ONUSE */}

            {onUse && (onUse.type === "video" || onUse.type === "image") && (
                <div className="flex w-full min-h-32 border border-dashed border-blue rounded-2xl bg-lightblue80 items-center justify-center  relative overflow-hidden">
                    <div className="relative overflow-hidden w-24 h-24 rounded-2xl relative overflow-hidden">
                        {onUse.type === "video" && (
                            <video
                                src={onUse.url}
                                playsInline
                                muted
                                className="h-full w-full rounded-2xl relative overflow-hidden object-contain bg-black opacity-50 cursor-not-allowed"
                            />
                        )}

                        {onUse.type === "image" && (
                            <img
                                src={onUse.url}
                                className="h-full w-full rounded-2xl relative overflow-hidden object-contain bg-black opacity-50 cursor-not-allowed"
                            />
                        )}
                    </div>
                </div>
            )}

            {onUse && onUse.type === "regenerate" && (
                <div className="flex w-full min-h-32 border border-dashed border-blue rounded-2xl bg-lightblue80 items-center justify-center  relative overflow-hidden">
                    <div className="relative overflow-hidden w-24 h-24 rounded-2xl relative overflow-hidden">
                        {genData.type === "video" && (
                            <video
                                src={genData.videoURL}
                                playsInline
                                muted
                                className="h-full w-full rounded-2xl relative overflow-hidden object-contain bg-black opacity-50 cursor-not-allowed"
                            />
                        )}

                        {genData.type === "image" && (
                            <img
                                src={genData.imageURL}
                                className="h-full w-full rounded-2xl relative overflow-hidden object-contain bg-black opacity-50 cursor-not-allowed"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddVideo;
