import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const animateTitle = ({ trigger, target, scrub }) => {
    if (!target) {
        target = trigger;
    }

    if (scrub === false) {
        scrub = false;
    } else if (scrub === undefined) {
        scrub = 1;
    }
    const divs = gsap.utils.toArray(`${target} > div`);

    divs.forEach((div, d) => {
        gsap.from(div, {
            y: "-50px",
            opacity: 0,
            delay: scrub === false ? d / 7 : d,
            scrollTrigger: {
                trigger: div,
                start: "130% bottom", // when the top of the trigger hits the top of the viewport
                end: "+=100", // end after scrolling 500px beyond the start
                scrub: scrub,
            },
        });
    });
};

export const toggleHeader = ({ trigger, start, type }) => {
    if (!start) {
        start = "10%";
    }
    const header = document.querySelector("#header");
    const container = document.querySelector("#header > .container");
    const logo = document.querySelector("#header .logo");
    const button = document.querySelector("#header .button");
    const menuLinks = document.querySelectorAll("#header a");
    const light = () => {
        if (type === "full") {
            container.classList.add("bg-white/90");
        } else {
            logo.classList.add("logo-white");
            button.classList.add("button-white");
            menuLinks.forEach((link) => {
                link.classList.add("text-white");
            });
        }
    };
    const dark = () => {
        if (type === "full") {
            container.classList.remove("bg-white/90");
        } else {
            logo.classList.remove("logo-white");
            button.classList.remove("button-white");
            menuLinks.forEach((link) => {
                link.classList.remove("text-white");
            });
        }
    };
    gsap.to(header, {
        scrollTrigger: {
            trigger: trigger,
            start: `top ${start}`,
            end: "100% top",
            // end: "+=100",
            scrub: 0,
            onEnter: () => light(),
            onLeave: () => dark(),
            onEnterBack: () => light(),
            onLeaveBack: () => dark(),
        },
    });
};

export const animateSections = () => {};

export const animateFeats = () => {
    animateTitle({ trigger: ".section-hero .g-title", scrub: false });

    toggleHeader({ trigger: ".section-feats" });
    animateTitle({ trigger: ".section-feats .g-title" });

    // gsap.to(".g-hero-feats", {
    //     backgroundColor:'#0f172a',
    //     scrollTrigger: {
    //         trigger: ".section-feats",
    //         start: `0% bottom`,
    //         end: "+=0",
    //         scrub: 1,
    //     },
    // });

    gsap.from(".section-feats", {
        // y: "200px",
        scale: 0.9,
        scrollTrigger: {
            trigger: ".section-hero",
            start: `bottom bottom`,
            end: "+=100",
            scrub: 1,
        },
    });

    gsap.to(".section-feats", {
        // y: "200px",
        borderRadius: 0,
        scrollTrigger: {
            trigger: ".section-feats",
            start: `-10% top`,
            end: "+=100",
            scrub: 0,
        },
    });
    gsap.to(".section-feats", {
        // y: "200px",
        borderRadius: "120px",
        scrollTrigger: {
            trigger: ".section-feats",
            start: `90% bottom`,
            end: "+=100",
            scrub: 0,
        },
    });

    gsap.from(".section-feats", {
        paddingLeft: "100px",
        paddingRight: "100px",
        scrollTrigger: {
            trigger: ".section-hero",
            start: `80% top`,
            end: "+=300",
            scrub: 1,
        },
    });

    gsap.from("#person-1", {
        y: () => {
            const parent = document.querySelector(".section-feats");
            const child = document.querySelector("#person-1");
            const relativeTop = parent.clientHeight - child.offsetTop;

            // return `-${child.offsetTop}`;

            return `-${relativeTop - child.clientHeight / 2}`;
        },

        scrollTrigger: {
            trigger: ".section-hero",
            start: `0 top`,
            end: "bottom top",
            scrub: 1,
            // markers:true
        },
    });

    gsap.from("#person-2", {
        x: "10",
        y: "200",
        scale: 0.9,
        scrollTrigger: {
            trigger: ".section-feats",
            start: `top 50%`,
            end: "50% 50%",
            scrub: 2,
            // markers:true
        },
    });

    gsap.from("#person-3", {
        x: "-200",
        y: "200",
        scale: 0.9,
        scrollTrigger: {
            trigger: ".section-feats",
            start: `top 50%`,
            end: "50% 50%",
            scrub: 3,
            // markers:true
        },
    });

    gsap.from("#person-4", {
        x: "200",
        y: "100",
        scale: 0.9,
        scrollTrigger: {
            trigger: ".section-feats",
            start: `top 50%`,
            end: "50% 50%",
            scrub: 4,
            // markers:true
        },
    });

    gsap.from("#person-5", {
        x: "100",
        y: "300",
        scale: 0.9,
        scrollTrigger: {
            trigger: ".section-feats",
            start: `top 50%`,
            end: "50% 50%",
            scrub: 5,
            // markers:true
        },
    });

    const gcols = gsap.utils.toArray(".section-feats .g-col");
    gcols.forEach((item, i) => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-feats",
                start: "30% top", // when the top of the trigger hits the top of the viewport
                end: "+=400", // end after scrolling 500px beyond the start
                scrub: 1,
                // toggleActions: "play reverse play none",
            },
        });
        tl.from(item, {
            y: "-20",
            opacity: 0,
            duration: 0.5,
            delay: i,
        });
    });

    gsap.to(".g-persons", {
        y: "-300px",
        scrollTrigger: {
            trigger: ".g-persons",
            start: `60% top`,
            end: "+=500",
            scrub: 1,
        },
    });

    gsap.from(".section-feats .g-cta-small", {
        y: "200px",
        opacity: 0,
        scrollTrigger: {
            trigger: ".section-feats .g-cta-small",
            start: `0% bottom`,
            end: "+=100",
            scrub: 1,
        },
    });
};

// OLD STEPS
// export const animateSteps = () => {
//     const tl = gsap.timeline({
//         scrollTrigger: {
//             trigger: ".section-steps",
//             start: `90% bottom`,
//             end: "+=1000",
//             scrub: 0,
//             pinSpacing: false,
//         },
//     });
//     tl.from(".section-steps", {
//         y: "-300px",
//     });
//     tl.add(animateTitle(".section-steps .g-title"));

//     // gsap.from(".section-steps", {
//     //     y: "-300px",

//     // });

//     let sections = gsap.utils.toArray(".g-step-panel");
//     let container = document.querySelector(".g-steps-container");
//     gsap.to(sections, {
//         xPercent: -100 * (sections.length - 1),
//         ease: "none",
//         scrollTrigger: {
//             trigger: container,
//             pin: true,
//             scrub: 1,
//             start: "top top",
//             // snap: 1 / (sections.length - 1),
//             // base vertical scrolling on how wide the container is so it feels more natural.
//             end: () => "+=" + container.offsetWidth,
//         },
//     });
// };

export const animateSteps = () => {
    const container = document.querySelector(".g-steps-container");
    ScrollTrigger.create({
        trigger: ".g-panel-graphics",
        start: "top top",
        end: `+=${container.offsetHeight}`,
        pin: true,
        pinSpacing: false,
    });

    const wHeight = window.innerHeight;
    console.log(wHeight);

    gsap.set(".g-panel-graphic-1", { scale: 1, y: 0 });
    gsap.set(".g-panel-graphic-1 .g-video", { scale:1.6, y: 500 });

    gsap.set(".g-panel-graphic-2", { scale: 1, y: wHeight });
    gsap.set(".g-panel-graphic-3", { scale: 1, y: wHeight });

    //////////// 1

    const step1_t1 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-1",
            start: `100% bottom`,
            end: `+=100px`,
            scrub: 1,
        },
    });
    step1_t1.to(".g-panel-graphic-1 .g-video", {
        y: 0,
        scale:1
    });

    const step1_t2 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-2",
            start: `50% bottom`,
            end: `+=100px`,
            scrub: 1,
        },
    });
    step1_t2.to(".g-panel-graphic-1", {
        scale: 0.8,
        y: -90
    });
    
    const step1_t3 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-3",
            start: `40% bottom`,
            end: `+=100px`,
            scrub: 1,
        },
    });
    step1_t3.to(".g-panel-graphic-1", {
        scale: 0.65,
        y: -160  
    });

    /////////// 2
    const step2_t1 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-2",
            start: `40% bottom`,
            end: `+=200px`,
            scrub: 1,
        },
    });
    step2_t1.to(".g-panel-graphic-2", {
        y: 0,
    });

    const step2_t2 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-2",
            start: `50% bottom`,
            end: `+=300px`,
            scrub: 1,
        },
    });
    const step2_texts = gsap.utils.toArray(".g-panel-graphic-2 .g-text > div");
    step2_texts.forEach((div,d)=>{
        step2_t2.from(div, {    
            y:-20,
            opacity:0,      
        });
    })

    const step2_t3 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-2",
            start: `90% bottom`,
            end: `+=1000px`,
            scrub: 1,
            toggleClass:{
                targets:".g-panel-graphic-2 .g-text > div > span",
                className: "g-tag"
            }
        },
    });
    // const step2_spans = gsap.utils.toArray(".g-panel-graphic-2 .g-text > div > span");
    // step2_spans.forEach((span,s)=>{
    //     step2_t3.from(div, {    
    //         y:-20,
    //         opacity:0,      
    //     });
    // })
    

    const step2_t4 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-3",
            start: `50% bottom`,
            end: `+=100px`,
            scrub: 1,
        },
    });
    step2_t4.to(".g-panel-graphic-2", {
        scale: 0.8,
        y: -90,
    });


    
    /////////// 3
    const step3_t1 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-3",
            start: `40% bottom`,
            end: `+=200px`,
            scrub: 1,
        },
    });
    step3_t1.to(".g-panel-graphic-3", {
        y: 0,
        scrollTrigger: {
            trigger: ".g-panel-title-3",
            start: `40% bottom`,
            end: `+=50%`,
            scrub: 1,
        },
    });

    const step3_t2 = gsap.timeline({
        scrollTrigger: {
            trigger: ".g-panel-title-3",
            start: `80% bottom`,
            end: `+=100px`,
            scrub: 1,
        },
    });
    const step3_videos = gsap.utils.toArray(".g-panel-graphic-3 .g-video");
    step3_videos.forEach((vid,v)=>{
        step3_t2.from(vid, {
            
            scale: 0.8,
            opacity:0,      
        });
    })
   
};

export const animateCTA = () => {
    toggleHeader({ trigger: ".wrapper-cta-why", type: "full" });
    animateTitle({ trigger: ".section-cta .g-title-alt" });

    gsap.from(".section-cta", {
        y: "200px",
        scale: 0.94,
        scrollTrigger: {
            trigger: ".section-cta",
            start: `0% bottom`,
            end: "+=100",
            scrub: 1,
        },
    });

    gsap.to(".section-cta .g-vid", {
        y: "200px",
        scale: 0.94,
        scrollTrigger: {
            trigger: ".section-cta .g-vid",
            start: `top bottom`,
            end: "+=1000",
            scrub: 2,
        },
    });

    // gsap.from(".g-logo-bg", {
    //     x: "-400px",
    //     scrollTrigger: {
    //         trigger: ".g-logo-bg",
    //         start: `top bottom`,
    //         end: "+=1000",
    //         scrub: 3,
    //     },
    // });

    gsap.from(".section-testi", {
        y: "100px",
        opacity: 0,
        scrollTrigger: {
            trigger: ".section-testi",
            start: `top bottom`,
            end: "+=200",
            scrub: 1,
        },
    });

    animateTitle({ trigger: ".section-testi .g-title" });

    const testis = gsap.utils.toArray(".g-testi");
    testis.forEach((item, i) => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".g-testis",
                start: "-20% bottom",
                end: "+=200",
                scrub: 1,
            },
        });
        tl.from(item, {
            x: "50px",
            opacity: 0,
            delay: i,
        });
    });
};

export const animateWhy = () => {
    // animateTitle(".section-why .g-title-why");

    ScrollTrigger.create({
        // animation:tl,
        trigger: ".section-why .g-title",
        start: `top 40%`,
        end: "110% bottom",
        scrub: 1,
        pin: true,
        pinSpacing: false,
        //  markers:true
    });

    gsap.from(".g-why-1-graphic", {
        x: "-1200px",
        scrollTrigger: {
            trigger: ".g-why-1",
            start: `30% bottom`,
            end: "+=500",
            scrub: 3,
        },
    });

    const items = gsap.utils.toArray(".g-why-2-mail");
    items.forEach((item, i) => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".g-why-2",
                start: `20% bottom`,
                end: "+=300",
                scrub: 2,
                // toggleActions: "play reverse play none",
            },
        });

        tl.from(item, {
            y: "-100px",
            opacity: 0,
            delay: i + 1,
        });
    });

    gsap.from(".g-why-3-calendar", {
        scale: 1.5,
        scrollTrigger: {
            trigger: ".g-why-3",
            start: `30% bottom`,
            end: "+=300",
            scrub: 1,
        },
    });
    gsap.from(".g-why-3-button", {
        y: "300px",
        opacity: 0,
        scrollTrigger: {
            trigger: ".g-why-3",
            start: `80% bottom`,
            end: "+=200",
            scrub: 1,
        },
    });
};

export const animateIntegrations = () => {
    animateTitle({ trigger: ".section-integrations .g-title-alt" });

    gsap.from(".section-integrations", {
        y: "-300px",
        scrollTrigger: {
            trigger: ".section-integrations",
            start: `50% bottom`,
            end: "+=1000",
            scrub: 0,
        },
    });

    gsap.from(".g-marquee-container", {
        y: "-100px",
        opacity: 0,
        scale: 1.5,
        scrollTrigger: {
            trigger: ".section-integrations",
            start: `90% bottom`,
            end: "+=300",
            scrub: 1,
        },
    });

    gsap.utils.toArray(".g-marquee").forEach((line, i) => {
        const links = line.querySelectorAll(".item"),
            tl = horizontalLoop(links, {
                repeat: -1,
                speed: 1 + i * 0.5,
                paddingRight: parseFloat(gsap.getProperty(links[0], "marginRight", "px")), // otherwise first element would be right up against the last when it loops. In this layout, the spacing is done with marginRight.
            });
        links.forEach((link) => {
            // link.addEventListener("mouseenter", () => tl.pause());
            // link.addEventListener("mouseleave", () => tl.resume());
        });
    });
};

export const animateFaq = () => {
    // ScrollTrigger.create({
    //     // animation:tl,
    //     trigger: ".section-faq .g-title",
    //     start: `top 40%`,
    //     end: "110% bottom",
    //     scrub: 3,
    //     pin: true,
    //     //  markers:true
    // });

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".section-faq",
            start: `50% bottom`,
            end: "+=1000",
            scrub: 0,
        },
    });

    tl.from(".section-faq", {
        y: "-300px",
    });

    // tl.from(".section-faq", {
    //     opacity:0,
    // });

    // gsap.from(".section-faq", {
    //     opacity:0,
    //     scrollTrigger: {
    //         trigger: ".section-faq",
    //         start: `10% bottom`,
    //         end: "+=300",
    //         scrub: 0,
    //     },
    // });

    // gsap.from(".section-faq", {
    //     y: "-300px",
    //     scrollTrigger: {
    //         trigger: ".section-faq",
    //         start: `50% bottom`,
    //         end: "+=1000",
    //         scrub: 0,
    //     },
    // });
};

export const animateCTA2 = () => {
    toggleHeader({ trigger: ".section-cta2", start: "40%", type: "full" });
    animateTitle({ trigger: ".section-cta2 .g-title" });

    gsap.from(".section-cta2", {
        y: "200px",
        scale: 0.94,
        scrollTrigger: {
            trigger: ".section-cta2",
            start: `0% bottom`,
            end: "+=100",
            scrub: 1,
        },
    });

    gsap.from(".g-vids-1", {
        xPercent: -90,
        scrollTrigger: {
            trigger: ".g-vids-1",
            start: `-10% bottom`,
            end: "+=1000",
            scrub: 3,
        },
    });

    gsap.from(".g-vids-2", {
        xPercent: 90,
        scrollTrigger: {
            trigger: ".g-vids-2",
            start: `-10% bottom`,
            end: "+=1000",
            scrub: 3,
        },
    });
};

export const animateFooter = () => {
    gsap.to("#header", {
        y: "-300px",
        scrollTrigger: {
            trigger: ".section-footer",
            start: `0% bottom`,
            end: "+=500",
            scrub: 0,
        },
    });

    gsap.from(".section-footer", {
        y: "-300px",
        scrollTrigger: {
            trigger: ".section-footer",
            start: `50% bottom`,
            end: "+=500",
            scrub: 0,
        },
    });
};

export const animateLogin = () => {
    animateTitle({ trigger: ".section-login .g-title", scrub: false });
    gsap.from(".section-login", {
        y: "100px",
        opacity: 0,
    });
};

//HELPERS-----------------

export const horizontalLoop = (items, config) => {
    items = gsap.utils.toArray(items);
    config = config || {};
    let tl = gsap.timeline({ repeat: config.repeat, paused: config.paused, defaults: { ease: "none" } }),
        length = items.length,
        startX = items[0].offsetLeft,
        times = [],
        widths = [],
        xPercents = [],
        curIndex = 0,
        pixelsPerSecond = (config.speed || 1) * 100,
        snap = config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
        totalWidth,
        curX,
        distanceToStart,
        distanceToLoop,
        item,
        i;
    gsap.set(items, {
        // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
        xPercent: (i, el) => {
            let w = (widths[i] = parseFloat(gsap.getProperty(el, "width", "px")));
            xPercents[i] = snap((parseFloat(gsap.getProperty(el, "x", "px")) / w) * 100 + gsap.getProperty(el, "xPercent"));
            return xPercents[i];
        },
    });
    gsap.set(items, { x: 0 });
    totalWidth =
        items[length - 1].offsetLeft +
        (xPercents[length - 1] / 100) * widths[length - 1] -
        startX +
        items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") +
        (parseFloat(config.paddingRight) || 0);
    for (i = 0; i < length; i++) {
        item = items[i];
        curX = (xPercents[i] / 100) * widths[i];
        distanceToStart = item.offsetLeft + curX - startX;
        distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
        tl.to(item, { xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100), duration: distanceToLoop / pixelsPerSecond }, 0)
            .fromTo(
                item,
                { xPercent: snap(((curX - distanceToLoop + totalWidth) / widths[i]) * 100) },
                { xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false },
                distanceToLoop / pixelsPerSecond
            )
            .add("label" + i, distanceToStart / pixelsPerSecond);
        times[i] = distanceToStart / pixelsPerSecond;
    }
    function toIndex(index, vars) {
        vars = vars || {};
        Math.abs(index - curIndex) > length / 2 && (index += index > curIndex ? -length : length); // always go in the shortest direction
        let newIndex = gsap.utils.wrap(0, length, index),
            time = times[newIndex];
        if (time > tl.time() !== index > curIndex) {
            // if we're wrapping the timeline's playhead, make the proper adjustments
            vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
            time += tl.duration() * (index > curIndex ? 1 : -1);
        }
        curIndex = newIndex;
        vars.overwrite = true;
        return tl.tweenTo(time, vars);
    }
    tl.next = (vars) => toIndex(curIndex + 1, vars);
    tl.previous = (vars) => toIndex(curIndex - 1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.times = times;
    return tl;
};
