import { useEffect, useState } from "react";

const Knob = (props) => {
    const [value, setValue] = useState(props.value);
    useEffect(()=>{
        props.OnChange(value)
    },[value])

    return (
        <div
            className={`transition-all knob-container cursor-pointer flex min-w-10 max-w-10 rounded-full p-1 ${
                value === true ? "flex-row-reverse bg-blue" : "flex-row bg-lightblue100 "
            }`}
            onClick={() => {
                setValue(!value);
            }}
        >
            <div className="knob bg-white w-4 h-4 rounded-full"></div>
        </div>
    );
};

export default Knob;
