import { logoutUser } from "src/common/services/user";

export const getStoredUser = () => {
    let storedUser = localStorage.getItem("DIGITALU_user");
    let currentUser = storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null;

    let storedAuth = localStorage.getItem("DIGITALU_auth");
    let currentAuth = storedAuth && storedAuth !== "undefined" ? storedAuth : null;

    if (currentUser && currentUser.id && currentAuth) {
        currentUser.auth = currentAuth;
    }
    return currentUser;
};

export const logout = () => {
    localStorage.removeItem("DIGITALU_user");
    //await logoutUser()
};
