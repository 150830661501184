import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { asynctts, listVoices } from "src/common/services/tester";
import { upload } from "src/common/services/utils";

import arrowLeft from "src/pages/tester/images/arrow-left.svg";
import arrowRight from "src/pages/tester/images/arrow-right.svg";
import iconClear from "src/pages/tester/images/icon-clear.svg";
import iconSpeaker from "src/pages/tester/images/icon-speaker.svg";

const Audio = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [voices, setVoices] = useState([]);

    const [voice, setVoice] = useState(null);
    const [text, setText] = useState(null);

    const [audioFile, setAudioFile] = useState(null);

    const [fetching, setFetching] = useState(false);

    useEffect(() => {
        console.log('2::' , location)
        if (location.state && location.state.character) {
        } else {
            navigate("/tester/character");
        }
    }, [location]);

    useEffect(() => {
        getVoices();
    }, []);

    const getVoices = async () => {
        try {
            let select_res = await listVoices();
            setVoices([...select_res]);
            if (select_res && select_res[0]?.elevenlabs) {
                setVoice(select_res[0]?.elevenlabs);
            }

            //console.log(select_res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAudio = async ({ opr, file }) => {
        switch (opr) {
            case "create":
                setFetching(true);
                if (voice && text && text !== "") {
                    try {
                        const create_res = await asynctts(voice, text);
                        console.log(create_res);
                        if (create_res && create_res.audio) {
                            setAudioFile(create_res.audio);
                            setFetching(false);
                            // navigate("/tester/video", {
                            //     state: {
                            //         audio: create_res.audio,
                            //         image: location.state?.character?.image,
                            //         video: location.state?.character?.video,
                            //     },
                            // });
                        } else {
                            setFetching(false);
                        }
                    } catch (error) {
                        console.log(error);
                        setFetching(false);
                    }
                }
                break;
            case "upload":
                setFetching(true);

                if (file) {
                    try {
                        const upload_res = await upload({ file: file, useAdminAuth: true });
                        console.log(upload_res);
                        if (upload_res) {
                            setAudioFile(upload_res.data[0].cache);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                setFetching(false);
                break;
            default:
                break;
        }
    };

    return (
        <div className="bg-zinc-950 min-h-full w-full text-white flex  text-base">
            {fetching && (
                <div className="pre-loader bg-zinc-950/50 absolute w-full h-full top-0 left-0 z-40 flex items-center justify-center backdrop-blur-sm">
                    <div className="flex flex-row gap-1 items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Please wait...
                    </div>
                </div>
            )}
            <div className="container container-sm flex flex-col flex-1 mx-auto p-10 max-w-3xl slideFromLeft">
                {/* HEADER */}
                <div className="header flex flex-row py-5 items-center">
                    <div
                        className="w-32 opacity-30 cursor-pointer hover:opacity-80 flex flex-row gap-5"
                        onClick={() => {
                            navigate("/tester/character", {state: { character : location?.state?.character}});
                        }}
                    >
                        <img src={arrowLeft} />
                        Previous
                    </div>
                    <div className="flex-1 text-center text-xl">Audio</div>
                    <div className="w-32 opacity-30 cursor-pointer hover:opacity-80 flex flex-row gap-5 justify-end">
                        {/* Next
                        <img src={arrowRight} /> */}
                    </div>
                </div>

                {/* BODY */}
                <div className="body flex-1 flex flex-col flex-1">
                    <div className="flex-1 flex flex-col gap-10 py-20 ">
                        <div className="flex-1 flex flex-col items-center justify-center gap-10">
                            <div className="relative">
                                {audioFile !== null && (
                                    <img
                                        src={iconClear}
                                        className="absolute right-0 top-10 z-10 cursor-pointer hover:opacity-50"
                                        onClick={() => {
                                            setAudioFile(null);
                                        }}
                                    />
                                )}
                                <label className="relative">
                                    <div className="transition-all relative overflow-hidden min-w-72 min-h-72 rounded-full border border-zinc-100/20 border-dashed flex items-center justify-center cursor-pointer hover:border-zinc-100/50">
                                    {audioFile && (
                                            <video
                                                playsInline
                                                autoPlay
                                                controls
                                                src={audioFile}
                                                className="absolute left-0 top-24 w-full h-20 object-cover"
                                            />
                                        )}
                                        
                                        {audioFile === null && <div className="text-6xl font-thin opacity-40">+</div>}
                                    </div>

                                    <input
                                        type="file"
                                        accept={``}
                                        className="absolute left-0 top-0 opacity-0"
                                        onChange={async (e) => {
                                            // console.log(e.target.files[0]);
                                            // setAudioFile(e.target.files[0]);
                                            await handleAudio({ opr: "upload", file: e.target.files[0] });
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="opacity-30 text-sm">Click to upload audio or video </div>
                        </div>
                        <div className="flex flex-col relative border border-zinc-100/10 rounded-4xl w-full h-full overflow-hidden">
                            <textarea
                                className="flex flex-1 w-full p-12"
                                rows={5}
                                placeholder={`You can write the beginning of a dialogue or you can try typing a single word`}
                                onChange={(e) => {
                                    setText(e.target.value);
                                }}
                            />

                            <div className="flex w-full items-end justify-between p-6">
                                <div className="flex flex-row gap-10  px-6 py-4 border border-zinc-100/10 rounded-3xl">
                                    <div className="flex text-nowrap opacity-40">Selet a voice : </div>
                                    <select
                                        onChange={(e) => {
                                            setVoice(e.target.value);
                                        }}
                                    >
                                        {voices.map((voice, v) => (
                                            <option key={v} value={voice.elevenlabs}>
                                                {voice.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div
                                    className={`btn  rounded-3xl py-3 px-8 border-2 border-orange-500 ${
                                        text === "" ? "opacity-40 cursor-not-allowed" : "opacity-100 hover:translate-y-1 cursor-pointer"
                                    }`}
                                    onClick={async () => {
                                        if (text !== "") {
                                            await handleAudio({ opr: "create" });
                                            // navigate("/tester/video", { state: { text: audio.text, character: location.state?.character?.file } });
                                        }
                                    }}
                                >
                                    Create
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* FOOTER */}
                <div className="footer flex flex-row py-5">
                    {audioFile && audioFile.indexOf("https://") !== -1 && (
                        <button
                            className={`transition-all flex-1 btn rounded-3xl py-6 px-20 text-white text-center bg-gradient-to-r from-red-500 to-amber-500 ${
                                audioFile && audioFile.indexOf("https://") !== -1
                                    ? "opacity-100 hover:translate-y-1 cursor-pointer"
                                    : "opacity-40 cursor-not-allowed"
                            }`}
                            onClick={() => {
                                if (audioFile && audioFile.indexOf("https://") !== -1) {
                                    navigate("/tester/video", {
                                        state: {
                                            audio: audioFile,
                                            image: location.state?.character?.image,
                                            video: location.state?.character?.video,
                                        },
                                    });
                                }
                            }}
                        >
                            Next
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Audio;
