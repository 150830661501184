import logo from "src/common/assets/images/logo.svg";
import logoWhite from "src/common/assets/images/logo-white.svg";

const Logo = (props) => {
    return (
        <div
            className={`w-28 min-h-12 md:w-32 bg-no-repeat bg-contain  ${props.className ? props.className : ""} ${
                props.type === "white" ? "logo-white" : "logo"
            } `}
        ></div>
        // <img src={props.type==='white'? logoWhite : logo} className={`w-28 md:w-32 ${props.className? props.className : ''}`} />
    );
};

export default Logo;
