import { useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

const UiDSection = (props) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {}, []);

    return (
        <div className="d-section p-6  border-b border-lightblue100 flex flex-col">
            <div className="d-title flex-1 flex flex-row items-center justify-between">
                {props.title && <div className="uppercase text-2xs opacity-40 font-semibold">{props.title}</div>}
                <RiArrowDownSLine
                    className={`transition-transform text-lg cursor-pointer opacity-40 hover:opacity-100 ${(open)?'rotate-180':''}`}
                    onClick={() => {
                        setOpen(!open);
                    }}
                />
            </div>
            <div className={`transition-all relative flex flex-col gap-4 ${(open)? 'mt-5' : 'h-0 opacity-0' }`}>
                {props.children}
            </div>
        </div>
    );
};

export default UiDSection;
