import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleResponse, handleRes } from "src/common/services/response";


// QUERIES

export const getVoices = async () => {
    if (getStoredUser() && getStoredUser().id) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.get(`${endpoint.rest}voice/all`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

// MUTATIONS
export const createVoice = async ({ voiceName, voiceDescription, fileURL }) => {
    if (getStoredUser() && getStoredUser().id && voiceName && voiceDescription && fileURL) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        let data = {
            auth: getStoredUser().auth,
            voiceName,
            voiceDescription,
            fileURL,
        };

        try {
            const res = await axios.post(`${endpoint.rest}voice`, data, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};



export const deleteVoice = async ({ voiceId }) => {
    if (getStoredUser() && getStoredUser().id) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.delete(`${endpoint.rest}voice/${voiceId}`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};


export const textToSpeech = async ({ voiceId, text }) => {
    if (getStoredUser() && getStoredUser().id && voiceId && text) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        let data = {
            auth: getStoredUser().auth,
            voiceId,
            text,
        };

        try {
            const res = await axios.post(`${endpoint.rest}speech`, data, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};
