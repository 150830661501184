import { useNavigate } from "react-router-dom";

import gBulkVids from "src/common/assets/images/marketing/g-bulk-vids.png";
import gSched from "src/common/assets/images/marketing/g-sched.svg";
import gEmails from "src/common/assets/images/marketing/g-emails.svg";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
gsap.registerPlugin(useGSAP);

const Why = (props) => {
    const main = useRef();
    const navigate = useNavigate();

    useGSAP(
        () => {
            const whyItems = gsap.utils.toArray(".section-why .why-item");
            whyItems.forEach((item, i) => {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        start: "top 80%",
                        end: "+=100",
                        scrub: 1,
                    },
                });
                tl.from(item, {
                    // x: "-200px",
                    opacity: 0,
                    scale: 1.3,
                    delay: i,
                });
            });
        },
        { scope: main }
    );

    return (
  
        <div ref={main} className="section-why section flex relative w-full min-h-screen p-4">
            <div
                className="bg-blur absolute rounded-full z-0 opacity-50 left-1/2 -translate-x-1/2 top-2/3 -translate-y-1/2"
                style={{ background: "#6676FF", width: 800, height: 600, filter: `blur(300px)` }}
            ></div>

            <div className="container mx-auto relative flex-1 flex flex-col  min-h-screen z-20  gap-20">
                <div className="section-title flex flex-col items-center text-cente gap-10 py-10">
                    <div className="sub-title bg-white/50 backdrop-blur rounded-full py-3 px-10 text-blue uppercase">From idea to video in minutes</div>
                    <div className="g-title text-4xl leading-tighter md:text-7xl md:leading-tighter tracking-tighter font-semibold">Why choose Digitalu</div>
                </div>

                <div className="flex-1 flex flex-col gap-10">
                    <div className="flex-1 flex flex-col md:flex-row gap-10">
                        <div className="why-item why-item-1 flex flex-col md:w-2/3 bg-white/50 rounded-3xl p-24 px-0 pb-10 backdrop-blur gap-32 ">
                            <div className="flex flex-col items-center text-center gap-6 px-10">
                                <div className="text-3xl font-semibold leading-tight tracking-tight">Auto-generate personalized videos in bulk</div>
                                <div className="text-lg opacity-50 leading-snug tracking-snug">
                                    Video prospecting tool to personalize with your own face and voice.
                                </div>
                            </div>
                            <div className="flex flex-1 items-center justify-center">
                                <img src={gBulkVids} />
                            </div>
                        </div>

                        <div className="why-item why-item-2 flex flex-col md:w-1/3 bg-white/50 rounded-3xl px-10 backdrop-blur ">
                            <div className="flex flex-1 items-start justify-start">
                                <img src={gSched} className="w-full h-full object-contain object-top" />
                            </div>

                            <div className="flex flex-col items-center text-center gap-6 p-10 pt-0 pb-24">
                                <div className="text-3xl font-semibold leading-tight tracking-tight">Improve demo scheduling rate by 80%</div>
                                <div className="text-lg opacity-50 leading-snug tracking-snug">
                                    Video prospecting tool to personalize with your own face and voice.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="why-item why-item-3 flex flex-col md:flex-row bg-white/50 rounded-3xl backdrop-blur gap-10 md:gap-52 ">
                        <div className="flex md:w-2/4 flex-col gap-6 px-10 pt-20 md:p-20 md:ml-10 items-center justify-center text-center md:text-left">
                            <div className="text-3xl font-semibold leading-tight tracking-tight">Increase reply rates to cold emails by 150%</div>
                            <div className="text-lg opacity-50 leading-snug tracking-snug">
                                Video prospecting tool to personalize with your own face and voice.
                            </div>
                        </div>
                        <div className="flex md:w-2/3  items-end justify-end md:pt-20">
                            <img src={gEmails} className="w-full h-full object-contain object-bottom" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    );
};

export default Why;
