import { FiTrash2 } from "react-icons/fi";

import { fontFamily, fontSizes, fontStyles, fontWeights } from "../data-options";
import UiSelect from "../../components/ui-select";
import UiImage from "../../components/ui-image";
import UiDSection from "../../components/ui-dsection";
import UiDuration from "../../components/ui-duration";

const ClosingPage = (props) => {
    const { data, scene } = props;
    return (
        <>
            {scene && (
                <>
                    <div className="px-6 py-5 border-b border-lightblue100 flex items-center justify-between">
                        <div className="font-semibold">Closing Page</div>
                        <FiTrash2 className="opacity-40 hover:opacity-100 z-20 cursor-pointer" onClick={() => {}} />
                    </div>
                    <UiDSection title={`Agent`}>
                        <div className="flex flex-col gap-2 ">
                            <div className="">Name:</div>
                            <div className="p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                <textarea
                                    row="1"
                                    className="p-0 min-h-10"
                                    value={scene.agent_name}
                                    onChange={(e) => {
                                        scene.agent_name = e.target.value;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <div className="">Logo:</div>

                            <UiImage
                                data={scene.logo}
                                OnSuccess={(file) => {
                                    scene.logo = file;
                                    props.OnSet({ ...scene });
                                }}
                            />
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <div className="">Image:</div>

                            <UiImage
                                data={scene.agent_photo}
                                OnSuccess={(file) => {
                                    scene.agent_photo = file;
                                    props.OnSet({ ...scene });
                                }}
                            />
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <div className="">Email:</div>
                            <div className="p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                <textarea
                                    row="1"
                                    className="p-0"
                                    value={scene.agent_email}
                                    onChange={(e) => {
                                        scene.agent_email = e.target.value;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 ">
                            <div className="">Phone Number:</div>
                            <div className="p-2 border border-lightblue100 rounded-xl relative overflow-hidden outline-blue ">
                                <textarea
                                    row="1"
                                    className="p-0"
                                    value={scene.agent_number}
                                    onChange={(e) => {
                                        scene.agent_number = e.target.value;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                            </div>
                        </div>
                    </UiDSection>

                    <UiDSection title={`Duration`}>
                        <UiDuration
                            data={data}
                            scene={scene}
                            OnSet={(scene) => {
                                props.OnSet({ ...scene });
                            }}
                        />
                    </UiDSection>

                    <UiDSection title={`Typography`}>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-row gap-4">
                                <UiSelect
                                    data={fontFamily}
                                    value={scene.font}
                                    OnSet={(font) => {
                                        scene.font = font;
                                        props.OnSet({ ...scene });
                                    }}
                                />
                                <div className="w-24">
                                    <UiSelect
                                        data={fontSizes}
                                        value={scene.font_size}
                                        OnSet={(fontsize) => {
                                            scene.font_size = fontsize;
                                            props.OnSet({ ...scene });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </UiDSection>
                </>
            )}
        </>
    );
};

export default ClosingPage;
