import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleRes } from "src/common/services/response";

//--------------------------- QUERIES VIDEOS
export const getInputVideos = async ({ pagesize, pagenum }) => {
    
    if (getStoredUser() && getStoredUser().id) {
        if (!pagesize) {
            pagesize = 20;
        }
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.get(`${endpoint.rest}video/all?orderby=createdate desc&pagesize=${pagesize}`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const deleteInputVideo = async ({ videoId }) => {
    if (getStoredUser() && getStoredUser().id) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.delete(`${endpoint.rest}video/${videoId}`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

//MUTATION VIDEOS

export const updateInputVideo = async ({ type, url }) => {
    if (getStoredUser() && getStoredUser().id && url) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        const data = {
            auth: getStoredUser().auth,
            url,
        };

        try {
            const res = await axios.post(`${endpoint.rest}video/${type}`, data, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

//---------------------------QUERIES IMAGES

export const getInputImages = async ({pageNum}) => {
    if (getStoredUser() && getStoredUser().id) {
        pageNum = pageNum ? pageNum : 1;
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.get(`${endpoint.rest}image/all?orderby=createdate desc&pagenumber=${pageNum}`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const deleteInputImage = async ({ imageId }) => {
    if (getStoredUser() && getStoredUser().id) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.delete(`${endpoint.rest}image/${imageId}`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};
