import VideoPlayer from "src/common/components/video-player/video-player";
import { checkFileType } from "src/common/utils/utils";

const AudioUrl = (props) => {
    const { genData } = props;
    return (
        <div className="flex flex-col gap-4 py-2">

            {genData.audioURL && checkFileType(genData.audioURL) &&  (
                <div className="w-96 h-96 relative overflow-hidden aspect-square bg-black mx-auto rounded-2xl">
                    <VideoPlayer src={genData.audioURL} className={``} fluid={true} />
                </div>
            )}
             <div>Enter a url to an audio.</div>
            <input
                type="text"
                placeholder="https://your_audio_url_here"
                className="w-full border border-lightblue100 bg-lightblue50 rounded-2xl"
                onChange={(e) => {


                    delete genData.voiceId
                    delete genData.voiceGender

                    genData.audioURL = e.target.value;
                    props.OnSet(genData);
                }}
            />
           
        </div>
    );
};

export default AudioUrl;
