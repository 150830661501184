export const mockdata = {
    metadata: {
        videoid: "b12bd2ae-3108-4df5-a6a8-b6fc98817c18",
        botname: "Anya",
        modelid: "d7f8610f-99fa-453b-9d65-92abab242bd0",
        voiceid: "21m00Tcm4TlvDq8ikWAM",
        videolength: 70,
        numofscenes: 13,
        propertyid: "2e033ac4-0636-42cf-84dd-4698842d745a",
        baseurl: "https://www.redfin.com/CA/San-Pedro/1110-S-Leland-St-90731/unit-101/home/7692123",
    },
    opening_page: {
        image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_2.jpg",
        price: "$703,000Est. $5,165/mo Get pre-qualified",
        address: "1110 S Leland St #101,",
        logo: "https://squadz-dev-bucket-02.s3.amazonaws.com/logo.png",
        num_of_beds: 2,
        num_of_baths: 2,
        sqfeet: 1,
        start: 0,
        end: 4,

        font: "Montserrat",
        font_size: 12,

        type: "opening-page",
        scene_video:
            'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
        scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_2.jpg",
    },
    closing_page: {
        agent_photo: "https://www.compass.com/m/14/760f336d-1317-4b16-aa70-175204e055d6/300x300.jpg",
        agent_name: "Melinda Brown",
        agent_email: "mindy.brown@compass.com",
        agent_number: "M: 310-989-3038",
        logo: "https://squadz-dev-bucket-02.s3.amazonaws.com/logo.png",
        start: 66,
        end: 70,

        font: "Montserrat",
        font_size: 12,

        type: "closing-page",
        scene_video:
            'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
        scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_2.jpg",
    },
    scenes: [
        {
            index: "1",
            photos: [
                {
                    id: "001",
                    duration: 4,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_1_2.jpg",
                },

                {
                    id: "002",
                    duration: 4,
                    effect: {
                        id: "bokeh_blur",
                        name: "Bokeh Blur",
                        radius: "90",
                        sigma: "21",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_10_2.jpg",
                },
            ],
            scene: "Exterior view of the building",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "Welcome to 1110 S Leland St.",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },

            start: 4,
            end: 12,

            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_1_2.jpg",
            id: "001",
            type: "scene",
        },
        {
            index: "2",
            photos: [
                {
                    id: "001",
                    duration: 5,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_10_2.jpg",
                },
            ],
            scene: "Living room overview",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "Step into the spacious living area.",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 12,
            end: 17,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_10_2.jpg",
            id: "002",
            type: "scene",
        },
        {
            index: "3",

            photos: [
                {
                    id: "001",
                    duration: 4,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_11_2.jpg",
                },
            ],
            scene: "Living Room Expansion",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 17,
            end: 21,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_11_2.jpg",
            id: "003",
            type: "scene",
        },
        {
            index: "4",
            photos: [
                {
                    id: "001",
                    duration: 5,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_13_2.jpg",
                },
            ],
            scene: "Dining room overview",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "The dining room is perfect for family meals.",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 21,
            end: 26,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_13_2.jpg",
            id: "004",
            type: "scene",
        },
        {
            index: "5",
            photos: [
                {
                    id: "001",
                    duration: 4,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_14_2.jpg",
                },
            ],
            scene: "Dining Room Detail",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 26,
            end: 30,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_14_2.jpg",
            id: "005",
            type: "scene",
        },
        {
            index: "6",
            photos: [
                {
                    id: "001",
                    duration: 5,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_20_2.jpg",
                },
            ],
            scene: "Kitchen overview",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "A modern kitchen with all the latest amenities.",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 30,
            end: 35,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_20_2.jpg",
            id: "006",
            type: "scene",
        },
        {
            index: "7",
            photos: [
                {
                    id: "001",
                    duration: 4,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_21_2.jpg",
                },
            ],
            scene: "Kitchen Detail",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 35,
            end: 39,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_21_2.jpg",
            id: "007",
            type: "scene",
        },
        {
            index: "8",
            photos: [
                {
                    id: "001",
                    duration: 5,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_25_2.jpg",
                },
            ],
            scene: "Bedroom overview",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "Relax in the beautifully designed bedrooms.",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 39,
            end: 44,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_25_2.jpg",
            id: "008",
            type: "scene",
        },
        {
            index: "9",
            photos: [
                {
                    id: "001",
                    duration: 4,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_26_2.jpg",
                },
            ],
            scene: "Bedroom Detail",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 44,
            end: 48,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_26_2.jpg",
            id: "009",
            type: "scene",
        },
        {
            index: "10",
            photos: [
                {
                    id: "001",
                    duration: 5,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_28_2.jpg",
                },
            ],
            scene: "Bathroom overview",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "Modern bathrooms with premium fixtures.",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 48,
            end: 53,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_28_2.jpg",
            id: "010",
            type: "scene",
        },
        {
            index: "11",
            photos: [
                {
                    id: "001",
                    duration: 4,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_29_2.jpg",
                },
            ],
            scene: "Bathroom Detail",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 53,
            end: 57,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_29_2.jpg",
            id: "011",
            type: "scene",
        },
        {
            index: "12",
            photos: [
                {
                    id: "001",
                    duration: 5,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_16_2.jpg",
                },
            ],
            scene: "Spacious Outdoor Patio",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "Enjoy the beautiful outdoor patio.",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },
            start: 57,
            end: 62,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_16_2.jpg",
            id: "012",
            type: "scene",
        },
        {
            index: "13",
            photos: [
                {
                    id: "001",
                    duration: 4,
                    effect: {
                        id: "zoom_in",
                        name: "Zoom In",
                    },
                    image: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_17_2.jpg",
                },
            ],
            scene: "Outdoor Patio Detail",
            transition_effect: {
                id: "fade",
                name: "Fade",
            },
            voiceover: "",
            font: "Montserrat",
            font_size: 12,

            bot: {
                visible: true,
                botvideo: "https://svc.staging.squadz.live/download/fd3ac017-696c-4126-b4fe-7fac4ff88675/output.mp4",
                x: 900,
                y: 700,
            },

            text: {
                visible: true,
                alignment: 5,
                margin_left: 10,
                margin_right: 10,
                margin_vertical: 50,
            },

            start: 62,
            end: 66,
            scene_video:
                'https://squadz-dev-bucket-02.s3.amazonaws.com/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4","key":"5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/1/house2.mp4',
            scene_ss: "https://ssl.cdn-redfin.com/photo/45/bigphoto/059/PV24117059_17_2.jpg",
            id: "013",
            type: "scene",
        },
    ],
};
