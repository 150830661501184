import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleResponse, handleRes } from "src/common/services/response";

//QUERIES
export const getVideos = async ({ pageNum }) => {
    if (getStoredUser() && getStoredUser().id) {
        pageNum = pageNum ? pageNum : 1;
       
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.get(`${endpoint.rest}generate/all?orderby=createdate desc&pagenumber=${pageNum}`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getVideo = async (id) => {
    if (getStoredUser() && getStoredUser().id) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.get(`${endpoint.rest}generate/${id}`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

//MUTATIONS

export const generateVideo = async ({ type, name, text, useVoice, imageURL, videoURL, audioURL, imageId, videoId, voiceId, voiceGender }) => {
    if (getStoredUser() && getStoredUser().id && type) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        const data = {
            auth: getStoredUser().auth,
            name,
            useVoice,
            text,
        };

        if (imageURL) {
            data.imageURL = imageURL;
        }
        if (videoURL) {
            data.videoURL = videoURL;
        }

        if (audioURL) {
            data.audioURL = audioURL;
        }

        if (imageId) {
            data.imageId = imageId;
        }

        if (videoId) {
            data.videoId = videoId;
        }

        if (voiceId) {
            data.voiceId = voiceId;
        }

        if (voiceGender) {
            data.voiceGender = voiceGender;
        }

        try {
            const res = await axios.post(`${endpoint.rest}generate/${type}`, data, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const deleteVideo = async ({ videoId }) => {
    if (getStoredUser() && getStoredUser().id) {
        const headers = {
            "Content-Type": "application/json",
            "x-api-key": getStoredUser().id,
        };
        try {
            const res = await axios.delete(`${endpoint.rest}generate/${videoId}`, { headers });
            return handleRes(res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};
