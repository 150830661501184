import { FaPlus } from "react-icons/fa6";
import { FiTrash2 } from "react-icons/fi";
import Dbox from "src/common/components/dbox/dbox";

const EditorTimeline = (props) => {
    const { data } = props;

    const Thumb = (props) => {
        let isSelected = false;

        if (props.scene && props.selected) {
            if (props.scene.type === "scene") {
                if (props.selected.id === props.scene.id) {
                    isSelected = true;
                   
                }
            } else if (props.scene.type === "opening-page" && props.selected.type === "opening-page") {
                isSelected = true;
            } else if (props.scene.type === "closing-page" && props.selected.type === "closing-page") {
                isSelected = true;
            }
        }

        

        return (
            <div className="flex flex-col min-w-20 max-w-20 ">
                {props.type === "add" ? (
                    <div className="flex flex-row bg-lightblue50 min-w-full min-h-40 max-h-40 relative overflow-hidden rounded-2xl cursor-pointer border border-dashed border-blue items-center justify-center hover:bg-lightblue100">
                        <FaPlus className="text-blue" />
                    </div>
                ) : (
                    <div className="flex relative flex-col gap-4 group">
                        {/* <div className="absolute -top-20 left-0 w-full p-2 rounded-xl bg-white z-50 text-xs text-center leading-tight shadow-lg shadow-indigo-900/20">{props.title}</div> */}

                        <div className="transition-opacity absolute top-2 right-2 p-1.5 rounded-xl bg-white z-50 shadow-lg shadow-indigo-900/10 cursor-pointer opacity-0 group-hover:opacity-100">
                            <FiTrash2 className="opacity-40 hover:opacity-100 z-20" onClick={() => {}} />
                        </div>
                        <div
                            className={`flex flex-row bg-lightblue100 min-w-full min-h-40 max-h-40 relative overflow-hidden rounded-xl cursor-pointer`}
                            onClick={() => {
                            
                                props.OnSelect(props.scene);
                            }}
                        >
                            {props.image && <img src={props.image} className={`object-cover w-full h-full`} />}
                            <div
                                className={`transition absolute w-full h-full left-0 top-0 bg-lightblue100/50 flex items-center justify-center ${
                                    isSelected ? "opacity-0" : "group-hover:opacity-0"
                                }`}
                            ></div>
                        </div>
                        <div className="text-xs text-center leading-tight">{props.title && props.title.length >= 7 ? `${props.title.slice(0, 7)}...` : props.title} </div> 
                       
                    </div>
                )}
            </div>
        );
    };

    return (
        <Dbox className="editor-timeline max-h-1/4">
            <div className="timeline-container relative min-w-full min-h-52 overflow-visible flex-col p-6">
                <div className="scenes relative z-20 flex-1 flex flex-row gap-5 min-h-full max-w-full overflow-visible">
                    <Thumb {...props} image={data.opening_page.scene_ss} scene={data.opening_page} title={"Opening Page"} />
                    <div className="w-1 min-h-full border-l border-lightblue100"></div>

                    {data.scenes && data.scenes.map((scene, s) => <Thumb key={s} {...props} scene={scene} image={scene.scene_ss} title={scene.scene} />)}

                    <Thumb {...props} type={"add"} />

                    <div className="w-1 min-h-full border-l border-lightblue100"></div>
                    <Thumb {...props} image={data.closing_page.scene_ss} scene={data.closing_page} title={"Closing Page"} />
                </div>
            </div>
        </Dbox>
    );
};

export default EditorTimeline;
