import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ReactLenis, useLenis } from "lenis/react";

import { animateCTA2, animateFaq, animateFooter } from "../../layouts/marketing/animation/animation";
import { animateFeatItems } from "../../layouts/marketing/animation/features";

import Header from "src/layouts/marketing/header";
import Footer from "src/layouts/marketing/footer";
import CTA from "src/layouts/marketing/cta";
import Faq from "src/layouts/marketing/faq";
import ada from "src/common/assets/images/marketing/ada-orig.mp4";
import gVidThumbs from "src/common/assets/images/marketing/g-vid-thumbs.png";

import logoApollo from "src/common/assets/images/marketing/logo-apollo.png";
import logoClose from "src/common/assets/images/marketing/logo-close.png";
import logoHighlevel from "src/common/assets/images/marketing/logo-highlevel.png";
import logoHubspot from "src/common/assets/images/marketing/logo-hubspot.png";
import logoInstantly from "src/common/assets/images/marketing/logo-instantly.png";
import logoLemlist from "src/common/assets/images/marketing/logo-lemlist.png";
import logoOutreach from "src/common/assets/images/marketing/logo-outreach.png";
import logoSalesloft from "src/common/assets/images/marketing/logo-salesloft.png";

import gHouse1 from "src/common/assets/images/marketing/house-1.jpg";
import gHouse2 from "src/common/assets/images/marketing/house-2.jpg";
import gHouse3 from "src/common/assets/images/marketing/house-3.jpg";

import Plans from "src/layouts/marketing/plans";
import MarketingLayout from "src/layouts/marketing/marketing-layout";

gsap.registerPlugin(useGSAP);

const Pricing = () => {
    const main = useRef();

    useGSAP(() => {}, { scope: main });

    return (
        <MarketingLayout>
            <div className="fixed scale-75 translate-y-32 md:translate-y-0 md:scale-100 left-0 top-0 w-full min-h-screen flex items-center justify-center z-0 pt-32">
                <div
                    className="bg-blur absolute rounded-full z-0 opacity-60 left-1/2 -translate-x-1/2 top-1/3 "
                    style={{ background: "#6676FF", width: 1000, height: 300, filter: `blur(300px)` }}
                ></div>
            </div>
            <div className="sections flex flex-col gap-32 pt-32 " ref={main}>
                <Plans isHero={true} />
            </div>
        </MarketingLayout>
    );
};

export default Pricing;
