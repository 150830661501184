import axios from "axios";
import endpoint from "./endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleResponse } from "./response";

export const listUserVids = async () => {
    if (getStoredUser() && getStoredUser().id) {
        let query = {
            Params: {
                userid: getStoredUser().id,
                auth: getStoredUser().auth,
                sys: "s3",
                act: "select",
                pagesize: "20",
                pagenumber: "1",
            },
            Where: {
                userid: getStoredUser().id,
                // directory: `${getStoredUser().id}/DIGITALU_custom_vid`
            },
        };
        try {
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getS3 = async (input, useAdminAuth) => {
   
    const theAuthUser = {
        id: getStoredUser()?.id,
        auth: getStoredUser()?.auth,
    };

    if (useAdminAuth === true) {
        theAuthUser.id = `11111111-1111-1111-1111-111111111111`;
        theAuthUser.auth = `xxxx`;
    }

   if (theAuthUser.id && theAuthUser.auth) {
        try {
            let query = {
                Params: {
                    userid: theAuthUser.id,
                    auth: theAuthUser.auth,
                    sys: "s3",
                    act: "select",
                    pagesize: "1",
                    pagenumber: "1",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};


export const getBatchVideos = async (input, pageNum) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: getStoredUser().auth,
                    sys: "s3",
                    act: "select",
                    pagesize: "10",
                    pagenumber: pageNum.toString(),
                    orderby: "createdate desc",
                },
                Where:input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};
