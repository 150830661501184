import { useEffect, useState, useRef } from "react";

import { dateFormatter, toEpoch } from "src/common/utils/utils";
import { deleteVideo, getVideo } from "src/common/services/enduser/generate";
import { getQueue } from "src/common/services/utils";
import logoIcon from "src/common/assets/images/logo-white.svg";
import Dbox from "src/common/components/dbox/dbox";
import VideoPlayer from "src/common/components/video-player/video-player";

import More from "./card-gen-more";

import { IoMdRefresh } from "react-icons/io";
import { RiMore2Fill } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { TbShare2 } from "react-icons/tb";

const CardGen = (props) => {
    const { video } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const [thesrc, setThesrc] = useState();
    const [showMore, setShowMore] = useState();

    const [vidState, setVidState] = useState();
    const [timedOut, setTimedOut] = useState(false);
    const [fetching, setFetching] = useState(false);
    const pollTimeout = useRef();
    const pollCounter = useRef(0);
    const pollLimit = 20;

    useEffect(() => {
        if (video && video.result) {
            if (video.result.indexOf("{") !== -1) {
                let result = JSON.parse(video.result);
                video.result = result?.upload[0]?.cache

                setThesrc(result?.upload[0]?.cache);

            } else if (video.result.indexOf("https:") !== -1) {
                setThesrc(video.result);
            }
        }
    }, []);

    useEffect(() => {
        setVidState(video.state);
        pollStatus();
    }, [video.state]);

    // useEffect(() => {
    //     if (showPrev) {
    //         handleVideo({ opr: "get", videoId: video.id });
    //     }
    // }, [showPrev]);

    const pollStatus = async () => {
        pollCounter.current++;
        if (pollCounter.current <= pollLimit) {
            if (pollTimeout.current) {
                clearTimeout(pollTimeout.current);
            }

            if (video.state === "processing" || video.state === "") {
                try {
                    const que_res = await getQueue({ id: video.id });
                    console.log("poll queue status :", que_res);

                    if (que_res.state === "complete") {
                        setVidState("complete");

                        if (que_res.result.indexOf("{") !== -1) {
                            let result = JSON.parse(que_res.result);
                            setThesrc(result?.upload[0]?.cache);
                        } else if (que_res.result.indexOf("https:") !== -1) {
                            setThesrc(que_res.result);

                            navigate("/admin/generated-videos", { state: { reload: true } });
                        }
                    } else {
                        setVidState(que_res.state);

                        if (que_res.state.indexOf("error") === -1) {
                            pollTimeout.current = setTimeout(async () => {
                                clearTimeout(pollTimeout.current);
                                await pollStatus();
                            }, 6000);
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            console.log("timed out");
            setTimedOut(true);
        }
    };

    const handleVideo = async ({ opr, videoId }) => {
        switch (opr) {
            case "get":
                if (videoId) {
                    setFetching(true);
                    const get_res = await getVideo(videoId);
                    console.log("get_res :", get_res);
                    setFetching(false);
                }
                break;

            case "delete":
                if (videoId) {
                    const del_res = await deleteVideo({ videoId });
                    console.log("del_res :", del_res);
                    props.OnDelete(true);
                }
                break;

            default:
                break;
        }
    };

    return (
        <Dbox className="aspect-square flex flex-col relative overflow-hidden" data-id={video.id}>
            <div className="relative max-h-full bg-zinc-950 rounded-3xl flex-1  cursor-pointer">
                {thesrc ? (
                    <VideoPlayer
                        image={`${thesrc}/frame1.jpg`}
                        src={thesrc}
                        hoverPlay={true}
                        actions={false}
                        className=""
                        fluid={true}
                        fit={"cover"}
                        onClick={() => {
                            {
                                thesrc && navigate(location.pathname, { state: { show: "detail-generated", data: video } });
                            }
                            //setShowPrev(true);
                        }}
                    />
                ) : (
                    <div className="flex-1  h-full flex items-center justify-center flex-1">
                        <img src={logoIcon} className={`w-1/3  blinking`} />
                    </div>
                )}
            </div>

            {/* <div
                className="absolute top-0 left-0 w-full h-full z-10 cursor-pointer"
                onClick={() => {
                    {
                        thesrc && navigate(location.pathname, { state: { show: "detail-generated", data: video } });
                    }
                    //setShowPrev(true);
                }}
            ></div> */}
            {showMore && (
                <More
                    thesrc={thesrc}
                    video={video}
                    OnDelete={async () => {
                        await handleVideo({ opr: "delete", videoId: video.id });
                    }}
                    OnClose={() => {
                        setShowMore(false);
                    }}
                />
            )}

            <div className="p-3 absolute top-0 left-0 w-full flex justify-between z-20">
                <div className="bg-black/60  rounded-3xl px-3 py-1 flex items-center gap-2 text-white text-xs">{video.name}</div>
                {thesrc && (
                    <div
                        className="bg-black/60 rounded-full p-3 cursor-pointer"
                        onClick={() => {
                            setShowMore(true);
                        }}
                    >
                        <RiMore2Fill className="text-white text-xl" />
                    </div>
                )}
            </div>

            <div className="absolute w-full bottom-0 left-0 z-20 flex items-end p-4 pt-0 justify-between">
                <div className="flex flex-col bg-black/60 rounded-2xl px-3 py-1 text-white text-xs  ">
                    <div className="flex items-center gap-2">
                        <div className={``}>{vidState}</div>
                        {vidState === "processing" && timedOut === true && (
                            <IoMdRefresh
                                className="text-lg cursor-pointer opacity-40 hover:opacity-100"
                                onClick={() => {
                                    setTimedOut(false);
                                    pollCounter.current = 0;
                                    pollStatus();
                                }}
                            />
                        )}
                    </div>
                    <div className="opacity-40">{dateFormatter(video.createdate)}</div>
                </div>

                {thesrc && (
                    <div>
                        <button
                            className="button button-small p-5 rounded-full"
                            onClick={() => {
                                navigate(location.pathname, { state: { show: "share", data: video } });
                            }}
                        >
                            <TbShare2 className="text-white text-xl" />
                        </button>
                    </div>
                )}
            </div>
        </Dbox>
    );
};

export default CardGen;
