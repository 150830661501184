import Editor from "src/modules/admin/realestate/editor";
import AdminLayout from "src/layouts/admin-layout";
const RealEstateEditor = () => {
    return (
        <AdminLayout type={"full"}>
            <Editor />
        </AdminLayout>
    );
};

export default RealEstateEditor;
