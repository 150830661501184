import { logout } from "src/common/components/auth/auth";

export const handleResponse = (query, res) => {
    //  console.log(`-> handling (${query?.Params?.act}) ::` , res)
    const sys = query?.Params?.sys;
    const act = query?.Params?.act;
    const pagesize = query?.Params?.pagesize;

    let response = res;
    let expectedres = "result";
    if (sys === "page") {
        expectedres = act;
    }

    if (res.data) {
        if (res.data[0] && res.data[0].error === "Auth Failed") {
            logout();
            window.location.href = "/";
        } else if (res.data.result && res.data.result[0].error) {
            // error
            response = res.data.result[0].error;
            console.log("error response : ", response);

            if (response === "Auth Failed") {
                logout();
                window.location.href = "/";
            }
        } else if (res.data[expectedres] === null) {
            response = [];
        } else if (res.data[expectedres]) {
            // success
            if (pagesize === "1") {
                response = res.data[expectedres][0];
            } else {
                if ((response = res.data[expectedres] === null)) {
                    response = [];
                } else {
                    response = res.data[expectedres];
                }
            }
        } else {
            console.log("unmarshalled:", response);
            response = res.data;
        }
    }

    //console.log(`<- returning (${query?.Params?.act}) ::`, response)
    return response;
};

export const handleRes = (res) => {
    let theres = [];
  //  console.log(res?.data?.result)
    if (res?.data?.result && !res?.data?.result[0].error) {
        theres = res.data.result;
    }

    return theres;
};
