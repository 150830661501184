import { useEffect } from "react";
import Iicon from "../iicon/iicon";

const TimelineNavi = (props) => {
    const { data, selected, direction , canNext } = props;
    const selectedIndex = data.findIndex((data) => data.id === selected.id);

    return (
        <div className="flex flex-1">
            <div className="flex flex-1 justify-start">
                {direction === "back" && selectedIndex !== undefined && data[selectedIndex - 1] && (
                    <button
                        className="button button-gray flex gap-3 pl-3 items-center"
                        onClick={() => {
                            if (props.onClick) {
                                props.onClick(data[selectedIndex - 1]);
                            }
                        }}
                    >
                        <Iicon icon={'arrow-left'}/>
                        <div>Back</div>
                        
                    </button>
                )}
            </div>

            <div className="flex  flex-1 justify-end">
                {direction === "next" && selectedIndex !== undefined && data[selectedIndex + 1] && (
                    <button
                        className="button flex gap-3 pr-3 items-center"
                        disabled={!canNext}
                        onClick={() => {

                            if (props.onClick && canNext) {
                                props.onClick(data[selectedIndex + 1]);
                            }
                        }}
                    >
                        <div>Next</div>
                        <Iicon icon={'arrow-right-white'}/>
                    </button>
                )}
            </div>
        </div>
    );
};

export default TimelineNavi;
