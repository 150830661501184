import { dateFormatter } from "src/common/utils/utils";
import VideoPlayer from "src/common/components/video-player/video-player";
const DetailVoice = (props) => {
    const { voice } = props;
    return (
        <div className="bg-white p-4 gap-3">
            {voice.sample && (
                // <voice src={voice.sample} playsInline controls className="object-contain w-full h-full" />
                <VideoPlayer image={`${voice.sample}/frame1.jpg`} src={voice.sample} className="min-w-full h-full" preload={"none"} />
            )}

            <div className="flex flex-col px-3 pt-8 gap-3 justify-between ">
                <div className="flexp flex flex-row gap-4">
                    <div className="opacity-40">Name : </div> <div>{voice.voiceName}</div>
                </div>

                <div className="flexp  flex flex-row gap-4">
                    <div className="opacity-40">Created : </div> <div> {dateFormatter(voice.date)}</div>
                </div>

                <div className="flex flex-row justify-between items-center pt-5  pb-2">
                    <div>
                        <a
                            href={voice.sample}
                            target="_blank"
                            download={voice.sample}
                            className="button cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                        >
                            Download
                        </a>
                    </div>

                    {/* <div>
                                    <button
                                        className="button button-gray cursor-pointer rounded-3xl py-3 px-6 hover:translate-y-1 flex gap-4 items-center"
                                        onClick={async () => {
                                            await handleVoice({ opr: "delete", voiceId: voice.voiceId });
                                        }}
                                    >
                                        Delete
                                    </button>
                                </div> */}
                </div>
            </div>
        </div>
    );
};

export default DetailVoice;
