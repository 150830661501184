import { useRef, useEffect , useState } from "react";
const OnBottom = (props) => {
    let bottomRef = useRef();
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
       
        if (isBottom && props.OnBottom) {
            props.OnBottom(true);
        }
    }, [isBottom]);

    useEffect(() => {
        window.addEventListener("scroll", checkVisibility);
        window.addEventListener("resize", checkVisibility);
        checkVisibility(); // Check visibility on initial render

        return () => {
            window.removeEventListener("scroll", checkVisibility);
            window.removeEventListener("resize", checkVisibility);
        };
    }, []);

    const checkVisibility = () => {
        if (!bottomRef.current) return;

        const { top, bottom } = bottomRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        
        if (top >= 0 && bottom <= viewportHeight) {
            setIsBottom(true);
        } else {
            setIsBottom(false);
        }
    };

    

    return (
        <div className="flex-1 flex flex-col">
            {props.children} <div ref={bottomRef}></div>
        </div>
    );
};

export default OnBottom;

