import { useNavigate } from "react-router-dom";

import person1 from "src/common/assets/images/marketing/person-1.jpg";
import person2 from "src/common/assets/images/marketing/person-2.jpg";
import person3 from "src/common/assets/images/marketing/person-3.jpg";
import person4 from "src/common/assets/images/marketing/person-4.jpg";
import person5 from "src/common/assets/images/marketing/person-5.jpg";
import person6 from "src/common/assets/images/marketing/person-6.jpg";
import person7 from "src/common/assets/images/marketing/person-7.jpg";
import person8 from "src/common/assets/images/marketing/person-8.jpg";
import person9 from "src/common/assets/images/marketing/person-9.jpg";
import person10 from "src/common/assets/images/marketing/person-10.jpg";
import person11 from "src/common/assets/images/marketing/person-11.jpg";
import person12 from "src/common/assets/images/marketing/person-12.jpg";
import person13 from "src/common/assets/images/marketing/person-13.jpg";
import person14 from "src/common/assets/images/marketing/person-14.jpg";
import person15 from "src/common/assets/images/marketing/person-15.jpg";
import person16 from "src/common/assets/images/marketing/person-16.jpg";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
gsap.registerPlugin(useGSAP);


const CTA = (props) => {
    const main = useRef()
    const navigate = useNavigate();
    const videos = [person1, person2, person3, person4, person5, person6, person7, person8];
    const videos2 = [person3, person12, person11, person15, person16, person14, person13, person12];

    useGSAP(
        () => {
            gsap.from(".container", {
                scale: 1.3,
                y: "200",
                scrollTrigger: {
                    trigger: ".container",
                    start: "0% 90%",
                    end: "+=100",
                    scrub: 1,
                },
            });
        },
        { scope: main }
    );

    return (
        <div ref={main} className="section-cta section flex relative w-full px-4">
            <div className="container mx-auto relative flex-1 flex flex-col z-20 bg-black rounded-4xl p-10 md:p-24 gap-32 text-white overflow-hidden">
                {/* <img src={bgBlur} className="absolute top-0 left-0 h-full w-full object-cover object-center z-0" /> */}
                <div
                    className="absolute rounded-full z-10 left-1/2 -translate-x-1/2 -top-2/3 opacity-20 md:opacity-40"
                    style={{ background: "#6676FF", width: 800, height: 600, filter: `blur(200px)` }}
                ></div>

                <div className="flex flex-col items-center text-center gap-10 py-10 relative z-20">
                    <div className="g-title text-3xl md:text-7xl leading-tight tracking-tighter font-semibold ">
                        <div>Don't send plain old</div>
                        <div>boring text emails</div>
                    </div>
                    <button
                        className="button text-lg rounded-full px-12"
                        onClick={() => {
                            navigate("/admin/dashboard");
                        }}
                    >
                        Try For Free
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CTA;
