import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dbox from "src/common/components/dbox/dbox";
import { dateFormatter } from "src/common/utils/utils";
import { deleteInputImage } from "src/common/services/enduser/input";
import More from "./card-image-more";

import { RiMore2Fill } from "react-icons/ri";

const CardImage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { image, genData } = props;
    const [showMore, setShowMore] = useState();

    const vimage = image.url.split("/")[image.url.split("/").length - 1];

    const handleImage = async ({ opr, imageId, image }) => {
        switch (opr) {
            case "delete":
                if (imageId) {
                    const del_res = await deleteInputImage({ imageId });
                    console.log("del_res :", del_res);

                    props.OnDelete(true);
                }
                break;
            case "onuse":
                if (image) {
                    const theimage = { ...image };
                    if (theimage.id === genData?.imageId) {
                        props.OnUse({});
                    } else {
                        theimage.type = "image";
                        props.OnUse({ ...theimage });
                    }
                }
                break;

            default:
                break;
        }
    };

    return (
        <>
            <Dbox
                className={`aspect-square flex flex-col relative overflow-hidden 
                    ${props.for && genData && genData.imageId !== image.id ? "hover:border-4 hover:border-indigo-500" : ""}
                    ${props.for && genData && genData.imageId === image.id ? "p-2 border-4 !border-indigo-500" : ""}
                `}
                data-id={image.id}
            >
                <div className={`relative max-h-full rounded-2xl flex-1 ${genData && genData.imageId === image.id ? "bg-blue" : " bg-zinc-950"}`}>
                    {image.url && (
                        <img src={image.url} className={`object-cover w-full h-full ${genData && genData.imageId === image.id ? "opacity-50" : ""}`} />
                    )}
                </div>
                <div
                    className="absolute top-0 left-0 w-full h-full z-10 cursor-pointer"
                    onClick={() => {
                        if (image.url) {
                            {
                                props.for === "select" && handleImage({ opr: "onuse", image });
                            }
                            {
                                !props.for && navigate(location.pathname, { state: { show: "detail-image", data: image } });
                            }
                        }

                        //setShowPrev(true);
                    }}
                ></div>
                {showMore && (
                    <More
                        thesrc={image.url}
                        image={image}
                        OnDelete={async () => {
                            await handleImage({ opr: "delete", imageId: image.id });
                        }}
                        OnClose={() => {
                            setShowMore(false);
                        }}
                    />
                )}
                <div className="p-3 absolute top-0 left-0 w-full flex justify-between z-20">
                    <div className="p-2">
                        {/* {genData?.imageId !== image.id ? (
                            <button
                                className="button button-small px-4"
                                onClick={() => {
                                    handleImage({ opr: "onuse", image });
                                }}
                            >
                                use
                            </button>
                        ) : (
                            
                            <div className="bg-indigo-500/20 px-4 py-1 rounded-full text-white border border-blue">selected</div>
                        )} */}

                        {/* {genData && genData.imageId === image.id && <div className="bg-indigo-500/20 px-4 py-1 rounded-full text-white border border-blue">selected</div>} */}
                    </div>
                    {image.url && (
                        <div
                            className="bg-black/60 rounded-full p-3 cursor-pointer"
                            onClick={() => {
                                setShowMore(true);
                            }}
                        >
                            <RiMore2Fill className="text-white text-xl" />
                        </div>
                    )}
                </div>

                <div className="absolute bottom-5 left-5 bg-black/60  rounded-2xl px-3 py-1 text-white text-xs">
                    <div>{vimage}</div>
                    <div className="opacity-40">{dateFormatter(image.uploadDate)}</div>
                </div>
            </Dbox>
        </>
    );
};

export default CardImage;
