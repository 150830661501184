import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import Plans from "src/layouts/marketing/plans";
import Why from "src/layouts/marketing/why";
import How from "src/layouts/marketing/how";

import bgBlur from "src/common/assets/images/bg-blur.svg";
import iconAvatar from "src/common/assets/images/icon-avatar.svg";
import iconSubtitle from "src/common/assets/images/marketing/icon-subtitle.svg";
import iconScenes from "src/common/assets/images/marketing/icon-scenes.svg";
import iconEasy from "src/common/assets/images/marketing/icon-easy.svg";
import iconArrowDown from "src/common/assets/images/marketing/icon-arrow-down.svg";
import iconCheck from "src/common/assets/images/marketing/icon-check.svg";
import logoCompass from "src/common/assets/images/marketing/logo-compass.svg";
import ada from "src/common/assets/vids/ada.mp4";

import { animateFeats, animateHero } from "./animation/real-estate";

import vidHouse1 from "src/common/assets/vids/house1.mp4";
import vidHouse2 from "src/common/assets/vids/house2.mp4";
import vidHouse3 from "src/common/assets/vids/house3.mp4";
import MarketingLayout from "src/layouts/marketing/marketing-layout";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(useGSAP);

const RealEstate = () => {
    const navigate = useNavigate();
    const main = useRef();
    const phoneRef = useRef();

    const feats = [
        {
            icon: iconAvatar,
            title: "Personalized Avatars",
            position: "start",
            desc: `Increase response rates by up to 500% with Digitalu's dynamic video personalization.`,
        },
        {
            icon: iconSubtitle,
            title: "AI Generated Subtitles",
            position: "end",
            desc: `Increase response rates by up to 500% with Digitalu's dynamic video personalization.`,
        },
        {
            icon: iconScenes,
            title: "Customizable Scenes",
            position: "start",
            desc: `Increase response rates by up to 500% with Digitalu's dynamic video personalization.`,
        },
        {
            icon: iconEasy,
            title: "Easy to get started",
            position: "end",
            desc: `Increase response rates by up to 500% with Digitalu's dynamic video personalization.`,
        },
    ];

    useGSAP(
        () => {
            const winWidth = window.innerWidth;

            gsap.to(".section-hero .arrow-down", {
                opacity: 0,
                y: -100,
                scrollTrigger: {
                    trigger: ".section-hero",
                    start: "bottom 95%",
                    end: "+=50",
                    scrub: 1,
                },
            });

            gsap.set(`.phone-bg`, {
                yPercent: 70,
            });

            const phone = () => {
                const phoneTl_1 = gsap.timeline();

                phoneTl_1.set(`.phone`, {
                    scale: 2,
                    yPercent: 160,
                });
                //1

                phoneTl_1.fromTo(
                    `.phone`,
                    {
                        scale: 2,
                        yPercent: 160,
                    },

                    {
                        scale: 1.8,
                        yPercent: -60,
                        scrollTrigger: {
                            trigger: ".feat-item-1",
                            start: "0% 90%",
                            end: "+=300",
                            scrub: 1,
                        },
                    }
                );
                //2
                phoneTl_1.fromTo(
                    `.phone`,
                    {
                        scale: 1.8,
                        yPercent: -60,
                    },

                    {
                        scale: 1.8,
                        yPercent: 33,
                        scrollTrigger: {
                            trigger: ".feat-item-2",
                            start: "40% bottom",
                            end: "+=300",
                            scrub: 1,
                        },
                    }
                );
                //3
                phoneTl_1.fromTo(
                    `.phone`,
                    {
                        scale: 1.8,
                        yPercent: 32,
                    },
                    {
                        yPercent: 0,
                        scale: 1.2,
                        scrollTrigger: {
                            trigger: ".feat-item-3",
                            start: "40% bottom",
                            end: "+=300",
                            scrub: 1,
                        },
                    }
                );
                //end
                phoneTl_1.fromTo(
                    `.phone`,
                    {
                        yPercent: 0,
                        scale: 1.2,
                    },
                    {
                        scale: 0.5,
                        opacity: 0,

                        // scale: 0.5,
                        // opacity :0,
                        // yPercent: -140,
                        scrollTrigger: {
                            trigger: ".section-how",
                            start: "10% 70%",
                            end: "+=300",
                            scrub: 1,
                        },
                    }
                );
            };

            const phoneScreen = () => {
                const ps_tl = gsap.timeline({ defaults: { duration: 5 } });

                ps_tl.to(`.phone-screen`, {
                    xPercent: 0,
                    opacity: 0.6,
                    scrollTrigger: {
                        trigger: ".feat-item-2",
                        start: "40% bottom",
                        end: "+=300",
                        scrub: 1,
                    },
                });

                ps_tl.fromTo(
                    `.phone-screen`,
                    {
                        xPercent: 0,
                        opacity: 0.6,
                    },
                    {
                        xPercent: 0,
                        opacity: 1,
                        scrollTrigger: {
                            trigger: ".feat-item-3",
                            start: "40% bottom",
                            end: "+=300",
                            scrub: 1,
                        },
                    }
                );

                const ps_tl2 = gsap.timeline({
                    defaults: { duration: 5 },
                    scrollTrigger: {
                        trigger: ".feat-item-3",
                        start: "20% 50%",
                        end: "+=500",
                        scrub: 1,
                    },
                });

                ps_tl2.to(`.phone-screen`, {
                    xPercent: -100,
                });
                ps_tl2.to(`.phone-screen`, {
                    xPercent: -200,
                });
            };

            const phoneBg = () => {
                const pb_tl = gsap.timeline({ defaults: { duration: 5 } });
                pb_tl.to(`.phone-bg`, {
                    scale: 1.1,
                    yPercent: -50,
                    scrollTrigger: {
                        trigger: ".section-feats",
                        start: "5% 98%",
                        end: "+=300",
                        scrub: 1,
                    },
                });

                pb_tl.fromTo(
                    `.phone-bg`,
                    {
                        scale: 1.1,
                        yPercent: -50,
                    },
                    {
                        scale: 1.8,
                        yPercent: -150,
                        immediateRender: false,
                        scrollTrigger: {
                            trigger: ".section-how",
                            start: "10% 90%",
                            end: "+=500",
                            scrub: 1,
                        },
                    }
                );
            };

            //feat1
            const feat1 = () => {
                gsap.from(
                    `.phone-ada`,
                    {
                        x: 110,
                        scrollTrigger: {
                            trigger: ".feat-item-1",
                            start: "60% bottom",
                            end: "+=300",
                            scrub: 1,
                        },
                    },
                    "-=3"
                );
            };

            //feat2
            const feat2 = () => {
                const feat2_tl = gsap.timeline({
                    defaults: { duration: 5 },
                    scrollTrigger: {
                        trigger: ".feat-item-2",
                        start: "top 50%",
                        end: "+=200",
                        scrub: 1,
                    },
                });
                const subtitles = gsap.utils.toArray(".phone-subtitles > div");
                subtitles.forEach((item, i) => {
                    feat2_tl.from(item, {
                        y: "-20",
                        opacity: 0,
                        delay: i,
                    });
                });
                gsap.to(".phone-subtitles", {
                    y: -50,
                    opacity: 0,
                    scrollTrigger: {
                        trigger: ".feat-item-2",
                        start: "50% top",
                        end: "+=200",
                        scrub: 1,
                    },
                });
            };

            phone();
            phoneScreen();
            phoneBg();

            feat1();
            feat2();

            // animateBg();
            animateHero();
            animateFeats();
        },
        { scope: main }
    );

    return (
        <MarketingLayout>
            <div className="sections flex flex-col gap-32 md:gap-52" ref={main}>
                {/* HERO */}
                <div className="section-hero section flex w-full relative items-start justify-center z-20">
                    <div
                        className="bg-blur-1 bg-blur-right absolute rounded-full z-0 opacity-20 left-2/3 -top-3/4"
                        style={{ background: "#6676FF", width: 1700, height: 1700, filter: `blur(300px)` }}
                    ></div>

                    <div
                        className="bg-blur-2 bg-blur-left absolute rounded-full z-0  opacity-10 right-2/3 -bottom-1/2"
                        style={{ background: "#6676FF", width: 1000, height: 1000, filter: `blur(200px)` }}
                    ></div>

                    <div className="container flex-1 flex flex-col justify-center items-center min-h-screen text-center gap-10 z-20">
                        <div className="sub-title bg-lightblue300/50 backdrop-blur rounded-full py-3 px-10 text-blue uppercase">
                            From idea to video in minutes
                        </div>
                        <div className="g-title text-5xl leading-tight md:text-9xl md:leading-tighter tracking-tighter font-semibold">
                            <div>Personalized</div> <div>Real Estate Videos</div>
                        </div>
                        <div className="g-title g-subtitle text-base md:text-xl px-20">
                            Increase response rates by up to 500% with Digitalu's dynamic video personalization.
                        </div>
                        <div className="items-end mt-10">
                            <button
                                className="button text-xl rounded-full px-12"
                                onClick={() => {
                                    navigate("/admin/dashboard");
                                }}
                            >
                                Try For Free
                            </button>
                        </div>

                        <div className="absolute left-0 bottom-0 w-full flex items-center justify-center pb-7 ">
                            <img src={iconArrowDown} className="arrow-down animate-bounce" />
                        </div>
                    </div>
                </div>

                {/* FEATS */}
                <div className="section-feats section flex relative w-full min-h-screen">
                    {/* <div
                            className="bg-blur absolute rounded-full z-0 opacity-20 left-1/2 top-1/2"
                            style={{ background: "#6676FF", width: 1200, height: 1200, filter: `blur(300px)` }}
                        ></div> */}

                    <div className="fixed scale-75 translate-y-32 md:translate-y-0 md:scale-100 left-0 top-0 w-full min-h-screen flex items-center justify-center z-10 pt-32">
                        <div
                            className="phone-bg bg-blur absolute rounded-full z-0 opacity-50 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
                            style={{ background: "#6676FF", width: 400, height: 600, filter: `blur(200px)` }}
                        ></div>

                        <div
                            ref={phoneRef}
                            className="phone bg-black rounded-4xl relative overflow-hidden"
                            style={{ width: 300, height: 640, boxShadow: ` 0 0 0 10px rgba(255,255,255,0.7)` }}
                        >
                            <div className="phone-screen absolute top-0 left-0 z-10 w-full h-full flex ">
                                <video src={vidHouse2} loop playsInline autoPlay muted className="w-full h-full object-cover relative z-10" />
                                <video src={vidHouse1} loop playsInline autoPlay muted className="w-full h-full object-cover relative z-10" />
                                <video src={vidHouse3} loop playsInline autoPlay muted className="w-full h-full object-cover relative z-10" />
                            </div>

                            <div className="absolute grad-top top-0 left-0 w-full p-8 z-20">
                                <img src={logoCompass} className="w-28" />
                            </div>

                            <div className="phone-subtitles absolute top-1/3 left-0 w-full p-8 z-20 text-white text-lg tracking-tight">
                                <div className="drop-shadow">Elegant Living within the</div>
                                <div className="drop-shadow">prestigious Hamilton Cove</div>
                                <div className="drop-shadow">private gated community. </div>
                            </div>

                            <div className="absolute grad-bottom bottom-0 left-0 w-full p-8 z-20 text-white">
                                <div className="text-3xl leading-tight tracking-tight mb-5">$1,249,900</div>
                                <div className="text-base leading-tight tracking-tight">1 bed 1 bath 990 sq ft</div>
                                <div className="text-base leading-tight tracking-tight">92 Playa Azul, Avalon, CA 90707</div>
                            </div>

                            <video
                                src={ada}
                                loop
                                playsInline
                                autoPlay
                                muted
                                className="phone-ada w-28 h-28 object-cover rounded-full absolute overflow-hidden z-30 right-6 bottom-20"
                            />
                        </div>
                    </div>

                    <div className="container mx-auto relative flex-1 flex flex-col items-center min-h-screen z-20 px-5">
                        {feats.map((feat, f) => (
                            <div
                                key={f}
                                className={`feat-item feat-item-${f + 1} flex flex-1 items-center min-h-screen justify-between ${
                                    feat.position === "start" ? "flex-col md:flex-row" : "flex-col md:flex-row-reverse"
                                } min-w-full ${f > 0 ? `py-32` : ``} `}
                            >
                                <div
                                    className={`md:w-1/4 flex items-start justify-center md:min-h-full flex-col gap-8  bg-white/60 md:bg-white/0 backdrop-blur-xl p-10 rounded-4xl `}
                                >
                                    <div className="bg-lightblue300/50 backdrop-blur p-6 rounded-3xl mb-10">
                                        <img src={feat.icon} className="w-14 h-14 object-contain" />
                                    </div>
                                    <div className="text-3xl md:text-5xl font-semibold leading-tighter tracking-tight">{feat.title}</div>
                                    <div className="text-base md:text-xl">{feat.desc}</div>
                                </div>

                                <div className="feat-more flex min-h-full text-lg hidden md:flex md:w-1/4 md:items-end pb-0">
                                    <div className="flex flex-col gap-10 ">
                                        <div className="flex gap-6 items-start">
                                            <img src={iconCheck} className="w-8" />
                                            <div>Increase response rates by up to 500% with Digitalu's dynamic video personalization.</div>
                                        </div>
                                        <div className="flex gap-6 items-start">
                                            <img src={iconCheck} className="w-8" />
                                            <div>Increase response rates by up to 500% with Digitalu's dynamic video personalization.</div>
                                        </div>
                                        <div className="flex gap-6 items-start">
                                            <img src={iconCheck} className="w-8" />
                                            <div>Increase response rates by up to 500% with Digitalu's dynamic video personalization.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <How />

                <Why />

                <Plans />
            </div>
        </MarketingLayout>
    );
};

export default RealEstate;
