import { useEffect, useState } from "react";
import { TbArrowRightCircle } from "react-icons/tb";
import { HiArrowLongRight } from "react-icons/hi2";

const UiDuration = (props) => {
    const { data, scene } = props;
    const [start, setStart] = useState(null);

    useEffect(() => {
    
        switch (scene.type) {
            case "scene":
                const currindex = data.scenes.findIndex((obj) => obj.id === scene.id);
                if (currindex > 0) {
                    setStart(parseInt(data.scenes[currindex - 1].end));
                } else if (currindex === data.scenes.length - 1) {
                    setStart(parseInt(data.scenes[data.scenes.length - 1].end));
                } else if (currindex === 0) {
                    setStart(parseInt(data.opening_page.end));
                } else if (currindex === 0) {
                    setStart(parseInt(data.opening_page.end));
                }
                break;

            default:
                //opening / closing page

                setStart(parseInt(scene.start));
                break;
        }
    }, [data]);

    return (
        <>
            <div className="flex-1 flex flex-row items-center justify-between ">
                <div className="flex flex-col  gap-2 w-1/3">
                    <div className="flex w-1/2">Start :</div>

                    <div className="flex-1 flex flex-row items-center gap-1">
                        <div className="flex-1 p-2 bg-lightblue50 rounded-xl relative overflow-hidden border border-lightblue100">
                            <div className="opacity-40 flex gap-1 items-center justify-center">
                                <div>{start}</div>
                                <div className=" text-xs">sec</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-1 pt-8 flex justify-center border-b border-dashed border-lightblue300">
                    {/* <HiArrowLongRight className="text-xl text-lightblue300" /> */}
                </div>

                <div className="flex flex-col w-1/3 gap-2">
                    <div className="flex w-1/2">End :</div>

                    <div className="flex flex-row items-center gap-1 ">
                        <div
                            className={`flex gap-1 items-center p-2 rounded-xl relative overflow-hidden outline-none border ${
                                start <= scene.end ? "border-lightblue100" : "border-orange-400"
                            }`}
                        >
                            <input
                                type="number"
                                min={start}
                                className="w-full flex-1 outline-none text-center"
                                value={scene.end}
                                onChange={(e) => {
                                    let theval = e.target.value;
                                    if (theval >= start) {
                                        scene.end = theval;
                                    } else {
                                        scene.end = start;
                                    }
                                    scene.end = parseInt(scene.end);
                                    props.OnSet({ ...scene });
                                }}
                            />
                            <span className="opacity-40 text-xs">sec</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UiDuration;
