import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { horizontalLoop, toggleHeader, animateTitle } from "./animation";

gsap.registerPlugin(ScrollTrigger);

export const animateExampleItems = () => {
   // toggleHeader({ trigger: ".section-hero-examples" });
    animateTitle({ trigger: ".section-hero-examples .g-title", scrub: false });

    let panels = gsap.utils.toArray(".ex-panel");
    panels.forEach((panel, i) => {
        ScrollTrigger.create({
            trigger: panel,
            start: "top top", // if it's shorter than the viewport, we prefer to pin it at the top
            end: "bottom bottom",
            
        });

        const prevPanel = panels[i - 1];

        if (prevPanel) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: panel,
                    start: "top bottom",
                    end: "top -10%",
                    scrub: 1,
                    pin:false
                },
            });

            tl.to(prevPanel, {
                scale: 0.85,
            });
            tl.to(prevPanel, {
                opacity: 0,
            });
        }
    });
};
