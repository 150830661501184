import iconCheck from "src/common/assets/images/marketing/icon-check.svg";
import logoApollo from "src/common/assets/images/marketing/logo-apollo.png";
import logoClose from "src/common/assets/images/marketing/logo-close.png";
import logoHighlevel from "src/common/assets/images/marketing/logo-highlevel.png";
import logoHubspot from "src/common/assets/images/marketing/logo-hubspot.png";
import logoInstantly from "src/common/assets/images/marketing/logo-instantly.png";
import logoLemlist from "src/common/assets/images/marketing/logo-lemlist.png";
import logoOutreach from "src/common/assets/images/marketing/logo-outreach.png";
import logoSalesloft from "src/common/assets/images/marketing/logo-salesloft.png";

import { useNavigate } from "react-router-dom";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";

import { horizontalLoop } from "src/pages/marketing/animation/common";

gsap.registerPlugin(useGSAP);

const Plans = (props) => {
    const main = useRef();
    const navigate = useNavigate();
    const logos = [
        logoApollo,
        logoClose,
        logoHighlevel,
        logoHubspot,
        logoLemlist,
        logoOutreach,
        logoSalesloft,
        logoApollo,
        logoClose,
        logoApollo,
        logoClose,
        logoHighlevel,
        logoHubspot,
        logoLemlist,
        logoOutreach,
    ];
    const plans = [
        {
            type: "Starter",
            price: "$99",
            term: true,
            feat: "750 AI videos / mo",
        },
        {
            type: "Professional",
            price: "$250",
            term: true,
            feat: "3000 AI videos / mo",
        },
        {
            type: "Enterprise",
            price: `Let's Talk`,
            term: false,
            feat: "Custom AI videos / mo",
        },
    ];

    useGSAP(
        () => {
            const planItems = gsap.utils.toArray(".section-plans .g-plan");
            planItems.forEach((item, i) => {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        start: "50% bottom",
                        end: "+=100",
                        scrub: 1,
                    },
                });
                tl.from(item, {
                    // x: "-200px",
                    opacity: 0,
                    scale: 1.3,
                    delay: i,
                });
            });

            gsap.utils.toArray(".g-marquee").forEach((line, i) => {
                const links = line.querySelectorAll(".item"),
                    tl = horizontalLoop(links, {
                        repeat: -1,
                        speed: 1 + i * 0.5,
                        paddingRight: parseFloat(gsap.getProperty(links[0], "marginRight", "px")), // otherwise first element would be right up against the last when it loops. In this layout, the spacing is done with marginRight.
                    });
                links.forEach((link) => {
                    // link.addEventListener("mouseenter", () => tl.pause());
                    // link.addEventListener("mouseleave", () => tl.resume());
                });
            });
        },
        { scope: main }
    );

    return (
        <div ref={main} className="section-plans section flex flex-col gap-32 relative w-full">
            <div
                className="bg-blur absolute rounded-full z-0 opacity-30 left-1/2 -translate-x-1/2 top-2/3 -translate-y-1/2"
                style={{ background: "#6676FF", width: 800, height: 600, filter: `blur(300px)` }}
            ></div>

            <div className="container mx-auto relative flex-1 flex flex-col  z-20 gap-10 p-4">
                <div className="section-title flex flex-col items-center text-cente gap-10 py-10">
                    <div className="sub-title bg-white/50 backdrop-blur rounded-full py-3 px-10 text-blue uppercase ">From idea to video in minutes</div>
                    <div className="g-title text-4xl leading-tighter md:text-7xl md:leading-tighter tracking-tighter font-semibold">Choose a plan</div>
                </div>

                <div className="flex-1 flex flex-col md:flex-row gap-10 md:items-center justify-center">
                    {/* <PlanItem type={`Starter`} price={`$99`} term={true} feat={`750 AI videos / mo`} />
                <PlanItem type={`Professional`} price={`$299`} term={true} feat={`3000 AI videos / mo`} />
                <PlanItem type={`Enterprise`} price={`Let's Talk`} term={false} feat={`Custom AI videos / mo`} /> */}

                    {plans.map((plan, p) => (
                        <div
                            key={p}
                            className="g-plan flex-1 flex flex-col bg-white/40 rounded-3xl backdrop-blur gap-5 p-10 md:p-20 hover:-translate-y-2 hover:bg-white/80"
                        >
                            <div className="text-xl text-blue">{plan.type}</div>
                            <div className="flex gap-8">
                                <div className="text-6xl md:text-7xl font-semibold leading-tighter tracking-tighter">{plan.price}</div>
                                <div className="flex flex-col justify-center">
                                    {plan.term && (
                                        <>
                                            <div>/ mo / seat</div>
                                            <div>7-day free trial</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="text-xl mb-5">{plan.feat}</div>

                            <div className="flex flex-col gap-4 text-base py-10 md:py-20 border-t border-t-slate-200 ">
                                <div className="flex gap-6 items-center">
                                    <img src={iconCheck} className="w-8" />
                                    <div>AI Face Cloning</div>
                                </div>
                                <div className="flex gap-6 items-center">
                                    <img src={iconCheck} className="w-8" />
                                    <div>AI Voice Cloning</div>
                                </div>
                                <div className="flex gap-6 items-center">
                                    <img src={iconCheck} className="w-8" />
                                    <div>Dynamic Videos</div>
                                </div>
                                <div className="flex gap-6 items-center">
                                    <img src={iconCheck} className="w-8" />
                                    <div>Screen Recording to AI Video</div>
                                </div>
                                <div className="flex gap-6 items-center">
                                    <img src={iconCheck} className="w-8" />
                                    <div>Zapier Webhooks</div>
                                </div>
                            </div>
                            <button className="button text-base rounded-full px-8" onClick={()=>{
                                navigate('/admin')
                            }}>Try For Free</button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex flex-col items-center text-center gap-10 md:gap-20 md:pt-10 relative z-20 ">
                <div className="g-title text-xl leading-tighter md:text-3xl md:leading-tighter tracking-tighter font-semibold px-10">
                    Integrates with 50+ sales and marketing tools.
                </div>

                <div className="g-marquee-container">
                    <div className="g-logos g-marquee flex relative overflow-hidden">
                        {logos.map((logo, l) => (
                            <div key={l} className="item flex items-center justify-center rounded-xl mr-24" style={{ minWidth: "130px", minHeight: "70px" }}>
                                <img src={logo} className="transition-all object-contain grayscale  hover:grayscale-0 hover:opacity-100 w-full h-full" />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Plans;
