import { useEffect, useRef } from "react";

import { animateCTA2, animateFooter } from "../../layouts/marketing/animation/animation";
import { animateFeatItems } from "../../layouts/marketing/animation/features";

import Header from "src/layouts/marketing/header";
import Footer from "src/layouts/marketing/footer";
import CTA from "src/layouts/marketing/cta";
import Faq from "src/layouts/marketing/faq";
import ada from "src/common/assets/images/marketing/ada-orig.mp4";
import gVidThumbs from "src/common/assets/images/marketing/g-vid-thumbs.png";

import logoApollo from "src/common/assets/images/marketing/logo-apollo.png";
import logoClose from "src/common/assets/images/marketing/logo-close.png";
import logoHighlevel from "src/common/assets/images/marketing/logo-highlevel.png";
import logoHubspot from "src/common/assets/images/marketing/logo-hubspot.png";
import logoInstantly from "src/common/assets/images/marketing/logo-instantly.png";
import logoLemlist from "src/common/assets/images/marketing/logo-lemlist.png";
import logoOutreach from "src/common/assets/images/marketing/logo-outreach.png";
import logoSalesloft from "src/common/assets/images/marketing/logo-salesloft.png";

import graphicCSV from "src/common/assets/images/marketing/graphic-csv.svg";

import gHouse1 from "src/common/assets/images/marketing/house-1.jpg";
import gHouse2 from "src/common/assets/images/marketing/house-2.jpg";
import gHouse3 from "src/common/assets/images/marketing/house-3.jpg";

import Plans from "src/layouts/marketing/plans";
import MarketingLayout from "src/layouts/marketing/marketing-layout";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(useGSAP);

const Features = () => {
    const main = useRef();
    const logos = [logoApollo, logoClose, logoHighlevel, logoHubspot, logoLemlist, logoOutreach, logoSalesloft, logoApollo, logoClose, logoHighlevel];

    useGSAP(
        () => {
            animateFeatItems();
        },
        { scope: main }
    );

    const features = [
        {
            title: "Accurate Lip-Sync Personalization",
            desc: "Greet prospects by name with perfectly synced videos.",
            content: (
                <>
                    <div className="absolute left-0 top-0 w-full h-full z-20" style={{ boxShadow: "inset 0 0 130px rgba(194, 199, 255, 0.4" }}></div>
                    <video src={ada} playsInline autoPlay className="w-full h-full object-cover relative z-10" loop muted />
                </>
            ),
        },

        {
            title: "Effortless Scalability",
            desc: "Produce thousands of personalized videos quickly using CSV uploads.",
            content: (
                <>
                    {/* <div className="absolute rounded-full bg-white flex items-center justify-center z-30 w-72 h-72">
                        <img src={graphicCSV} className="w-24 object-contain" />
                    </div> */}
                    <div className="g-feat-vidthumbs left-0 flex flex-col gap-5 items-center">
                        <div className="g-feat-vidthumbs-1 flex flex-row ">
                            <img src={gVidThumbs} className=" g-item object-contain " style={{ minWidth: "200%", maxWidth: "200%" }} />
                            <img src={gVidThumbs} className=" g-item object-contain " style={{ minWidth: "200%", maxWidth: "200%" }} />
                            <img src={gVidThumbs} className=" g-item object-contain " style={{ minWidth: "200%", maxWidth: "200%" }} />
                        </div>

                        <div className="g-feat-vidthumbs-2  flex flex-row-reverse">
                            <img src={gVidThumbs} className=" g-item object-contain " style={{ minWidth: "200%", maxWidth: "200%" }} />
                            <img src={gVidThumbs} className=" g-item object-contain " style={{ minWidth: "200%", maxWidth: "200%" }} />
                            <img src={gVidThumbs} className=" g-item object-contain " style={{ minWidth: "200%", maxWidth: "200%" }} />
                        </div>
                    </div>
                </>
            ),
        },

        {
            title: "Integration with 50+ Tools",
            desc: "Add DigitalU to your existing sales tools without disruption.",
            content: (
                <>
                    <div className="flex-1 flex flex-col gap-6 absolute w-full h-full overflow-hidden items-center justify-center w-full h-full">
                        <div className="g-logos g-marquee flex flex-row overflow-hidden items-center justify-center">
                            {logos.map((logo, l) => (
                                <div key={l} className="item flex min-w-96 items-center justify-center bg-white p-20 rounded-4xl relative overflow-hidden mr-5">
                                    <img src={logo} className="object-contain" />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ),
        },

        {
            title: "AI generated screen recordings",
            desc: "Create automated screen recordings with unique backgrounds.",
            content: (
                <>
                    <div className="g-bgs-wrapper absolute w-full h-full left-0 top-0">
                        <div className="g-bgs w-full h-full flex " style={{ width: 1000 }}>
                            <img src={gHouse1} className="w-full h-full object-cover" />
                            <img src={gHouse2} className="w-full h-full object-cover" />
                            <img src={gHouse3} className="w-full h-full object-cover" />
                        </div>
                        <div className="absolute rounded-full w-44 h-44 md:w-64 md:h-64 bottom-14 right-14 overflow-hidden">
                            <video src={ada} playsInline autoPlay className="w-full h-full object-cover relative z-10" loop muted />
                        </div>
                    </div>
                </>
            ),
        },
    ];

    return (
        <MarketingLayout>
             <div className="fixed scale-75 translate-y-32 md:translate-y-0 md:scale-100 left-0 top-0 w-full min-h-screen flex items-center justify-center z-0 pt-32">
                <div
                    className="bg-blur absolute rounded-full z-0 opacity-60 left-1/2 -translate-x-1/2 top-2/3 "
                    style={{ background: "#6676FF", width: 1000, height: 300, filter: `blur(300px)` }}
                ></div>
            </div>
            <div className="sections flex flex-col gap-32 " ref={main}>
                {/* HERO */}

                <div className="section-hero-features section flex w-full relative items-center pt-60 pb-12 justify-center z-30">
                    <div
                        className="bg-blur-1 bg-blur-right absolute rounded-full z-0 opacity-20 left-2/3 -top-3/4"
                        style={{ background: "#6676FF", width: 600, height: 400, filter: `blur(200px)` }}
                    ></div>

                    <div
                        className="bg-blur-2 bg-blur-left absolute rounded-full z-0  opacity-10 right-2/3 -bottom-1/2"
                        style={{ background: "#6676FF", width: 1000, height: 1000, filter: `blur(200px)` }}
                    ></div>
                    <div className="container flex-1 flex justify-center items-center px-4">
                        <div className="flex flex-col text-center items-center gap-20">
                             <div className="sub-title bg-lightblue300/50 backdrop-blur rounded-full py-3 px-10 text-blue uppercase">
                                From idea to video in minutes
                            </div>
                            <div className="g-title text-6xl leading-tight md:text-8xl md:leading-tighter tracking-tighter font-semibold">
                                <div>Add personality to your</div>
                                <div>outreach sequences with </div>
                                <div>personalized videos.</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-features flex w-full relative  items-center justify-center pb-20 rounded-wide z-30">
                    <div className="container  flex-1 flex justify-start items-start">
                        <div className="g-feat-wrapper grid  grid-cols-1 md:grid-cols-2 gap-10 px-4">
                            {features.map((feat, f) => (
                                <div key={f} className="g-feat-item flex p-6 md:p-10 bg-white/40 rounded-3xl">
                                    <div className="flex-1 flex flex-col">
                                        <div className="p-10 mb-20 flex flex-col gap-4 ">
                                            <div className="flex items-start mb-10">
                                                <div className="bg-lightblue100 text-blue rounded-2xl text-base md:text-xl p-3 md:p-5 w-16 md:w-20 h-16 flex items-center justify-center text-center`">
                                                    {`0${f + 1}`}
                                                </div>
                                            </div>
                                            <div className="text-4xl font-semibold leading-tight tracking-tighter">{feat.title}</div>
                                            <div className="text-lg">{feat.desc}</div>
                                        </div>
                                        <div
                                            className="flex-1 flex bg-lightblue80  rounded-3xl w-full  relative overflow-hidden border-1 border-1-lightblue100 items-center justify-center"
                                            style={{ minHeight: 450, maxHeight: 450 }}
                                        >
                                            {feat.content}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <Plans />
            </div>
        </MarketingLayout>
    );
};

export default Features;
