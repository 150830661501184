import { useEffect, useState } from "react";
import { upload } from "src/common/services/utils";

const FileUploader = (props) => {
    const [theFile, setTheFile] = useState(null);

    useEffect(()=>{
        if(props.file){
            setTheFile(props.file)
        }
    },[props.file])

    const handleUpload = async (file) => {
        if (file) {
            props.OnUploading(true);
            try {
                const file_res = await upload({ file });
                console.log(file_res);
                props.OnSuccess(file_res.data[0].cache);
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className="flex">
            <div className="flex w-full min-h-32 border border-dashed border-blue rounded-2xl bg-lightblue80 items-center justify-center  relative overflow-hidden">
                {theFile && theFile.name && (
                    <div className="absolute w-full h-full flex flex-col items-center justify-center p-4 gap-4">
                        {theFile.type.indexOf("video") !== -1 && (
                            <video
                                src={URL.createObjectURL(theFile)}
                                playsInline
                                muted
                                className="object-contain min-w-14 min-h-14  rounded-2xl relative   overflow-hidden bg-black"
                            />
                        )}

                        {theFile.type.indexOf("image") !== -1 && (
                            <img
                                src={URL.createObjectURL(theFile)}
                                className="object-contain min-w-14 min-h-14  rounded-2xl relative  overflow-hidden bg-black"
                            />
                        )}

                        <div className="flex flex-row items-center gap-5">
                            <div>{theFile.name}</div>
                            <button
                                className="button button-small button-white px-3 py-1 text-xs"
                                onClick={() => {
                                    props.OnRemove(true);
                                    setTheFile(null);
                                }}
                            >
                                remove
                            </button>
                        </div>
                    </div>
                )}

                {theFile && (
                    <div className="relative overflow-hidden w-24 h-24 rounded-2xl relative overflow-hidden">
                        <video
                            src={theFile}
                            playsInline
                            muted
                            className="h-full w-full rounded-2xl relative overflow-hidden object-contain bg-black opacity-50 cursor-not-allowed"
                        />

                        {/* {props.onUse.type === "image" && (
                            <img
                                src={props.onUse.url}
                                className="h-full w-full rounded-2xl relative overflow-hidden object-contain bg-black opacity-50 cursor-not-allowed"
                            />
                        )} */}
                    </div>
                )}

                <label className="relative cursor-pointer">
                    {!theFile && <button className="relative z-10 button-white px-6 py-2 rounded-full cursor-pointer">Browse Files</button>}
                    <input
                        type="file"
                        accept={props.accepts}
                        className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                        onChange={async (e) => {
                            console.log(e.target.files[0]);
                            setTheFile(e.target.files[0]);
                            await handleUpload(e.target.files[0]);
                        }}
                    />
                </label>
            </div>
        </div>
    );
};

export default FileUploader;
