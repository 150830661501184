import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { stableDiff } from "src/common/services/tester";
import { captureKeyframes, getQueue, upload } from "src/common/services/utils";

import arrowLeft from "src/pages/tester/images/arrow-left.svg";
import arrowRight from "src/pages/tester/images/arrow-right.svg";
import iconClear from "src/pages/tester/images/icon-clear.svg";
import iconVideo from "src/pages/tester/images/icon-video.svg";

const Character = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [fetching, setFetching] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [character, setCharacter] = useState({
        image: null,
        video: null,
        desc: "",
    });
    const pollCount = useRef(0);

    useEffect(() => {
        return () => {};
    }, []);


    useEffect(()=>{
        console.log("1:", location)
        if(location.state && location.state.character){
            setCharacter(location.state.character)
        }
    },[location])

    const pollQue = async (queueid) => {
        if (pollCount.current < 20) {
            //1 min
            await new Promise((res) => setTimeout(res, 3000));
            const que_res = await getQueue({ id: queueid }, true);
            console.log("que_res :: ", que_res);

            if (que_res) {
                if (que_res.state === "complete") {
                    character.image = que_res.result;
                    setCharacter({ ...character });
                    setFetching(false);
                } else if (que_res.state.indexOf("Error") !== -1 || que_res.state.indexOf("error") !== -1) {
                    setFetching(false);
                } else {
                    pollCount.current++;
                    await pollQue(queueid);
                }
            }
        }
    };

    const handleCharacter = async ({ opr, file }) => {
        setErrorMsg(null);
        switch (opr) {
            case "create":
                setFetching(true);
                if (character.desc && character.desc !== "") {
                    try {
                        const desc = `A headshot of a person, showing the full head from the top of the hair to the bottom of the chin. The face should take up about 50% of the image, with a bit of extra space (padding) around the head to ensure that the image is not overly zoomed in or cropped too tightly. ${character.desc}`;

                        const create_res = await stableDiff(desc);
                        console.log("create_res ::", create_res);
                        if (create_res && create_res.id) {
                            pollQue(create_res.id);
                        } else {
                            setFetching(false);
                        }
                    } catch (error) {
                        setFetching(false);
                        console.log(error);
                    }
                }

                break;

            case "upload":
                setFetching(true);

                if (file) {
                    try {
                        const upload_res = await upload({ file: file, useAdminAuth: true });
                        console.log(upload_res);
                        if (upload_res) {
                            if (file.type.indexOf("video") !== -1) {
                                character.video = upload_res.data[0].cache;

                                let keyFramesInput = {
                                    url: upload_res.data[0].cache,
                                    frames: "1",
                                };
                                console.log("keyFramesInput:", keyFramesInput);
                                const keyFrameRes = await captureKeyframes(keyFramesInput, true);
                                console.log("keyFrameRes :: ", keyFrameRes);

                                if (keyFrameRes && keyFrameRes.key) {
                                    character.image = `https://svc.staging.squadz.live/download/${keyFrameRes.key}`;
                                }
                            } else if (file.type.indexOf("image") !== -1) {
                                character.image = upload_res.data[0].cache;
                            }

                            setCharacter({ ...character });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                setFetching(false);
                break;
            default:
                break;
        }
    };
    return (
        <div className="bg-zinc-950 min-h-full w-full text-white flex  text-base">
            {fetching && (
                <div className="pre-loader bg-zinc-950/50 absolute w-full h-full top-0 left-0 z-40 flex items-center justify-center backdrop-blur-sm">
                    <div className="flex flex-row gap-1 items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Please wait...
                    </div>
                </div>
            )}
            <div className="container container-sm flex flex-col flex-1 mx-auto p-10 max-w-3xl relative slideFromLeft">
                {errorMsg && (
                    <div className="absolute left-0 top-5 w-full bg-rose-500/50 backdrop-blur p-2 rounded-2xl text-center flex flex-row">
                        <div className="flex-1">{errorMsg}</div>
                        <img
                            src={iconClear}
                            className="cursor-pointer"
                            onClick={() => {
                                setErrorMsg(null);
                            }}
                        />
                    </div>
                )}

                {/* HEADER */}
                <div className="header flex flex-row py-5 items-center">
                    <div className="w-32 opacity-30 cursor-pointer hover:opacity-80 flex flex-row gap-5">
                        {/* <img src={arrowLeft} />
                        Previous */}
                    </div>
                    <div className="flex-1 text-center text-xl">Character</div>

                    <div
                        className="w-32 opacity-30 cursor-pointer hover:opacity-80 flex flex-row gap-5 justify-end"
                        onClick={() => {
                            if (character.image && character.image.indexOf("https://") !== -1) {
                                navigate("/tester/audio", { state: { character } });
                            }
                        }}
                    >
                        {character.image && character.image.indexOf("https://") !== -1 && (
                            <div className="flex gap-5">
                                Next
                                <img src={arrowRight} />
                            </div>
                        )}
                    </div>
                </div>

                {/* BODY */}
                <div className="body flex-1 flex flex-col flex-1">
                    <div className="flex-1 flex flex-col gap-10 py-20 ">
                        <div className="flex-1 flex flex-col items-center justify-center gap-10">
                            <div className="relative">
                                {character.image !== null && (
                                    <img
                                        src={iconClear}
                                        className="absolute right-10 top-10 z-10 cursor-pointer hover:opacity-50"
                                        onClick={() => {
                                            character.image = null;
                                            setCharacter({ ...character });
                                        }}
                                    />
                                )}
                                <label className="relative">
                                    <div className="transition-all relative overflow-hidden min-w-96 min-h-96 rounded-full border border-zinc-100/20 border-dashed flex items-center justify-center cursor-pointer hover:border-zinc-100/50">
                                        {((character.image && character.image.indexOf(".mov") !== -1) ||
                                            (character.image && character.image.indexOf(".mp4") !== -1) ||
                                            (character.image && character.image.indexOf(".webm") !== -1) ||
                                            (character.image && character.image.indexOf(".ts") !== -1)) && (
                                            <video
                                                playsInline
                                                muted
                                                autoPlay
                                                loop
                                                src={character.image}
                                                className="absolute left-0 top-0 w-full h-full object-cover"
                                            />
                                        )}

                                        {((character.image && character.image.indexOf(".png") !== -1) ||
                                            (character.image && character.image.indexOf(".jpg") !== -1) ||
                                            (character.image && character.image.indexOf(".jpeg") !== -1) ||
                                            (character.image && character.image.indexOf(".webp") !== -1)) && (
                                            <img src={character.image} className="absolute left-0 top-0 w-full h-full object-cover" />
                                        )}

                                        {character.image === null && <div className="text-6xl font-thin opacity-40">+</div>}
                                    </div>

                                    <input
                                        type="file"
                                        accept={``}
                                        className="absolute left-0 top-0 opacity-0"
                                        onChange={async (e) => {
                                            console.log(e.target.files[0]);
                                            // character.image = e.target.files[0];
                                            // setCharacter({ ...character });
                                            await handleCharacter({ opr: "upload", file: e.target.files[0] });
                                        }}
                                    />
                                </label>
                            </div>
                            {character.image === null && <div className="opacity-30 text-sm">Click to upload image or video</div>}
                        </div>
                        <div className="flex flex-col relative border border-zinc-100/10 rounded-4xl w-full overflow-hidden">
                            <textarea
                                className="flex flex-1 w-full p-12"
                                rows={2}
                                placeholder={`Describe your character and click "Create" to generate`}
                                onChange={(e) => {
                                    character.desc = e.target.value;
                                    setCharacter({ ...character });
                                }}
                            />
                            <div className="flex w-full items-end justify-end p-6">
                                <button
                                    disabled={character.desc === ""}
                                    className={`btn cursor-pointer rounded-3xl py-3 px-8 border-2 border-orange-500  ${
                                        character.desc === "" ? "opacity-40 cursor-not-allowed" : "opacity-100 hover:translate-y-1"
                                    }`}
                                    onClick={async () => {
                                        await handleCharacter({ opr: "create" });
                                    }}
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* FOOTER */}
                <div className="footer flex flex-row py-5">
                    {character.image && character.image.indexOf("https://") !== -1 && (
                        <button
                            className={`transition-all flex-1 btn rounded-3xl py-6 px-20 text-white text-center bg-gradient-to-r from-red-500 to-amber-500 ${
                                character.image && character.image.indexOf("https://") !== -1
                                    ? "opacity-100 hover:translate-y-1 cursor-pointer"
                                    : "opacity-40 cursor-not-allowed"
                            }`}
                            onClick={() => {
                                if (character.image && character.image.indexOf("https://") !== -1) {
                                    navigate("/tester/audio", { state: { character } });
                                }
                            }}
                        >
                            Next
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Character;
