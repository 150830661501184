const Canvas = (props) => {
    const { selected } = props;
    return (
        <div className="editor-canvas flex-1  bg-lightblue80 rounded-3xl h-3/4 min-h-3/4 max-h-3/4 relative overflow-hidden">
            <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center scale-70">
                <div className="relative bg-white rounded-5xl flex overflow-hidden border-8 border-white shadow-2xl shadow-indigo-500/20" style={{ width: 390, height: 844 }}>
                    {selected && selected.scene_ss && <img src={selected.scene_ss} className="absolute left-0 top-0 object-cover w-full h-full" />}
                </div>
            </div>
        </div>
    );
};

export default Canvas;
