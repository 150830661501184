import axios from "axios";
import { getStoredUser } from "src/common/components/auth/auth";
import endpoint from "./endpoints";
import { handleResponse } from "./response";

///////////////////////////QUERIES
export const getUser = async (input, params) => {
    let query = {
        Params: {
            userid: getStoredUser()?.id,
            auth: getStoredUser()?.auth,
            sys: "users",
            act: "select",
            pagesize: "1",
            pagenumber: "1",
        },
        Where: input,
    };
    if (params?.userid) {
        query.Params.userid = params.userid;
    }
    if (params?.auth) {
        query.Params.auth = params.auth;
    }

    try {
        const res = await axios.post(endpoint.rest, query);
        return handleResponse(query, res);
    } catch (error) {
        console.log("error :", error);
    }
};

export const searchUsers = async (input, params) => {
    if (getStoredUser() && getStoredUser().id) {
        let query = {
            Params: {
                userid: getStoredUser().id,
                auth: getStoredUser().auth,
                sys: "users",
                act: "select",
                pagesize: params?.pagesize ? params.pagesize : "10",
                pagenumber: params?.pagenumber ? params.pagenumber : "1",
            },
            WhereLike: input,
        };
        try {
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getFollowingNetworks = async (input, params) => {
    if (getStoredUser() && getStoredUser().id) {
        let query = {
            Params: {
                userid: getStoredUser().id,
                auth: getStoredUser().auth,

                sys: "followingnetworks",
                act: "select",
                pagesize: params?.pagesize ? params.pagesize : "20",
                pagenumber: params?.pagenumber ? params.pagenumber : "1",
            },
            Where: input,
        };
        try {
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const usersByNetwork = async (input, params) => {
    if (getStoredUser() && getStoredUser().id) {
        let query = {
            Params: {
                userid: getStoredUser().id,
                auth: getStoredUser().auth,

                sys: "networkmembers",
                act: "select",
                pagesize: params?.pagesize ? params.pagesize : "20",
                pagenumber: params?.pagenumber ? params.pagenumber : "1",
            },
            Where: input,
        };
        try {
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const getUserChats = async (params) => {
    if (getStoredUser() && getStoredUser().id) {
        let query = {
            Params: {
                userid: getStoredUser().id,
                auth: getStoredUser().auth,
                sys: "users",
                act: "select",
                pagesize: params?.pagesize ? params.pagesize : "20",
                pagenumber: params?.pagenumber ? params.pagenumber : "1",
            },
            WhereLike: {
                name: "%%",
            },
        };
        try {
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

///////////////////////////MUTATIONS

export const loginUser = async (phone) => {
    let query = {
        Params: {
            sys: "auth",
            act: "login",
            phone,
            pagesize: "1",
        },
    };
    try {
        const res = await axios.post(endpoint.rest, query);
        return handleResponse(query, res);
    } catch (error) {
        console.log("error :", error);
    }
};

export const logoutUser = async () => {
    if (getStoredUser() && getStoredUser().id) {
        let query = {
            Params: {
                userid: getStoredUser().id,
                auth: getStoredUser().auth,
                sys: "auth",
                act: "logout",
                pagesize: "1",
            },
        };
        try {
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const createUser = async (input) => {
    if (getStoredUser() && getStoredUser().id) {
        let query = {
            Params: {
                auth: getStoredUser().auth,
                sys: "users",
                act: "insert",
                pagesize: "1",
                pagenumber: "1",
            },
            Values: input,
        };
        try {
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const updateUser = async (userid, input, returnUser, auth) => {
    if (userid) {
        let query = {
            Params: {
                userid: getStoredUser() && getStoredUser().id ? getStoredUser().id : userid,
                auth:(auth)? auth : getStoredUser().auth,
                sys: "users",
                act: "update",
                pagesize: "1",
                pagenumber: "1",
            },
            Where: { id: userid },
            Values: input,
        };
        try {
            const res = await axios.post(endpoint.rest, query);

            if (returnUser) {
                return await getUser({ id: userid });
            } else {
                return handleResponse(query, res);
            }
        } catch (error) {
            console.log("error :", error);
        }
    }
};

export const createStreamChatUserToken = async (userId, auth) => {
    let query = {
        Params: {
            userid: userId,
            auth: auth,
            // publishto: selectedChannel.id,

            sys: "chat",
            act: "token",
            pagesize: "1",
        },
    };
    try {
        const res = await axios.post(endpoint.rest, query);
        return handleResponse(query, res);
    } catch (error) {
        console.log("error:", error);
    }
};
