import AdminLayout from "src/layouts/admin-layout";
import ListVideos from "src/modules/admin/inputed-videos/list-videos";
import { IoAddCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { upload } from "src/common/services/utils";
import { useState } from "react";

const InputedVideos = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [action, setAction] = useState();

    return (
        <>
            <AdminLayout
                header={() => (
                    <div className="flex flex-1 items-center ">
                        <div className="flex flex-1 flex-row px-0 items-center gap-6">
                            <div className="text-xl font-semibold">Your Videos</div>
                            <div
                                className="transition-all bg-white border border-zinc-500/40 rounded-full p-2 px-5  flex items-center gap-2 opacity-60 hover:opacity-100 hover:text-blue hover:border-blue cursor-pointer"
                                onClick={() => {
                                    navigate(location.pathname, { state: { show: "api-box" } });
                                }}
                            >
                                <div>Api</div>
                            </div>

                            <label className="relative overflow-hidden transition-all bg-white border border-zinc-500/40 rounded-full p-2 pr-5  flex items-center gap-2 opacity-60 hover:opacity-100 hover:text-blue hover:border-blue cursor-pointer">
                                <IoAddCircleOutline className="text-xl" /> <div>Upload</div>
                                <input
                                    type="file"
                                    accept={`video/*`}
                                    className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                                    onChange={async (e) => {
                                        console.log(e.target.files[0]);
                                        setAction({type:'upload', file: e.target.files[0]})
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                )}
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <ListVideos action={action} />
                </div>
            </AdminLayout>
        </>
    );
};

export default InputedVideos;
