import { HiOutlineSpeakerWave } from "react-icons/hi2";
import VideoPlayer from "src/common/components/video-player/video-player";

const AudioUpload = (props) => {
    const { genData } = props;
    return (
        <div className="flex flex-1">
            <div className="flex w-full min-h-24 border border-dashed border-blue rounded-2xl bg-lightblue80 items-center justify-center  relative overflow-hidden">
                {genData.audio && genData.audio.name && (
                   <div className="max-h-400 min-h-400 aspect-square flex flex-col items-center justify-center p-4 gap-4">
                        {/* <div className="bg-white rounded-full p-6">
                            <HiOutlineSpeakerWave className="text-xl" />
                        </div> */}

                        {genData.audio.name && (
                            <VideoPlayer
                                src={URL.createObjectURL(genData.audio)}
                                fluid={true}
                                className="object-contain min-w-14 min-h-14 rounded-2xl relative overflow-hidden bg-black"
                            />
                        )}
                        {/* <video src={URL.createObjectURL(genData.audio)} playsInline muted className="max-h-full rounded-2xl relative overflow-hidden" /> */}
                        <div className="flex flex-row items-center gap-5">
                            <div>{genData.audio.name}</div>
                            <button
                                className="button button-small button-white px-3 py-1 text-xs"
                                onClick={() => {
                                    delete genData.audio;
                                    delete genData.voiceId;
                                    delete genData.audioURL;
                                    delete genData.voiceGender;

                                    props.OnSet({ ...genData });
                                }}
                            >
                                remove
                            </button>
                        </div>
                    </div>
                )}
                <label className="relative cursor-pointer">
                    {!genData.audio && <button className="relative z-10 button-white px-6 py-2 rounded-full cursor-pointer">Browse Files</button>}
                    <input
                        type="file"
                        accept={`video/*, audio/*`}
                        className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                        onChange={async (e) => {
                            console.log(e.target.files[0]);

                            genData.audio = e.target.files[0];
                            delete genData.voiceId;
                            delete genData.audioURL;
                            delete genData.voiceGender;

                            props.OnSet(genData);
                        }}
                    />
                </label>
            </div>
        </div>
    );
};

export default AudioUpload;
